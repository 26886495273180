import _ from 'underscore';
import TimeMe from 'timeme.js';
import { globalHistory } from '@reach/router';

const USER_ACTIVITY_WHITE_LISTED_URLS = [
  /^\/task_assignments\/[\w\-]+(\/path_assignment_id\/[\w\-]+)?$/,
  /^\/guides\/[\w\-]+\/tasks\/[\w\-]+$/,
  /^\/challenge_assignments\/[\w\-]+$/,
];

function resolveUserType() {
  const internalUser = getCurrentUser();
  if (internalUser !== null) {
    return { userIdField: 'user_id', userId: internalUser.id };
  }

  const externalUser = getExternalUser();
  if (externalUser !== null) {
    return { userIdField: 'external_user_id', userId: externalUser.id };
  }
}

function publishTimer() {
  const userObject = resolveUserType();
  if (!userObject) return;

  const postData = new FormData();
  const timeSpentOnTask = TimeMe.getTimeOnCurrentPageInSeconds();

  postData.append('content_uri', TimeMe.currentPageName);
  postData.append('time_spent', timeSpentOnTask);
  postData.append(userObject.userIdField, userObject.userId);
  postData.append(
    document.querySelector('meta[name=csrf-param]').getAttribute('content'),
    document.head.querySelector('[name=csrf-token][content]').content,
  );
  navigator.sendBeacon('/api/url_activity', postData);
}

function userActivityWhiteListedUrl(pathname) {
  return _.some(USER_ACTIVITY_WHITE_LISTED_URLS, (whiteListedUrl) => whiteListedUrl.test(pathname));
}

function publishTimerAndResetActivityTracking() {
  TimeMe.stopTimer();
  publishTimer();
  TimeMe.resetAllRecordedPageTimes();
  window.removeEventListener('visibilitychange', handleVisibilityChange);
}

function startTimerAndActivityTracking(pathname) {
  // Before TimeMe is initialized, the currentPageName === "default-page-name"
  // after we initialize TimeMe once, the currentPageName will be whatever we subsequently set it to
  if (TimeMe.currentPageName === 'default-page-name') {
    TimeMe.initialize({
      currentPageName: pathname,
      idleTimeoutInSeconds: 60,
    });
  } else {
    TimeMe.setCurrentPageName(pathname);
    TimeMe.startTimer();
  }

  window.addEventListener('visibilitychange', handleVisibilityChange);
}

function initializeActivityTracking() {
  const { pathname } = window.location;
  globalHistory.listen(({ location }) => {
    if (userActivityWhiteListedUrl(TimeMe.currentPageName)) {
      publishTimerAndResetActivityTracking();
    }

    if (userActivityWhiteListedUrl(location.pathname)) {
      startTimerAndActivityTracking(location.pathname);
    }
  });

  if (!userActivityWhiteListedUrl(pathname)) return;

  TimeMe.initialize({
    currentPageName: pathname,
    idleTimeoutInSeconds: 60,
  });

  window.addEventListener('visibilitychange', handleVisibilityChange);
}

const handleVisibilityChange = () => {
  const { pathname } = window.location;

  if (document.visibilityState === 'visible') {
    return;
  }

  publishTimerAndResetActivityTracking();

  if (userActivityWhiteListedUrl(pathname)) {
    window.addEventListener('visibilitychange', handleVisibilityChange);
  }
};

initializeActivityTracking();
