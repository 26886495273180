import type { SemanticColorNames } from '@workramp/ui';
import type { ReactNode } from 'react';
import { Col, Icon, Row, Stack, Tooltip } from '@workramp/ui';
import { LabelWrapper, StatLabel, StatValue, StatWrapper } from './StatPanel.styles';

export interface StatPanelItemProps {
  label: string;
  value?: string | number | null;
  tooltip?: string;
  color?: SemanticColorNames;
  action?: ReactNode;
}

export function StatPanelItem({ label, value, tooltip, color, action }: StatPanelItemProps) {
  return (
    <StatWrapper>
      <Row align="center" wrap="nowrap" gapX={2}>
        <Col>
          <Stack size={1}>
            <StatValue size={4} $color={color}>
              {value ?? '--'}
            </StatValue>

            <LabelWrapper>
              <StatLabel level="md" maxLines={1} isExpandable>
                {label}
              </StatLabel>
              {tooltip && (
                <StatLabel level="md">
                  <Tooltip message={tooltip}>
                    <Icon size="match-font" name="info:regular" />
                  </Tooltip>
                </StatLabel>
              )}
            </LabelWrapper>
          </Stack>
        </Col>

        {action && <Col xs="auto">{action}</Col>}
      </Row>
    </StatWrapper>
  );
}
