window.GroupStore = {
  callbacks: [],
  groupDeleted(groupId) {
    this.invokeCallbacks(groupId);
  },
  invokeCallbacks(groupId) {
    for (let i = 0; i < this.callbacks.length; i++) {
      this.callbacks[i](groupId);
    }
  },
  addChangeListener(callback) {
    this.callbacks.push(callback);
  },
  removeChangeListener() {
    // TODO: implement
  },
};
