import _ from 'underscore';

class EventAttendeeRegistrationStatus {
  constructor(registrationStatus) {
    this.registrationStatusObject = this.constructor._registrationStatusObject(registrationStatus) || {};
  }

  value = () => {
    if (_.isEmpty(this.registrationStatusObject)) return;
    return this.registrationStatusObject.DB_VALUE;
  };

  displayValue = () =>
    I18n.t(`js.learner.events.attendees.registration_status.${this.registrationStatusObject.DB_VALUE}`);

  displayColor = () => this.registrationStatusObject.DEFAULT_DISPLAY_COLOR;

  // Not in use
  isInvited = () => this._equals(APP_CONSTANTS.EVENT_ATTENDEE_STATUSES.invited);

  isRegistered = () =>
    this._equals(APP_CONSTANTS.EVENT_ATTENDEE_STATUSES.registered) ||
    this._equals(APP_CONSTANTS.EVENT_ATTENDEE_STATUSES.attended) ||
    this._equals(APP_CONSTANTS.EVENT_ATTENDEE_STATUSES.absent);

  isWaitlisted = () => this._equals(APP_CONSTANTS.EVENT_ATTENDEE_STATUSES.waitlisted);

  // Not in use
  isArchived = () => this._equals(APP_CONSTANTS.EVENT_ATTENDEE_REG_STATUSES.ARCHIVED.DB_VALUE);

  // Not in use
  isRegistrationDeclined = () => this._equals(APP_CONSTANTS.EVENT_ATTENDEE_REG_STATUSES.DECLINED.DB_VALUE);

  // Not in use
  isUnregistered = () => this._equals(APP_CONSTANTS.EVENT_ATTENDEE_STATUSES.unregistered);

  _equals = (dbValue) => this.value() === dbValue;

  static _registrationStatusObject = (registrationStatus) =>
    _.chain(APP_CONSTANTS.EVENT_ATTENDEE_REG_STATUSES)
      .mapObject((obj) => obj)
      .find((obj) => obj.DB_VALUE === registrationStatus)
      .value();
}

export default EventAttendeeRegistrationStatus;
