import type { ReactNode } from 'react';
import { StatPanelItem } from './StatPanelItem';
import { ContainerWrapper } from './StatPanel.styles';
import { StatPanelCustomItem } from './StatPanelCustomItem';

export interface StatPanelProps {
  children: ReactNode;
}

export function StatPanel({ children }: StatPanelProps) {
  return <ContainerWrapper>{children}</ContainerWrapper>;
}

StatPanel.Item = StatPanelItem;
StatPanel.CustomItem = StatPanelCustomItem;
