import { Component, createRef } from 'react';
import styled, { css } from 'styled-components';
import { DEFAULT_THEME, OverlayProvider, ThemeProvider, Button } from '@workramp/ui';
import { Portal } from '~/features/common';
import OldButton from '../data-visualization/button';

export const MODAL_BASE_CLASS = 'my-modal';

/** @deprecated prefer `import { Modal } from '@workramp/ui';` */
class Modal extends Component {
  state = {
    isSaving: false,
    isShown: false,
  };

  TRANSLATED_DEFAULT_MODAL_CANCEL = I18n.t('common.application.cancel');

  TRANSLATED_DEFAULT_MODAL_SAVE = I18n.t('common.application.save');

  constructor(props) {
    super(props);
    this.modalRef = createRef();
  }

  componentDidMount() {
    const me = this;

    // When there isn't an input in the modal, we still want to save on enter.
    $(document).keydown(this.onKeyDown);
    $(this.modalRef.current).on('hidePrevented.bs.modal', this.onClickOutside);
    $(this.modalRef.current).on('shown.bs.modal', this.onShown);
    $(this.modalRef.current).on('hidden.bs.modal', this.onHidden);
  }

  componentWillUnmount() {
    $(document).off('keydown', this.onKeyDown);
    $(this.modalRef.current).off('hidePrevented.bs.modal');
    $(this.modalRef.current).off('shown.bs.modal');
    $(this.modalRef.current).off('hidden.bs.modal');
  }

  componentDidUpdate() {
    if (this.props.show) {
      $(this.modalRef.current).modal('show');
    } else {
      $(this.modalRef.current).modal('hide');
    }
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13 && !this.props.disableSaveOnEnter && this.props.show) {
      this.onSave();
    }
  };

  onClickOutside = (e) => {
    if (!this.state.isShown) {
      return;
    }

    this.onClose();
  };

  onShown = () => this.setState({ isShown: true });

  onHidden = () => this.setState({ isShown: false });

  exitButton() {
    return (
      <span className="flex-1 text-right">
        <ExitButton onClick={this.onClose}>
          <img src="/images/cross_icon_black.svg" width="12px" className="cursor-pointer" alt="Close modal" />
        </ExitButton>
      </span>
    );
  }

  saveButton() {
    const { newUi, saveButtonTitle, isDangerConfirm, saveDisabled } = this.props;
    const { isSaving } = this.state;

    if (newUi) {
      const disabled = saveDisabled || isSaving;
      let color;
      let variant;
      if (isDangerConfirm) {
        color = 'danger';
        variant = 'outline';
      } else {
        color = 'primary';
        variant = 'solid';
      }

      return (
        <Button isDisabled={disabled} color={color} variant={variant} onPress={() => this.onSave()}>
          {saveButtonTitle || this.TRANSLATED_DEFAULT_MODAL_SAVE}
        </Button>
      );
    }
    return (
      <Button className="mlm" onPress={this.onSave} isDisabled={saveDisabled || isSaving}>
        {saveButtonTitle || this.TRANSLATED_DEFAULT_MODAL_SAVE}
      </Button>
    );
  }

  closeButton() {
    const { newUi, cancelButtonTitle } = this.props;
    if (newUi) {
      return (
        <CloseButton color="secondary" variant="text" onPress={() => this.onClose()}>
          {cancelButtonTitle || this.TRANSLATED_DEFAULT_MODAL_CANCEL}
        </CloseButton>
      );
    }
    return (
      <Button className="mlm" onPress={this.onClose} variant="text" color="secondary">
        {cancelButtonTitle || this.TRANSLATED_DEFAULT_MODAL_CANCEL}
      </Button>
    );
  }

  render() {
    const {
      showBackdrop = true,
      wider,
      overflowVisible,
      className,
      newUi,
      dontShowHeader,
      wrapHeader,
      title,
      children,
      extraButtons,
      dontShowCancelButton,
      dontShowSaveButton,
    } = this.props;

    const modalStyleClass = wider ? ' wider' : '';
    const modalClass = `${MODAL_BASE_CLASS} modal fade grey-5 editable ${modalStyleClass}`;
    const modalContentOverflow = overflowVisible ? { overflow: 'visible' } : {};

    const themeWithOverlayContainers = {
      ...DEFAULT_THEME,
      overlays: {
        modalContainer: document.body,
        popoverContainer: document.body,
        toastContainer: document.body,
        tooltipContainer: document.body,
      },
    };

    return (
      <Portal>
        <ThemeProvider theme={themeWithOverlayContainers}>
          <OverlayProvider>
            <div
              className={`${modalClass} ${className || ''}`}
              ref={this.modalRef}
              tabIndex={-1}
              role="dialog"
              data-bs-backdrop={showBackdrop ? 'static' : 'false'}
              data-bs-keyboard="false"
            >
              <div className="modal-dialog" role="document">
                <ModalWrapper newUi={newUi} className="modal-content" style={modalContentOverflow}>
                  {dontShowHeader ? (
                    ''
                  ) : (
                    <ModalHeader wrapHeader={wrapHeader} className="my-modal-header flex">
                      <div className="hide-overflow mrl">{title}</div>
                      {this.exitButton()}
                    </ModalHeader>
                  )}
                  <div className="my-modal-body">{children}</div>
                  <div className="my-modal-footer text-right">
                    {extraButtons}
                    {dontShowCancelButton ? '' : this.closeButton()}
                    {dontShowSaveButton ? '' : this.saveButton()}
                  </div>
                </ModalWrapper>
              </div>
            </div>
          </OverlayProvider>
        </ThemeProvider>
      </Portal>
    );
  }

  onSave = () => {
    this.setState({ isSaving: true }, () => {
      this.props.onSave(() => {
        this.setState({ isSaving: false });
      });
    });
  };

  onClose = () => {
    this.props.onClose();
  };
}

window.Modal = Modal;

const ModalWrapper = styled.div`
  ${(props) =>
    props.newUi
      ? `
      box-shadow: none;
      border-radius: 8px;
  `
      : ''}
`;

const ModalHeader = styled.div`
  ${(props) =>
    props.wrapHeader
      ? `
    height: auto;
    padding-top: 18.5px;
    padding-bottom: 18.5px;
    .hide-overflow {
      overflow: visible;
      white-space: normal;
    }
    `
      : ''}
`;

const ExitButton = styled.button`
  background-color: transparent;
  border: none;
`;

const CloseButton = styled(Button)`
  margin-right: 12px;
`;

export default Modal;
