(function ($R) {
  $R.add('plugin', '|', {
    init: function (app) {
      this.app = app;
      this.opts = app.opts;
      this.toolbar = app.toolbar;
    },
    start: function () {
      this.toolbar.addDivider();
    }
  });
})(Redactor);
