/** @deprecated prefer `import { ProgressBar } from '@workramp/ui';` or `import { Meter } from '@workramp/ui';` */
function ThinProgressBar(props) {
  let { percentComplete } = props;
  if (percentComplete > 100) {
    percentComplete = 100;
  }
  const borderRadius = props.borderRadius ? props.borderRadius : 0;
  const styles = {
    complete: {
      WebkitBoxFlex: '0',
      WebkitFlex: `0 1 ${percentComplete}%`,
      msFlex: `0 1 ${percentComplete}%`,
      flex: `0 1 ${percentComplete}%`,
      backgroundColor: '#3168A8',
      height: `${props.height ? props.height : '3'}px`,
      textAlign: 'right',
      color: 'white',
      fontSize: '0.75rem',
      paddingRight: props.inlineStats ? '5px' : '0',
      paddingLeft: props.inlineStats ? '5px' : '0',
      minWidth: props.inlineStats ? '22px' : '0',
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: percentComplete === 100 ? borderRadius : 0,
      transition: 'flex-basis 0.5s ease',
    },
    inComplete: {
      WebkitBoxFlex: '0',
      WebkitFlex: `0 1 ${100 - percentComplete}%`,
      msFlex: `0 1 ${100 - percentComplete}%`,
      flex: `0 1 ${100 - percentComplete}%`,
      backgroundColor: '#AABFD7',
      height: `${props.height ? props.height : '3'}px`,
      borderTopLeftRadius: percentComplete === 100 ? borderRadius : 0,
      borderTopRightRadius: borderRadius,
      transition: 'flex-basis 0.5s ease',
    },
  };

  const positionStyle = props.relativePosition ? { position: 'relative', top: '3px' } : {};

  return (
    <div className="thin-progress-bar" style={positionStyle}>
      <div className="flex">
        <div style={styles.complete}>{props.inlineStats ? `${percentComplete} %` : null}</div>
        <div style={styles.inComplete} />
      </div>
      {props.showStats ? (
        <div className="fsxs text-right" style={{ marginTop: '2px' }}>
          <a href={props.link} className={props.link ? 'no-underline' : 'no-underline-hover'}>
            {`${percentComplete}%`}
          </a>
        </div>
      ) : null}
    </div>
  );
}

window.ThinProgressBar = ThinProgressBar;
export default ThinProgressBar;
