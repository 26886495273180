import { guid } from '../common';

export default class AlgoliaIndex {
  constructor(name, indexDefaultParams, formatResult, filterCallback) {
    this.name = name;
    this.id = guid();

    this.formatResult = formatResult || this.defaultFormatResult;
    this.filterCallback = filterCallback || this.defaultFilterResults;

    this.indexDefaultParams = indexDefaultParams;
  }

  format = (result) => this.formatResult(result);

  filter = (results, afterFilter) => this.filterCallback(results, afterFilter);

  getParams(gameTimeParams) {
    return { ...AlgoliaIndex.ALGOLIA_DEFAULT_PARAMS, ...this.indexDefaultParams, ...gameTimeParams };
  }

  defaultFormatResult(result) {
    return result;
  }

  defaultFilterResults(results) {
    return results;
  }
}

AlgoliaIndex.ALGOLIA_DEFAULT_PARAMS = {
  hitsPerPage: 10,
  attributesToSnippet: [],
  queryType: 'prefixAll',
  offset: 0,
  length: 50,
  analyticsTags: [],
};

window.AlgoliaIndex = AlgoliaIndex;
