import { Component } from 'react';
import { hideLoader } from '../../common';
import ThinProgressBar from './thin_progress_bar';

class UploadWithProgress extends Component {
  state = {
    uploadInProgress: false,
    uploadProgress: 0,
    uploadRequest: null,
  };

  componentDidMount() {
    this.handleUpload();
  }

  render() {
    let uploading = '';
    const percentComplete =
      this.state.uploadProgress == 100 ? 99 : this.state.uploadProgress == 0 ? 1 : this.state.uploadProgress;

    if (this.props.name) {
      uploading = <span className="upload-indicator-text plm1">Uploading {this.props.name}...</span>;
    } else {
      uploading = <span className="upload-indicator-text plm1">Uploading...</span>;
    }
    return (
      <div className="upload-with-progress flex">
        <ThinProgressBar percentComplete={percentComplete} />
        {!this.props.onlyShowProgressBar && (
          <div style={{ position: 'relative', top: '-1px' }}>
            {uploading}
            <img
              className="cursor-pointer plm1"
              src="/images/cross_icon_blue.svg"
              width="10px"
              height="10px"
              onClick={this.cancelUpload}
            />
          </div>
        )}
      </div>
    );
  }

  handleUpload = () => {
    const me = this;
    ChangesPendingStore.setUploadRequired();
    let apiInput = {
      xhr() {
        const xhr = new window.XMLHttpRequest();

        xhr.upload.addEventListener(
          'progress',
          (evt) => {
            if (evt.lengthComputable) {
              const pct = parseInt((evt.loaded / evt.total) * 100);
              me.setState({
                uploadInProgress: true,
                uploadProgress: pct,
              });
            }
          },
          false,
        );

        return xhr;
      },
      url: this.props.url,
      type: this.props.method ? this.props.method : 'POST',
      data: this.props.data,
      processData: false,
      contentType: this.props.isBinaryUpload ? 'binary/octet-stream' : false,
      success(result) {
        ChangesPendingStore.resetUploadRequired();
        if (me.props.uploadCompleted) {
          me.props.uploadCompleted(result, me.props.url);
        }
      },
      error(xhr) {
        hideLoader();
        ChangesPendingStore.resetUploadRequired();
        if (xhr.status == 403) {
          alert('You do not have access to complete this operation.');
        } else if (xhr.status != 0) {
          alert('An unexpected error occurred. Please try again.');
        }
        if (me.props.uploadFailed) {
          me.props.uploadFailed(null, me.props.url, xhr.statusText === 'abort');
        }
      },
    };

    if (this.props.header && this.props.header !== '') {
      apiInput = { ...apiInput, headers: { Authorization: this.props.header } };
    }

    const xhr = $.ajax(apiInput);

    this.setState({
      uploadRequest: xhr,
    });
  };

  cancelUpload = () => {
    if (this.state.uploadRequest && this.state.uploadRequest.readyState != 4) {
      this.state.uploadRequest.abort();
      this.setState({
        uploadInProgress: false,
        uploadProgress: 0,
        uploadRequest: null,
      });
    }
  };
}

window.UploadWithProgress = UploadWithProgress;

export default UploadWithProgress;
