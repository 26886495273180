const SUBMITTED_SORT_RANK = 0;
const UNSUBMITTED_SORT_RANK = 1;
const REVIEWED_SORT_RANK = 2;

const REVIEWED_STATUS_NAME = 'reviewed';
const PASSED_STATUS_NAME = 'passed';
const FAILED_STATUS_NAME = 'failed';
const UNDER_REVIEW_STATUS_NAME = 'Under Review';
const SUBMITTED_STATUS_NAME = 'submitted';
const UNSUBMITTED_STATUS_NAME = 'unsubmitted';

class ChallengeStatus {
  constructor(status, color) {
    this.status = status;
    this.color = color;
  }

  static get submittedSortRank() {
    return SUBMITTED_SORT_RANK;
  }

  static get unsubmittedSortRank() {
    return UNSUBMITTED_SORT_RANK;
  }

  static get reviewedSortRank() {
    return REVIEWED_SORT_RANK;
  }

  static get reviewedStatusName() {
    return I18n.t('js.learner.card.reviewed');
  }

  static get passedStatusName() {
    return I18n.t('js.learner.card.passed');
  }

  static get failedStatusName() {
    return I18n.t('js.learner.card.failed');
  }

  static get underReviewStatusName() {
    return I18n.t('js.learner.card.under_review');
  }

  static get submittedStatusName() {
    return I18n.t('js.learner.card.submitted');
  }

  static get unsubmittedStatusName() {
    return I18n.t('js.learner.card.unsubmitted');
  }

  getLabel() {
    /*
      Due to the nature of how this class is written and instantiated,
      I18n doesn't know what the current locale is yet which is why
      we need to determine the translated string via this hash method.

      Similarly, we cannot set this translated hash in the class constructor.

      Same for getReviewerLabel() below.
    */
    const status = {
      [REVIEWED_STATUS_NAME]: ChallengeStatus.reviewedStatusName,
      [PASSED_STATUS_NAME]: ChallengeStatus.passedStatusName,
      [FAILED_STATUS_NAME]: ChallengeStatus.failedStatusName,
      [UNDER_REVIEW_STATUS_NAME]: ChallengeStatus.underReviewStatusName,
      [SUBMITTED_STATUS_NAME]: ChallengeStatus.submittedStatusName,
      [UNSUBMITTED_STATUS_NAME]: ChallengeStatus.unsubmittedStatusName,
    }[this.status];
    return status || this.status.capitalize();
  }

  getReviewerLabel() {
    if (this.status === 'submitted') {
      return I18n.t('js.learner.card.needs_review');
    }
    if (this.status === 'unsubmitted') {
      return I18n.t('js.learner.card.unsubmitted_for_reviewer');
    }

    const status = {
      [REVIEWED_STATUS_NAME]: ChallengeStatus.reviewedStatusName,
      [PASSED_STATUS_NAME]: ChallengeStatus.passedStatusName,
      [FAILED_STATUS_NAME]: ChallengeStatus.failedStatusName,
      [UNDER_REVIEW_STATUS_NAME]: ChallengeStatus.underReviewStatusName,
      [SUBMITTED_STATUS_NAME]: ChallengeStatus.submittedStatusName,
      [UNSUBMITTED_STATUS_NAME]: ChallengeStatus.unsubmittedStatusName,
    }[this.status];
    return status || this.status.capitalize();
  }

  isReviewed() {
    return [this.constructor.REVIEWED.status, this.constructor.PASSED.status, this.constructor.FAILED.status].includes(
      this.status,
    );
  }

  isPartiallyReviewed() {
    return this.status === this.constructor.UNDER_REVIEW.status;
  }

  isSubmitted() {
    return this.status === this.constructor.SUBMITTED.status;
  }

  isUnsubmitted() {
    return this.status === this.constructor.UNSUBMITTED.status;
  }

  static getStatus(challengeAssignment) {
    if (challengeAssignment.isFullyGraded) {
      return challengeAssignment.passed ? this.PASSED : this.FAILED;
    }
    if (challengeAssignment.isPartiallyGraded) return this.UNDER_REVIEW;
    if (challengeAssignment.isSubmitted) return this.SUBMITTED;
    return this.UNSUBMITTED;
  }

  static getStatusReviewerView(challengeAssignment, reviewerReviewed) {
    if (reviewerReviewed) return this.REVIEWED;
    if (!challengeAssignment.isSubmitted) return this.UNSUBMITTED;
    return this.SUBMITTED;
  }
}

ChallengeStatus.REVIEWED = new ChallengeStatus(REVIEWED_STATUS_NAME, '#42A655');
ChallengeStatus.PASSED = new ChallengeStatus(PASSED_STATUS_NAME, '#42A655');
ChallengeStatus.FAILED = new ChallengeStatus(FAILED_STATUS_NAME, '#D81A1A');
ChallengeStatus.UNDER_REVIEW = new ChallengeStatus(UNDER_REVIEW_STATUS_NAME, '#ff9900');
ChallengeStatus.SUBMITTED = new ChallengeStatus(SUBMITTED_STATUS_NAME, '#2195f3');
ChallengeStatus.UNSUBMITTED = new ChallengeStatus(UNSUBMITTED_STATUS_NAME, '#e03ffb');

export default ChallengeStatus;
