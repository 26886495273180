export const iconFromAssetType = (type) => {
  const typeIconMap = {
    [AssetPickerTypes.ACADEMY_CERTIFICATION]: AssetPickerTypes.ACADEMY_CERTIFICATION,
    [AssetPickerTypes.ACADEMY_PATH]: AssetPickerTypes.ACADEMY_PATH,
    [AssetPickerTypes.ACADEMY_EVENT]: AssetPickerTypes.ACADEMY_EVENT,
    [AssetPickerTypes.ACADEMY_COLLECTION]: AssetPickerTypes.ACADEMY_COLLECTION,
    [AssetPickerTypes.CHALLENGE]: AssetPickerTypes.CHALLENGE,
    [AssetPickerTypes.CLASS]: 'event',
    [AssetPickerTypes.EVENT]: AssetPickerTypes.EVENT,
    [AssetPickerTypes.EVENT_TEMPLATE]: 'schedule-template',
    [AssetPickerTypes.FOLDER]: 'folder-closed',
    [AssetPickerTypes.GO1_CONTENT]: AssetPickerTypes.GO1_CONTENT,
    [AssetPickerTypes.GUIDE_SECTION]: 'list',
    [AssetPickerTypes.GUIDE_TASK]: 'check-circle',
    [AssetPickerTypes.GUIDE]: AssetPickerTypes.GUIDE,
    [AssetPickerTypes.LIBRARY]: 'library',
    [AssetPickerTypes.RESOURCE]: AssetPickerTypes.RESOURCE,
    [AssetPickerTypes.SCORM]: AssetPickerTypes.SCORM,
    [AssetPickerTypes.SECTION]: 'list',
    [AssetPickerTypes.SERIES]: 'path',
    [AssetPickerTypes.TASK]: 'check-circle',
    [AssetPickerTypes.UDEMY]: '',
    [AssetPickerTypes.UNIVERSITY]: 'library',
    [AssetPickerTypes.WORKFLOW]: '',
    // advanced search results doesn't use AssetPickerType enum:
    'guide task': 'check-circle',
    'guide section': 'list',
    path: 'path',
  };

  return typeIconMap[type];
};

export const colorFromAssetType = (type) => {
  const typeColorMap = {
    [AssetPickerTypes.ACADEMY_CERTIFICATION]: 'neutral',
    [AssetPickerTypes.CHALLENGE]: 'yellow',
    [AssetPickerTypes.CLASS]: 'red',
    [AssetPickerTypes.EVENT]: 'red',
    [AssetPickerTypes.EVENT_TEMPLATE]: 'cyan',
    [AssetPickerTypes.FOLDER]: 'neutral',
    [AssetPickerTypes.GO1_CONTENT]: 'neutral',
    [AssetPickerTypes.GUIDE_SECTION]: 'blue',
    [AssetPickerTypes.GUIDE_TASK]: 'blue',
    [AssetPickerTypes.GUIDE]: 'blue',
    [AssetPickerTypes.LIBRARY]: 'purple',
    [AssetPickerTypes.RESOURCE]: 'teal',
    [AssetPickerTypes.SCORM]: 'orange',
    [AssetPickerTypes.SECTION]: 'blue',
    [AssetPickerTypes.SERIES]: 'green',
    [AssetPickerTypes.TASK]: 'blue',
    [AssetPickerTypes.UDEMY]: 'neutral',
    [AssetPickerTypes.UNIVERSITY]: 'purple',
    [AssetPickerTypes.WORKFLOW]: 'yellow',
    'folder-closed': 'neutral',
    // advanced search results doesn't use AssetPickerType enum:
    'guide task': 'blue',
    'guide section': 'blue',
    path: 'green',
  };

  return typeColorMap[type];
};

export const GO1_FOLDER_ADMIN_IMPORT_ROOT = 'Admin Imported Content';
export const GO1_FOLDER_LEARNER_IMPORT_ROOT = 'Learner Imported Content';
