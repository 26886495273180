import styled from 'styled-components';
import CircularProgressbar from 'react-circular-progressbar';
import { common } from '../../styles';
import { WrTooltip } from '../../styled-components/common/wr-tooltip';

const PathModuleStatusCircle = (props) => {
  if (props.completed) {
    return (
      <div>
        <FullCircle>
          <img src="/images/check_icon_white.svg" width="10px" height="10px" />
        </FullCircle>
      </div>
    );
  }
  if (props.locked) {
    return (
      <WrTooltip
        content={`This ${props.type} is locked. Please finish all previous modules before starting.`}
        tagName="span"
        zIndex={2000}
      >
        <img src="/images/icon_lock_2.svg" width="20px" height="20px" />
      </WrTooltip>
    );
  }
  return (
    <ProgressCircle>
      <CircularProgressbar
        percentage={props.percentComplete ? props.percentComplete : 0}
        strokeWidth={10}
        styles={{
          path: { stroke: 'url(#path-progress-gradient)' },
          trail: { stroke: '#E5EBF1' },
        }}
      />
      <GradientSVG startColor="#9ed9e1" endColor="#5ba7e8" idCSS="path-progress-gradient" />
    </ProgressCircle>
  );
};

const FullCircle = styled.div`
  ${common.flex}
  justify-content: center;
  background: linear-gradient(#1d9eef, #c9f1ea);
  width: 20px;
  height: 20px;
  border-radius: 10px;
`;

const ProgressCircle = styled.div`
  background-color: white;
  width: 20px;
  height: 20px;
`;

const GradientSVG = (props) => {
  const { startColor, endColor, idCSS } = props;

  const gradientTransform = 'rotate(90)';

  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={idCSS} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor={endColor} />
          <stop offset="100%" stopColor={startColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PathModuleStatusCircle;
