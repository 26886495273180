function FileExtension(filename) {
  const parts = (filename || '').split('.');
  const name = parts[0];
  const ext = parts.pop().split('?')[0].toLowerCase();

  this.getExt = function () {
    return ext;
  };

  this.getName = function () {
    return name;
  };

  this.isPreviewableVideo = function () {
    return FileExtension.isPreviewableVideo(ext);
  };

  this.isVideo = function () {
    return FileExtension.isVideo(ext);
  };

  this.isImage = function () {
    return FileExtension.isImage(ext);
  };

  this.isAudio = function () {
    return FileExtension.isAudio(ext);
  };

  this.isPreviewable = function () {
    return FileExtension.isPreviewable(ext);
  };

  this.isScorm = function () {
    return FileExtension.isScorm(ext);
  };

  this.isSubtitle = function () {
    return FileExtension.isSubtitle(ext);
  };

  return this;
}

// File extension categories
FileExtension.videoExts = ['avi', 'flv', 'mov', 'mp4', 'mpeg', 'mpg', 'webm', 'wmv'];
FileExtension.previewableVideoExts = ['flv', 'mp4', 'webm'];
FileExtension.audioExts = ['aac', 'm4a', 'm4b', 'mp3', 'ogg', 'wav'];
FileExtension.imageExts = ['gif', 'jpeg', 'jpg', 'png'];
FileExtension.previewableExts = ['doc', 'docx', 'md', 'pdf', 'ppt', 'pptx', 'txt', 'xls', 'xlsx'];
FileExtension.scormExts = ['zip'];
FileExtension.subtitleExts = ['vtt'];

FileExtension.isVideo = function (ext) {
  return FileExtension.videoExts.includes(ext);
};
FileExtension.isPreviewableVideo = function (ext) {
  return FileExtension.previewableVideoExts.includes(ext);
};
FileExtension.isImage = function (ext) {
  return FileExtension.imageExts.includes(ext);
};
FileExtension.isPreviewable = function (ext) {
  return FileExtension.previewableExts.includes(ext);
};
FileExtension.isAudio = function (ext) {
  return FileExtension.audioExts.includes(ext);
};
FileExtension.isScorm = function (ext) {
  return FileExtension.scormExts.includes(ext);
};
FileExtension.isSubtitle = function (ext) {
  return FileExtension.subtitleExts.includes(ext);
};

window.FileExtension = (filename) => new FileExtension(filename);

window.getExtensionCheckForType = function (type) {
  switch (type) {
    case 'video':
      return FileExtension.isVideo;
    case 'preview_video':
      return FileExtension.isPreviewableVideo;
    case 'image':
      return FileExtension.isImage;
    case 'preview':
      return FileExtension.isPreviewable;
    case 'audio':
      return FileExtension.isAudio;
    case 'scorm':
      return FileExtension.isScorm;
    case 'subtitle':
      return FileExtension.isSubtitle;
    default:
      return undefined;
  }
};

window.getExtensionErrorMessageForType = function (type) {
  let extensions = [];
  switch (type) {
    case 'video':
      extensions = FileExtension.videoExts;
      break;
    case 'preview_video':
      extensions = FileExtension.previewableVideoExts;
      break;
    case 'image':
      extensions = FileExtension.imageExts;
      break;
    case 'preview':
      extensions = FileExtension.previewableExts;
      break;
    case 'audio':
      extensions = FileExtension.audioExts;
      break;
    case 'scorm':
      extensions = FileExtension.scormExts;
      break;
    case 'subtitle':
      extensions = FileExtension.subtitleExts;
      break;
    default:
      return undefined;
  }
  const validExtensions =
    extensions.length > 1 ? `${extensions.slice(0, -1).join(', ')} or ${extensions.slice(-1)[0]}` : extensions[0];
  return `Please select a ${validExtensions} file to upload`;
};
