window.AnalyticsStore = function () {
  this.options = {};
  this.callbacks = [];
};

AnalyticsStore.prototype.add = function (name, option) {
  this.options[name] = option;
};

AnalyticsStore.prototype.get = function (name) {
  return this.options[name];
};

AnalyticsStore.prototype.remove = function (name) {
  if (this.options[name]) {
    delete this.options[name];
  }
};

AnalyticsStore.prototype.notify = function (type) {
  for (let i = 0; i < this.callbacks.length; i++) {
    this.callbacks[i](type, this.options);
  }
};

AnalyticsStore.prototype.addChangeListener = function (callback) {
  this.callbacks.push(callback);
};

AnalyticsStore.prototype.setOptions = function (options) {
  this.options = options;
};

AnalyticsStore.prototype.removeChangeListener = function () {
  // TODO: implement
};
