import { MixPanel } from "~/common";

const mixPanelTrackButtonClick = (button, eventName) => {
  const clickedButtonName = button.obj.tooltip.toLowerCase().replaceAll(' ','-');
  MixPanel.track(`${eventName}_${clickedButtonName}_formatting`);
}

(function($R){
    $R.add('plugin', 'mixpanel', {
        init: function(app) {
          this.app = app;
          this.toolbar = app.toolbar;
          this.opts = app.opts;
        },
        start: function() {
          const { opts, toolbar } = this;
          const { mixPanelFormatEventName } = opts;
          
          if (mixPanelFormatEventName) {
            const buttons = toolbar.getButtons();
            buttons.forEach(button => {
              button.nodes[0].addEventListener('click', () => mixPanelTrackButtonClick(button, mixPanelFormatEventName))
            })
          }
        }
    });
})(Redactor);
