import _ from 'underscore';
import { Component } from 'react';
import { guid } from '../../../common';

export default function withSearchResultsManagement(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = { results: undefined, searches: [] };
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          results={this.state.results}
          searching={this.state.searches.length > 0}
          beforeSearch={this.beforeSearch}
          afterSearch={this.afterSearch}
          clear={this.clear}
        />
      );
    }

    beforeSearch = () => {
      const searchId = guid();
      this.setState({ searches: this.state.searches.concat(searchId) });
      return searchId;
    };

    afterSearch = (searchId, results) => {
      const searches = _.filter(this.state.searches, (id) => id !== searchId);
      this.setState({ searches, results });
    };

    clear = () => {
      this.setState({ results: null, searches: [] });
    };
  };
}

window.withSearchResultsManagement = withSearchResultsManagement;
