import type { ReactNode } from 'react';
import { StatWrapper } from './StatPanel.styles';

export interface StatPanelCustomItemProps {
  children: ReactNode;
}

export function StatPanelCustomItem({ children }: StatPanelCustomItemProps) {
  return <StatWrapper>{children}</StatWrapper>;
}
