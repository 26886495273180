import { I18n } from '~/common/explicit-exports';

export const CMS_BASE_URL = '/admin/cms';
export const CMS_API_ROOT_URL = 'cms';
export const LIBRARY_API_ROOT_URL = 'admin/libraries';
export const UNIVERSITY_API_ROOT_URL = 'universities';

const MEGABYTE = 1024 * 1024;
export const MAX_FILE_SIZE = 1024 * MEGABYTE;

export const MAX_FILE_SIZE_TEXT = '1GB';
export const MAX_URL_LENGTH = 5000;

export const CMS_PREVIEWABLE_FILE_EXTENSIONS = [
  'txt',
  'md',
  'pdf',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
  'csv',
  'gif',
  'jpeg',
  'jpg',
  'png',
  'svg',
  'zip',
];

export const CMS_SUPPORTED_FILE_EXTENSIONS = [...CMS_PREVIEWABLE_FILE_EXTENSIONS, 'pages', 'numbers', 'key'];

export const CMS_SUPPORTED_CARD_BANNER_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

export const GET_LIST_ASSET_KEY = 'getListAssets';
export const CMS_ASSET_DETAILS_KEY = 'cmsAssetDetails';
export const CMS_RECENT_ACTIVITY_KEY = 'cmsRecentActivityKey';
export const CMS_RECORD_VIEW_KEY = 'cmsRecordViewKey';

export const CMS_RETRY_CONFIG = { retry: 5 };

export enum CMSAssetAuditAction {
  LINK_COPY = 'LINK_COPY',
  FILE_DOWNLOAD = 'FILE_DOWNLOAD',
  FILE_VIEW = 'FILE_VIEW',
  LINK_VIEW = 'LINK_VIEW',
}

export const CMSAssetAuditActionStringMap = {
  [CMSAssetAuditAction.LINK_COPY]: I18n.t('js.admin.cms.recent_activity_status.link_copy'),
  [CMSAssetAuditAction.FILE_DOWNLOAD]: I18n.t('js.admin.cms.recent_activity_status.file_download'),
  [CMSAssetAuditAction.FILE_VIEW]: I18n.t('js.admin.cms.recent_activity_status.file_view'),
  [CMSAssetAuditAction.LINK_VIEW]: I18n.t('js.admin.cms.recent_activity_status.link_view'),
};

export enum CMSTopLevelContentTypes {
  CMS_FILE = 'cms_file',
  CMS_LINK = 'cms_link',
}

export enum CMSAdminMixpanelEvents {
  ADD_ASSET_CLICKED = 'cms_details_page_add_asset',
  ASSET_ADDED = 'cms_details_page_asset_added',
  VIEW_ASSET = 'cms_details_page_view_asset',
  EDIT_ASSET = 'cms_details_page_edit_asset',
  REPLACE_ASSET = 'cms_details_page_replace_asset',
  DELETE_ASSET = 'cms_details_page_delete_asset',
  DOWNLOAD_ASSET = 'cms_details_page_download_asset',
  ASSET_EDITED = 'cms_details_page_asset_edited',
  ASSET_FILE_REPLACED = 'cms_details_page_asset_file_replaced',
  EDIT_ASSET_CANCELLED = 'cms_details_page_edit_asset_cancelled',
  ASSET_ADD_TO_LIB_FROM_LIB = 'cms_details_page_add_asset_to_library',
  ASSET_ADDED_TO_LIB_FROM_LIB = 'cms_details_page_asset_added_to_library',
}

export const SHOW_METRICS_THRESHOLD = 2;
