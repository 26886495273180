window.ListUtils = {
  findById(immutableList, id) {
    return immutableList.find((item) => item.id === id);
  },

  findByIds(immutableList, ids) {
    return immutableList.filter((item) => ids.includes(item.id));
  },

  removeById(immutableList, id) {
    return immutableList.filter((item) => item.id !== id);
  },

  removeByIds(immutableList, ids) {
    return immutableList.filter((item) => !ids.includes(item.id));
  },

  /**
   * Run update function on items within the list where id is in ids. Note that this function currently mutates the
   * items in the list and returns the same list that was passed in. We should consider changing this if we want to move
   * to full immutability.
   */
  updateItemsWithIds(immutableList, ids, update) {
    ids.forEach((id) => {
      const item = ListUtils.findById(immutableList, id);
      update(item);
    });
    return immutableList;
  },
};
