import _ from 'underscore';
import { learnerGoToCmsContentViaSearchResult } from '~/features/cms/utils';
import { CMSTopLevelContentTypes } from '~/features/cms/constants';
import AssetPickerTypes from '../../components/common/asset-picker/asset-picker-types';
import LearningObjectIcon from '../../components/common/asset-picker/LearningObjectIcon';

const TrainingIndexFormatters = {
  formatCourseResult: (result) => {
    const snippetResult = result._snippetResult;
    const name = snippetResult && snippetResult.name ? snippetResult.name.value : result.name;
    const description =
      snippetResult && snippetResult.description ? snippetResult.description.value : result.description;
    const tags = TrainingIndexFormatters._getTags(result);
    const nestedTasks = _.map(result.nested_tasks, (nestedTask) =>
      TrainingIndexFormatters.formatCourseTaskResult(nestedTask),
    );
    const nestedSections = _.map(result.nested_sections, (nestedSection) =>
      TrainingIndexFormatters.formatCourseSectionResult(nestedSection),
    );

    return {
      icon: <LearningObjectIcon type={AssetPickerTypes.GUIDE} />,
      lastUpdatedAt: result.updated_at,
      lastUpdatedBy: result.last_updated_by_name,
      name,
      content: description,
      onSelect: () => {
        window.location.href = AssetPickerTypes.getViewItemUrl(result);
      },
      nestedSections,
      nestedTasks,
      searchResult: result,
      tags,
      type: 'Guide',
    };
  },

  formatCourseTaskResult: (result) => {
    const TRANSLATED_GUIDE_TYPE_DISPLAY_NAME = I18n.t('common.content.guide', { count: 1 });
    const TRANSLATED_SECTION_TYPE_DISPLAY_NAME = I18n.t('common.content.section', { count: 1 });
    const TRANSLATED_UNTITLED_GUIDE = I18n.t('common.untitled_content', {
      content: TRANSLATED_GUIDE_TYPE_DISPLAY_NAME,
    });
    const TRANSLATED_UNTITLED_SECTION = I18n.t('common.untitled_content', {
      content: TRANSLATED_SECTION_TYPE_DISPLAY_NAME,
    });

    const snippetResult = result._snippetResult;
    const contentMatch = TrainingIndexFormatters._isMatch(snippetResult.content);
    const nameMatch = TrainingIndexFormatters._isMatch(snippetResult.name);
    const guideTitle = result.guide_title || TRANSLATED_UNTITLED_GUIDE;
    const sectionTitle = result.section_title || TRANSLATED_UNTITLED_SECTION;
    const name = nameMatch ? snippetResult.name.value : result.name;
    const content = contentMatch ? snippetResult.content.value : '';

    return {
      id: result.id,
      content,
      guideTitle,
      lastUpdatedAt: result.updated_at,
      icon: <LearningObjectIcon type={AssetPickerTypes.GUIDE_TASK} />,
      onSelect: () => {
        window.location.href = AssetPickerTypes.getViewItemUrl(result);
      },
      searchResult: result,
      name,
      sectionTitle,
      type: 'Guide Task',
    };
  },

  formatCourseSectionResult: (result) => {
    const TRANSLATED_GUIDE_TYPE_DISPLAY_NAME = I18n.t('common.content.guide', { count: 1 });
    const TRANSLATED_UNTITLED_GUIDE = I18n.t('common.untitled_content', {
      content: TRANSLATED_GUIDE_TYPE_DISPLAY_NAME,
    });

    const snippetResult = result._snippetResult;
    const name = snippetResult && snippetResult.name ? snippetResult.name.value : result.name;
    const guideTitle = result.guide_title || TRANSLATED_UNTITLED_GUIDE;

    return {
      icon: <LearningObjectIcon type={AssetPickerTypes.GUIDE_SECTION} />,
      guideTitle,
      lastUpdatedAt: result.updated_at,
      name,
      onSelect: () => {
        window.location.href = AssetPickerTypes.getViewItemUrl(result);
      },
      searchResult: result,
      type: 'Guide Section',
    };
  },

  formatChallengeResult: (result) => {
    const snippetResult = result._snippetResult;
    const name = snippetResult && snippetResult.name ? snippetResult.name.value : result.name;
    const description =
      snippetResult && snippetResult.description ? snippetResult.description.value : result.description;
    const tags = TrainingIndexFormatters._getTags(result);

    return {
      icon: <LearningObjectIcon type={AssetPickerTypes.CHALLENGE} />,
      lastUpdatedAt: result.updated_at,
      lastUpdatedBy: result.last_updated_by_name,
      name,
      content: description,
      onSelect: () => {
        window.location.href = AssetPickerTypes.getViewItemUrl(result);
      },
      searchResult: result,
      tags,
      type: 'Challenge',
    };
  },

  formatPathResult: (result) => {
    const snippetResult = result._snippetResult;
    const name = snippetResult && snippetResult.name ? snippetResult.name.value : result.name;
    const description =
      snippetResult && snippetResult.description ? snippetResult.description.value : result.description;
    const tags = TrainingIndexFormatters._getTags(result);

    return {
      icon: <LearningObjectIcon type={AssetPickerTypes.SERIES} />,
      lastUpdatedAt: result.updated_at,
      lastUpdatedBy: result.last_updated_by_name,
      name,
      content: description,
      onSelect: () => {
        window.location.href = AssetPickerTypes.getViewItemUrl(result);
      },
      searchResult: result,
      tags,
      type: 'Path',
    };
  },

  formatResourceResult: (result) => {
    const snippetResult = result._snippetResult;
    const name = snippetResult && snippetResult.name ? snippetResult.name.value : result.name;
    const tags = TrainingIndexFormatters._getTags(result);
    const contentMatch = TrainingIndexFormatters._isMatch(snippetResult.content);
    const content = contentMatch ? snippetResult.content.value : '';

    return {
      content,
      icon: <LearningObjectIcon type={AssetPickerTypes.RESOURCE} />,
      lastUpdatedAt: result.updated_at,
      lastUpdatedBy: result.last_updated_by_name,
      name,
      onSelect: () => {
        window.location.href = AssetPickerTypes.getViewItemUrl(result);
      },
      searchResult: result,
      tags,
      type: 'Resource',
    };
  },

  formatUniversityResult: (result) => {
    const snippetResult = result._snippetResult;
    const name = snippetResult && snippetResult.name ? snippetResult.name.value : result.name;
    const tags = TrainingIndexFormatters._getTags(result);

    return {
      icon: <LearningObjectIcon type={AssetPickerTypes.UNIVERSITY} />,
      lastUpdatedAt: result.updated_at,
      lastUpdatedBy: result.last_updated_by_name,
      name,
      onSelect: () => {
        window.location.href = AssetPickerTypes.getViewItemUrl(result);
      },
      searchResult: result,
      tags,
      type: 'University',
    };
  },

  formatTopLevelContentResult: (result) => {
    const snippetResult = result._snippetResult;
    const name = snippetResult && snippetResult.name ? snippetResult.name.value : result.name;
    const tags = TrainingIndexFormatters._getTags(result);
    // Check if the search term was found in the tlc's algolia content field (the indexed text of the file)
    const contentSnippetMatchFound = TrainingIndexFormatters._isMatch(snippetResult.content);
    const contentSnippetText = contentSnippetMatchFound ? snippetResult.content?.value : '';
    const descriptionText =
      snippetResult && snippetResult.description ? snippetResult.description.value : result.description;

    // If the search term was found in the tlc's algolia content field then display that content snippet.
    // Otherwise, display the description (snippet or description field) as the default.
    const contentToDisplay = contentSnippetText || descriptionText;

    return {
      icon: <LearningObjectIcon type={result.type} />,
      lastUpdatedAt: result.updated_at,
      lastUpdatedBy: result.last_updated_by_name,
      content: contentToDisplay,
      name,
      onSelect: () => {
        if (result.type === AssetPickerTypes.UDEMY) {
          window.open(AssetPickerTypes.getViewItemUrl(result), '_blank');
        } else if (result.type === CMSTopLevelContentTypes.CMS_FILE) {
          learnerGoToCmsContentViaSearchResult(result.id, CMSTopLevelContentTypes.CMS_FILE);
        } else if (result.type === CMSTopLevelContentTypes.CMS_LINK) {
          learnerGoToCmsContentViaSearchResult(result.id, CMSTopLevelContentTypes.CMS_LINK);
        } else {
          window.location.href = AssetPickerTypes.getViewItemUrl(result);
        }
      },
      searchResult: result,
      tags,
      type: result.type,
    };
  },
};

TrainingIndexFormatters._isMatch = function (snippet) {
  return snippet && snippet.matchLevel !== 'none';
};

TrainingIndexFormatters._getTags = function (result) {
  const snippetResult = result._snippetResult;
  const tags = snippetResult ? snippetResult.object_tags : null;
  if (tags) {
    const matchedTags = _.filter(tags, (tag) => TrainingIndexFormatters._isMatch(tag));
    return _.map(matchedTags, (tag, idx) => {
      // add space to all tags after the first, as we directly display this via dangerouslySetInnerHTML
      if (idx !== 0) return ` ${tag.value}`;
      return tag.value;
    });
  }
};

TrainingIndexFormatters.formatTrainingResult = (result) => {
  if (_.contains([...APP_CONSTANTS.SEARCHABLE_TOP_LEVEL_CONTENT_TYPES], result.type)) {
    return TrainingIndexFormatters.formatTopLevelContentResult(result);
  }

  switch (result.type) {
    case AssetPickerTypes.GUIDE:
      return TrainingIndexFormatters.formatCourseResult(result);
    case AssetPickerTypes.GUIDE_TASK:
      return TrainingIndexFormatters.formatCourseTaskResult(result);
    case AssetPickerTypes.GUIDE_SECTION:
      return TrainingIndexFormatters.formatCourseSectionResult(result);
    case AssetPickerTypes.CHALLENGE:
      return TrainingIndexFormatters.formatChallengeResult(result);
    case AssetPickerTypes.SERIES:
      return TrainingIndexFormatters.formatPathResult(result);
    case AssetPickerTypes.RESOURCE:
      return TrainingIndexFormatters.formatResourceResult(result);
    case AssetPickerTypes.UNIVERSITY:
      return TrainingIndexFormatters.formatUniversityResult(result);
    default:
      console.log('Received an invalid training item.');
  }
};

window.TrainingIndexFormatters = TrainingIndexFormatters;

export default TrainingIndexFormatters;
