/** legacy admin settings panels vertical padding */
export const AdminSettingsVerticalPadding = {
  paddingTop: '36px',
  paddingBottom: '120px',
};

/** @deprecated prefer using the `theme` prop made available by `<ThemeProvider>` in styled-components */
export const media = {
  smallMobile: '@media (max-width: 400px)',
  mobile: '@media (max-width: 767px)',
  screenXsMax: '@media (max-width: 767px)',
  screenSmMax: '@media (max-width: 991px)',
  desktopMedium: '@media (max-width: 1000px)',
  desktopLarge: '@media (max-width: 1200px)',
};

/** @deprecated prefer using the `theme` prop made available by `<ThemeProvider>` in styled-components */
export const common = {
  hideOverflow: `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  unsetOverflow: `
    overflow: initial;
    text-overflow: initial;
    white-space: normal;
  `,
  flex: `
    display: flex;
    align-items: center;
  `,
  flex1: `
    flex: 1 1 auto;
  `,
  breakWord: `
    word-wrap: break-word;
    word-break: break-word;
  `,
};

/** @deprecated prefer using the `theme` prop made available by `<ThemeProvider>` in styled-components */
export const theme = {
  font: {
    regular: `
      font-weight: 400;
    `,
    medium: `
      font-weight: 500;
    `,
    semibold: `
      font-weight: 600;
    `,
    bold: `
      font-weight: 700;
    `,
  },

  color: {
    accentBlue: '#009CD1',
    darkBlue: '#203A8E',
    error: '#F9292D',
    failingRed: '#ff0000',
    lightBackground: '#F9FAFB',
    lightGreyBackground: '#F9F9F9',
    overdue: '#f15c76',
    passingGreen: '#008000',
    placeholder: '#C0CBD6',
    t18TextSecondary: '#7E7E7E',
    textDescription: '#444649',
    textPrimary: '#2B313F',
  },

  boxShadow: {
    t18ShadowElevation1: '0 3px 5px rgba(161, 179, 200, 0.23)',
    t18ShadowElevation2: '0 3px 5px rgba(161, 179, 200, 0.7)',
  },

  gradient: {
    blue: 'linear-gradient(to right, #1D9EEF , #C9F1EA)',
  },

  button: {
    context: `
      background-color: white;
      border-radius: 4px;
      opacity: 0.95;
      box-shadow: 0 0 4px rgba(0,0,0,0.1);
      padding: 6px;
    `,
  },

  reporting: {
    color: {
      textPrimary: '#414752',
      lightGrey: '#EFF2F6',
      textDescription: '#95A7B7',
    },

    link: `
      &:link,
      &:visited {
        color: #414752;
      }

      &:hover {
        text-decoration: none;
      }
    `,
  },
};

/** @deprecated prefer using the `theme` prop made available by `<ThemeProvider>` in styled-components */
theme.t18Card = {
  context: `
    border-radius: 6px;
    box-shadow: ${theme.boxShadow.t18ShadowElevation1};
  `,
};

/** @deprecated prefer using the `theme` prop made available by `<ThemeProvider>` in styled-components */
theme.trainingContent = {
  subHeader: {
    context: `
    color: ${theme.color.textPrimary};
    ${theme.font.semibold};
    font-size: 1rem;
    margin-bottom: 1rem;
    position: relative;
    margin-top: 1.5rem;
    `,
  },
};

/** @deprecated prefer using the `theme` prop made available by `<ThemeProvider>` in styled-components */
export const sidebar = {
  sidebarPadding: '1.5rem',
  sidebarWidthSmall: '250px',
  sidebarWidthLarge: '300px',
};

/** @deprecated prefer using the `theme` prop made available by `<ThemeProvider>` in styled-components */
sidebar.context = `
  width: ${sidebar.sidebarWidthLarge};
  background: #F9FAFB;
  height: 100%;
  box-shadow: inset -4px 0 12px 0 rgba(0, 0, 0, .05);
  box-sizing: border-box;

  ${media.desktopMedium} {
    width: ${sidebar.sidebarWidthSmall};
  }
`;

/** @deprecated prefer using the `theme` prop made available by `<ThemeProvider>` in styled-components */
theme.editPencilIcon = {
  context: `
    fill: ${theme.color.textDescription};
    margin-left: 4px;
    opacity: 0;
`,
};

/** @deprecated prefer using the `theme` prop made available by `<ThemeProvider>` in styled-components */
theme.contentDetailWrapper = {
  context: `
    ${theme.font.semibold}
    font-size: 0.75rem;
    display: flex;
    color: ${theme.color.textDescription};
    margin-right: 0.75rem;
    cursor: default;
    align-items: center;
  `,
};

/** @deprecated prefer using the `theme` prop made available by `<ThemeProvider>` in styled-components */
theme.editableContentDetailWrapper = {
  context: `
    ${theme.contentDetailWrapper.context}
    cursor: pointer;
  `,
};

/** @deprecated prefer using the `theme` prop made available by `<ThemeProvider>` in styled-components */
export const theme2020 = {
  color: {
    blue: '#0F48E6',
    greyBorder: 'rgba(8, 10, 13, .12)',
    inputGreyBorder: '#E6EAEF',
    unselectedGrey: '#2F3A4C',
    labelGrey: '#253256',
    uploadFileBgGrey: '#F2F4F7',
  },

  card: {
    context: `
      background: #FFFFFF;
      box-shadow: 0 0 0 1px #E6EAEF, 0 4px 4px -2px rgba(6,8,14,0.16), 0 16px 16px -8px rgba(6,8,14,0.08);
      border-radius: 4px;
    `,
  },

  inputFocusBlueShadow: {
    context: `
      box-shadow: 0 0 0 3px #537EF3;
    `,
  },
};

/** @deprecated prefer using the `theme` prop made available by `<ThemeProvider>` in styled-components */
theme2020.inputEntryText = {
  context: `
    font-size: .875rem;
    color: ${theme2020.color.labelGrey}
    opacity: .8;
  `,
};

/** @deprecated prefer using the `theme` prop made available by `<ThemeProvider>` in styled-components */
theme2020.inputLabel = {
  context: `
    opacity: 0.5;
    font-size: .75rem;
    color: ${theme2020.color.labelGrey};
    margin-bottom: .5rem;
  `,
};
