import { WORKFLOWS_BASE_URL } from '~/features/workflows/constants';

/** Should be 1:1 with the non-api routes in routes.rb */
const Urls = {
  eventOverview: (eventId, pathAssignmentId = null, sessionId = null) => {
    const url = `/events/${eventId}`;
    if (pathAssignmentId) return `${url}?path_assignment_id=${pathAssignmentId}`;
    if (sessionId) return `${url}?session_id=${sessionId}`;
    return url;
  },

  register: '/register',

  learnerHome: '/guides',

  guidePreview: (id, universityId, universityFolderId) =>
    `/guides/${id}/preview${uId(universityId, universityFolderId)}`,
  guidePreviewInPath: (id, pathId) => `/guides/${id}/preview?series_id=${pathId}`,
  guideStart: (id, universityId, universityFolderId) => `/guides/${id}/start${uId(universityId, universityFolderId)}`,
  guideContinueAssignment: (guideAssignmentId, universityId, universityFolderId) =>
    `/guide_assignments/${guideAssignmentId}${uId(universityId, universityFolderId)}`,
  guideContinueAssignmentInPath: (guideAssignmentId, pathAssignmentId) =>
    `/guide_assignments/${guideAssignmentId}/path_assignment_id/${pathAssignmentId}`,

  pathPreview: (id, universityId, universityFolderId) => `/paths/${id}/preview${uId(universityId, universityFolderId)}`,
  pathStart: (id, universityId, universityFolderId) => `/paths/${id}/start${uId(universityId, universityFolderId)}`,
  pathContinueAssignment: (pathAssignmentId, universityId, universityFolderId) =>
    `/series_assignments/${pathAssignmentId}${uId(universityId, universityFolderId)}`,

  resource: (id, universityId, uResourceId) => `/resources/${id}${uId(universityId)}&uresource_id=${uResourceId}`,
  resourcePreview: (id, universityId) => `/resources/${id}/preview${uId(universityId)}`,
  resourcePreviewInPath: (id, pathId) => `/resources/${id}/preview?series_id=${pathId}`,
  resourceContinueAssignmentInPath: (resourceId, assignmentId) =>
    `/resources/${resourceId}?resource_assignment_id=${assignmentId}`,

  trainingSeriesUserAssignments: (id) => `/training_series/${id}/user_assignments`,

  challengePreview: (id, universityId, universityFolderId) =>
    `/challenges/${id}/preview${uId(universityId, universityFolderId)}`,
  challengePreviewInPath: (id, pathId) => `/challenges/${id}/preview?series_id=${pathId}`,
  challengeStart: (id) => `/challenges/${id}/start_from_share_link`,
  challengeNewOrGoToFromUniversity: (universityId, challengeId) =>
    `/universities/${universityId}/challenge/${challengeId}/assignment`,
  challengeContinueAssignment: (challengeAssignmentId, universityId, universityFolderId) =>
    `/challenge_assignments/${challengeAssignmentId}${uId(universityId, universityFolderId)}`,

  topLevelContentPreview: (tlcType, tlcId, universityId, universityFolderId) =>
    `/${tlcType}/${tlcId}/preview${uId(universityId, universityFolderId)}`,
  topLevelContentPreviewInPath: (tlcType, tlcId, pathId) => `/${tlcType}/${tlcId}/preview?series_id=${pathId}`,
  topLevelContentStartOrContinueAssignment: (tlcType, tlcId, universityId, universityFolderId) =>
    `/${tlcType}/${tlcId}${uId(universityId, universityFolderId)}`,
  topLevelContentContinueAssignmentInPath: (tlcType, tlcId, pathAssignmentId) =>
    `/${tlcType}/${tlcId}?training_series_user_assignment_id=${pathAssignmentId}`,

  university: (id, uFolderId) => `/universities/${id}${uFolderId ? `?university_folder_id=${uFolderId}` : ''}`,

  academyGuide: (baseExternalUrl, id) => `${baseExternalUrl}/training/${id}/overview`,
  academyResource: (baseExternalUrl, id) => `${baseExternalUrl}/training/${id}/register`,
  academyTopLevelContent: (baseExternalUrl, id) => `${baseExternalUrl}/training/${id}/register`,

  admin: {
    path: (id) => `/admin/paths/${id}`,
    pathArchived: (id) => `/admin/paths/${id}/archived`,
    pathEdit: (id) => `/admin/paths/${id}/edit`,
    pathPreview: (id) => `/admin/paths/${id}/preview`,

    guideEdit: (id) => `/admin/guides/${id}/edit`,
    guidePreview: (id) => `/admin/guides/${id}/preview`,
    guidePreviewInPath: (id, pathId) => `/admin/guides/${id}/preview?series_id=${pathId}`,
    guideTaskPreview: (id) => `/admin/tasks/${id}/preview`,

    challenge: (id) => `admin/challenges/${id}`,
    challengeEdit: (id) => `/admin/challenges/${id}/edit`,
    challengePreview: (id) => `/admin/challenges/${id}/preview`,
    challengePreviewInPath: (id, pathId) => `/admin/challenges/${id}/preview?series_id=${pathId}`,

    resourceEdit: (id) => `/admin/resources/${id}`,
    resourcePreview: (id) => `/admin/resources/${id}/preview`,
    resourcePreviewInPath: (id, pathId) => `/admin/resources/${id}/preview?series_id=${pathId}`,

    taskPreview: (id, seriesId) => `/admin/tasks/${id}/preview?series_id=${seriesId}`,
    topLevelContent: (id, contentType) => `/admin/content/${contentType}/${id}`,
    topLevelContentPreview: (id, contentType) => `/admin/content/${contentType}/${id}/edit`, // preview same page as edit for TLC
    university: (id, uFolderId) => `/admin/universities/${id}${uFolderId ? `?university_folder_id=${uFolderId}` : ''}`,

    managerUserProfile: (id) => `/admin/manager/user/${id}`,
    managerDashboard: () => `/admin/manager/users`,
    reviewExternalAssignment: (academyId, contentId, contentType, assignmentId) => {
      let urlContentType;

      switch (contentType) {
        case AssetPickerTypes.GUIDE:
          urlContentType = 'guides';
          break;
        case AssetPickerTypes.RESOURCE:
          urlContentType = 'resources';
          break;
        default:
          return '';
      }

      return `/admin/academies/${academyId}/assignments/${assignmentId}/${urlContentType}/${contentId}`;
    },
    academyIndividualTrainingEnrollments: (academyId, training_type, training_id) =>
      `/admin/academies/${academyId}/trainings/${training_type}/${training_id}`,
    academyIndividualCertificationEnrollments: (academyId, academyCertificationId) =>
      `/admin/academies/${academyId}/certification_enrollments/${academyCertificationId}`,
    academyIndividualPathEnrollments: (academyId, academyPathId) =>
      `/admin/academies/${academyId}/path_enrollments/${academyPathId}`,
    workflow: (workflowId) => `${WORKFLOWS_BASE_URL}/${workflowId}`,
  },
};

const uId = (universityId, universityFolderId) => {
  const params = universityId ? `?university_id=${universityId}` : '';
  return params && universityFolderId ? params.concat(`&university_folder_id=${universityFolderId}`) : params;
};

export default Urls;
