import Fetch from './fetch';

const ObjectTagsApi = {
  getForObject: (objectId, objectType, seriesId) =>
    Fetch.get(`object_tags/${objectId}/${objectType}${seriesId ? `?series_id=${seriesId}` : ''}`),
  getExcludedSalesforce: () => Fetch.get('object_tags/search?reject_from_salesforce=true'),
  add: (tag_params) => Fetch.post('object_tags/', tag_params),
  delete: (tag_name) => Fetch.delete('object_tags/admin_bulk_delete', { name: tag_name }),
  editTags: (namesOfTagsToUpdate, tagData) =>
    Fetch.put('object_tags', { names_of_tags_to_update: namesOfTagsToUpdate, tag_data: tagData }),
};
export default ObjectTagsApi;
