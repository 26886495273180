export default function PortalsPageIcon({ className, width, height, fill }) {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 18 18">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ic_library_books_black_24px" transform="translate(-2.000000, -2.000000)">
          <polygon id="Shape" points="0 0 22 0 22 22 0 22" />
          <path
            d="M17.75,2 L4.25,2 C3.0125,2 2,3.0125 2,4.25 L2,17.75 C2,18.9875 3.0125,20 4.25,20 L17.75,20 C18.9875,20 20,18.9875 20,17.75 L20,4.25 C20,3.0125 18.9875,2 17.75,2 Z M16.625,11 L5.375,11 L5.375,9.375 L16.625,9.375 L16.625,11 Z M12.125,15 L5.375,15 L5.375,13.375 L12.125,13.375 L12.125,15 Z M16.625,7 L5.375,7 L5.375,5.375 L16.625,5.375 L16.625,7 Z"
            id="Shape"
            fill={fill}
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
}
PortalsPageIcon.COLORS = {
  PortalsPage: '#00ACC1',
  UniversitiesResource: '#9E9E9E',
};
PortalsPageIcon.defaultProps = {
  width: '17px',
  height: '17px',
  fill: PortalsPageIcon.COLORS.PortalsPage,
};
