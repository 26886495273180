import { Component } from 'react';
import styled from 'styled-components';
import { media, common, theme } from '../../styles';
import TimeUtils from '../../common/time-utils';
import ObjectTagsIcon from '../object-tags/object-tags-icon';

const TRANSLATED_START_BUTTON_LABEL = (pathName) =>
  window.I18n.t('js.learner.paths.modal_start_button_label', { pathName });

export default class PathHeader extends Component {
  render() {
    const timeEstimate = this.props.timeEstimateSeconds ? (
      <TimeEstimate>
        <img src="/images/time_estimate_icon_white.svg" width="14px" height="14px" />
        {TimeUtils.displayDurationLong(this.props.timeEstimateSeconds)}
      </TimeEstimate>
    ) : null;

    return (
      <Wrapper bg={this.props.bg}>
        <GradientOverlay />
        <ContentOverlay>
          <Name role="heading" aria-level="2">
            {this.props.name}
          </Name>
          <PathDetailsWrapper>
            <StyledObjectTagsIcon
              objectId={this.props.pathId}
              objectType={AssetPickerTypes.SERIES}
              className="object-tags-icon"
              tagIconSrc="/images/tag_solid_white.svg"
              viewOnly
            />
            {timeEstimate}
          </PathDetailsWrapper>
          {this.props.continueUrl && this.renderStartButton()}
        </ContentOverlay>
      </Wrapper>
    );
  }

  renderStartButton = () => {
    if (isFeatureEnabled('manager_view') && this.props.isReviewMode && this.props.isMentorOnly) {
      return null;
    }

    return (
      <ButtonWrapper>
        <Button
          role="link"
          tabIndex={0}
          hideStartButton
          onClick={() => window.open(this.props.continueUrl, this.props.openLinkInNewTab ? '_blank' : '_self')}
          aria-label={TRANSLATED_START_BUTTON_LABEL(this.props.name)}
        >
          {this.props.continueText}
        </Button>
      </ButtonWrapper>
    );
  };
}

const Wrapper = styled.div`
  position: relative;
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  height: 220px;
`;

const GradientOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
`;

const ContentOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const Name = styled.div`
  color: white;
  font-size: 2.2rem;
  text-align: center;
  margin-top: 50px;
  padding: 0 2.5rem;
  ${theme.font.bold}
  ${common.hideOverflow}
`;

const TimeEstimate = styled.div`
  color: white;
  font-size: 0.85rem;
  ${theme.font.semibold}
  text-align: center;
  margin-top: 5px;
  display: flex;
  justify-content: center;

  img {
    margin-right: 4px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 100%;
`;

const Button = styled.button`
  background-color: white;
  display: block;
  ${theme.font.bold}
  color: ${theme.color.textPrimary};
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 2px 36px 0;
  line-height: 3rem;
  border-radius: 50px;
  border: none;
  outline: none;

  ${media.mobile} {
    line-height: 2rem;
    padding-left: 18px;
    padding-right: 18px;
  }

  &:focus-visible,
  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 3px;
  }
`;

const PathDetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const StyledObjectTagsIcon = styled(ObjectTagsIcon)`
  font-size: 0.85rem;
  color: #ffffff;
  img {
    height: 14px;
    width: 14px;
  }
`;
