import type { ReactNode } from 'react';
import { StrictMode, useMemo } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { createGlobalStyle } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import { OverlayProvider, ThemeProvider, ToastProvider, DEFAULT_THEME, DialogManagerProvider } from '@workramp/ui';
import { queryClient } from '~/lib/react-query';
import { Z_INDEX_LAYERS } from '~/constants';
import { ActionCableConsumerProvider } from '~/providers';

export interface AppProviderProps {
  children: ReactNode;
}

const GlobalStyle = createGlobalStyle`
  #layer-application {
    height: 100%;
    position: relative;
    z-index: ${Z_INDEX_LAYERS.base};
  }
  #layer-modals {
    position: relative;
    z-index: ${Z_INDEX_LAYERS.modals};
  }
  #layer-popovers {
    position: relative;
    z-index: ${Z_INDEX_LAYERS.popovers};
  }
  #layer-toasts {
    position: relative;
    z-index: ${Z_INDEX_LAYERS.toasts};
  }
  #layer-tooltips {
    position: relative;
    z-index: ${Z_INDEX_LAYERS.tooltips};
  }
`;

export function AppProvider({ children }: AppProviderProps) {
  const themeWithOverlayContainers = useMemo(
    () => ({
      ...DEFAULT_THEME,
      overlays: {
        modalContainer: document.getElementById('layer-modals') ?? document.body,
        popoverContainer: document.getElementById('layer-popovers') ?? document.body,
        toastContainer: document.getElementById('layer-toasts') ?? document.body,
        tooltipContainer: document.getElementById('layer-tooltips') ?? document.body,
      },
    }),
    [],
  );

  return (
    <StrictMode>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={themeWithOverlayContainers}>
          <ActionCableConsumerProvider>
            <HelmetProvider>
              <ToastProvider>
                <OverlayProvider style={{ height: '100%' }}>
                  <DialogManagerProvider>{children}</DialogManagerProvider>
                </OverlayProvider>
              </ToastProvider>
            </HelmetProvider>
          </ActionCableConsumerProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </StrictMode>
  );
}
