import _ from 'underscore';
import { apiPut, apiDelete, handleApiError } from '../common';

/* Shared code for recommending */
window.KnowledgeAssets = {
  isFolder(asset) {
    return asset.type === 'folder' || !!asset.knowledgePortalId;
  },

  getType(asset) {
    return this.isFolder(asset) ? 'folder' : 'page';
  },

  recommend(asset, successCallback) {
    const data = { is_recommended: !asset.isRecommended };
    this.update(asset, data, successCallback);
  },

  rename(asset, newName, successCallback) {
    const renameEndpoint = this.isFolder(asset) ? `folders/${asset.id}` : `pages/${asset.id}`;
    const data = { name: newName };

    apiPut(
      renameEndpoint,
      data,
      (result) => {
        successCallback();
      },
      handleApiError(),
      '/knowledge/',
    );
  },

  editDescription(asset, description, successCallback) {
    const data = { description };
    this.update(asset, data, successCallback);
  },

  move(asset, newParentId, successCallback, errorCallback) {
    const moveEndpoint = this.isFolder(asset) ? `folders/${asset.id}/move` : `pages/${asset.id}`;
    const data = { parent_id: newParentId };
    apiPut(
      moveEndpoint,
      data,
      (result) => {
        successCallback();
      },
      (xhr) => {
        if (xhr.responseJSON && xhr.responseJSON.type === 'duplicate_name') {
          errorCallback(xhr.responseJSON.message);
        } else {
          handleApiError()(xhr);
        }
      },
      '/knowledge/',
    );
  },

  copy(asset, newParentId, successCallback) {
    if (this.isFolder(asset)) {
      return 'Folder copy not implemented.';
    }
    const data = { parent_id: newParentId };
    apiPut(
      `pages/${asset.id}/copy`,
      data,
      (result) => {
        successCallback();
      },
      handleApiError(),
      '/knowledge/',
    );
  },

  trash(asset, successCallback) {
    const isFolder = this.isFolder(asset);
    const url = isFolder ? `folders/${asset.id}` : `pages/${asset.id}`;

    apiDelete(
      url,
      {},
      (result) => {
        successCallback();
      },
      handleApiError,
      '/knowledge/',
    );
  },

  update(asset, data, successCallback) {
    const url = this.isFolder(asset) ? `folders/${asset.id}` : `pages/${asset.id}`;

    apiPut(
      url,
      data,
      (result) => {
        if (typeof successCallback === 'function') successCallback();
      },
      handleApiError(),
      '/knowledge/',
    );
  },

  logViewTime(asset, time, successCallback) {
    if (this.isFolder(asset)) {
      console.log('Not implemented for folders');
      return;
    }
    if (!asset.currentView) {
      console.log('No current view to record to.');
      return;
    }

    apiPut(
      `/knowledge/page_views/${asset.currentView.id}/log_time`,
      { read_time: time },
      (result) => {
        if (typeof successCallback === 'function') successCallback();
      },
      handleApiError(),
    );
  },

  restore(asset, successCallback) {
    const url = this.isFolder(asset) ? `folders/${asset.id}/restore` : `pages/${asset.id}/restore`;

    apiPut(
      url,
      {},
      (result) => {
        successCallback();
      },
      handleApiError(),
      '/knowledge/',
    );
  },

  getLastEdit(asset) {
    const { edits } = asset;
    if (edits) {
      const lastEdit = _.sortBy(edits, (edit) => edit.createdAt).pop();
      return lastEdit;
    }
    return undefined;
  },

  getParentUrl(asset) {
    if (asset.isHomeFolder) {
      return `/knowledge/portals/${asset.knowledgePortalId}/`;
    }
    return `/knowledge/folders/${asset.parentId}/`;
  },

  sortAssets(assets, sortBy) {
    const me = this;

    switch (sortBy) {
      case 'date':
        return _.sortBy(assets, (child) => {
          const lastEdit = me.getLastEdit(child);
          const updateTime = lastEdit ? lastEdit.createdAt : child.updatedAt;
          return -1 * updateTime;
        });
      case 'views':
        return _.sortBy(assets, (child) => -child.numViews);
      default:
        return _.sortBy(assets, (child) => (child.name ? child.name.toLowerCase() : undefined));
    }
  },
};
