import _ from 'underscore';
import { I18n } from '~/common/explicit-exports';
import ChallengeStatus from '../challenges/challenge-status';
import Urls from '../urls';
import EventAttendeeRegistrationStatus from '../event-attendee-registration-status';
import { sumArray, URIUtils } from '../common';
import AssignmentUtils from './assignment-utils';

export default class PathUtils {
  static getOrderedAssets(path) {
    const assets = path.trainingSeriesGuides
      .concat(path.trainingSeriesResources)
      .concat(path.trainingSeriesChallenges)
      .concat(path.trainingSeriesTopLevelContents || [])
      .concat(path.trainingSeriesTrainingClasses || []);

    const withoutDeleted = _.filter(assets, (asset) => !asset.asset.isDeleted);
    return _.sortBy(withoutDeleted, (asset) => asset.order);
  }

  static orderedAssetTitles(path) {
    const assets = this.getOrderedAssets(path);
    return _.map(assets, (asset) => asset.asset.displayTitle);
  }

  /**
   * Generate an object with some data to display for each module. This will be different based on whether this is an
   * assignment or a preview.
   */
  static getAssetsDisplayInfo(
    path,
    isReviewMode,
    isEndUserPreview,
    pathAssignment,
    universityId,
    sessionAttendeesByEventId,
  ) {
    const assetsDisplayInfo = {};
    let isLocked = false;

    _.each(PathUtils.getOrderedAssets(path), (asset) => {
      const info = PathUtils.getAssetDisplayInfo(
        asset,
        isReviewMode,
        isEndUserPreview,
        path,
        pathAssignment,
        isLocked,
        universityId,
        sessionAttendeesByEventId,
      );

      // If sequential unlock is on and this guide isn't completed, set all future guides to locked.
      if (path.sequentialUnlock && !info.isCompleted) {
        isLocked = true;
      }

      assetsDisplayInfo[asset.asset.id] = info;
    });

    return assetsDisplayInfo;
  }

  static getAssetDisplayInfo(
    asset,
    isAdminReview,
    isEndUserPreview,
    path,
    pathAssignment,
    isLocked,
    universityId,
    sessionAttendeesByEventId,
  ) {
    let info = {};

    if (asset.asset.type == AssetPickerTypes.GUIDE) {
      info = this.getGuideDisplayInfo(asset.asset, isAdminReview, isEndUserPreview, path, pathAssignment);
    } else if (asset.asset.type == AssetPickerTypes.RESOURCE) {
      info = this.getResourceDisplayInfo(asset.asset, isAdminReview, isEndUserPreview, path, pathAssignment);
    } else if (asset.asset.type == AssetPickerTypes.CHALLENGE) {
      info = this.getChallengeDisplayInfo(asset.asset, isAdminReview, isEndUserPreview, path, pathAssignment);
    } else if (_.contains(APP_CONSTANTS.GENERIC_TOP_LEVEL_CONTENT_TYPES, asset.asset.type)) {
      info = this.getTLCDisplayInfo(asset.asset, isAdminReview, isEndUserPreview, path, pathAssignment);
    } else if (asset.asset.type == AssetPickerTypes.CLASS) {
      const eventSessionAttendees = sessionAttendeesByEventId ? sessionAttendeesByEventId[asset.asset.id] : [];
      info = this.getTrainingClassDisplayInfo(
        asset.asset,
        isAdminReview,
        isEndUserPreview,
        pathAssignment,
        eventSessionAttendees,
        asset.requireAttendanceForCompletion,
      );
    }

    info.isLocked = isLocked;
    info.type = asset.asset.type;
    if (universityId) {
      info.url = URIUtils.addParamToUrl(info.url, 'university_id', universityId);
    }

    return info;
  }

  static getGuideDisplayInfo(guide, isAdminReview, isEndUserPreview, path, pathAssignment) {
    let info = {};

    if (pathAssignment) {
      const guideAssignment = PathUtils.findGuideUserAssignment(pathAssignment, guide.id);
      let url = isAdminReview
        ? `/admin/guide_assignments/${guideAssignment.id}/review`
        : `/guide_assignments/${guideAssignment.id}`;

      url += `/path_assignment_id/${pathAssignment.id}`;

      let isCompleted;
      if (path.sequentialUnlock && path.passingScoreRequiredForUnlock) {
        isCompleted = guideAssignment.isCompletedAndPassed;
      } else {
        isCompleted = guideAssignment.isCompleted || guideAssignment.areAllTaskAssignmentsComplete;
      }

      info = {
        url,
        isCompleted,
        percentComplete: AssignmentUtils.getPercentCompletedForGuideAssignment(guideAssignment, guide),
      };
    } else {
      const url = isEndUserPreview
        ? `/guides/${guide.id}/preview?series_id=${path.id}`
        : `/admin/guides/${guide.id}/preview?series_id=${path.id}`;
      info = {
        url,
        isCompleted: false,
        percentComplete: 0,
      };
    }

    return info;
  }

  static getResourceDisplayInfo(resource, isAdminReview, isEndUserPreview, path, pathAssignment) {
    let info = {};

    if (pathAssignment) {
      const resourceAssignment = PathUtils.findResourceUserAssignment(pathAssignment, resource.id);
      const url = isAdminReview
        ? `/admin/resources/${resourceAssignment.id}/review`
        : `/resources/${resource.id}?resource_assignment_id=${resourceAssignment.id}`;
      info = {
        url,
        isCompleted: resourceAssignment.isCompleted,
      };
    } else {
      const url = isEndUserPreview
        ? `/resources/${resource.id}/preview?series_id=${path.id}`
        : `/admin/resources/${resource.id}/preview?series_id=${path.id}`;
      info = {
        url,
        isCompleted: false,
      };
    }

    return info;
  }

  static getChallengeDisplayInfo(challenge, isAdminReview, isEndUserPreview, path, pathAssignment) {
    let info = {};

    if (pathAssignment) {
      const userAssignment = PathUtils.findChallengeUserAssignment(pathAssignment, challenge.id);
      const status = ChallengeStatus.getStatus(userAssignment);

      const url = isAdminReview
        ? `/admin/challenge_user_assignments/${userAssignment.id}/review`
        : `/challenge_assignments/${userAssignment.id}`;

      let statusText;
      if (status === ChallengeStatus.SUBMITTED || status === ChallengeStatus.UNDER_REVIEW) {
        statusText = 'Pending review';
      } else if (status !== ChallengeStatus.UNSUBMITTED) {
        statusText = status.getLabel();
      }
      info = {
        url,
        isCompleted: userAssignment.isSubmitted,
        status: statusText,
      };
    } else {
      const url = isEndUserPreview
        ? `/challenges/${challenge.id}/preview?series_id=${path.id}`
        : `/admin/challenges/${challenge.id}/preview?series_id=${path.id}`;
      info = {
        url,
        isCompleted: false,
      };
    }

    return info;
  }

  static getTLCDisplayInfo(tlc, isAdminReview, isEndUserPreview, path, pathAssignment) {
    let info = {};

    if (pathAssignment) {
      const assignment = PathUtils.findTLCAssignment(pathAssignment, tlc.id);
      // If this TLC content type isn't reviewable (such as SCORMs), set url to null, so
      // we'll know to render it as unclickable.
      const isReviewable = APP_CONSTANTS.TOP_LEVEL_CONTENT_NOT_REVIEWABLE.indexOf(tlc.contentType) === -1;
      const url = isAdminReview
        ? isReviewable && `/admin/top_level_contents/${assignment.id}/review`
        : `/${tlc.type}/${tlc.id}?training_series_user_assignment_id=${pathAssignment.id}`;
      info = {
        url,
        isCompleted: assignment.isCompleted,
        openLinkInNewTab: tlc.openLinkDirectlyInNewTab,
      };
    } else {
      const url = isEndUserPreview
        ? `/${tlc.type}/${tlc.id}/preview?series_id=${path.id}`
        : `/admin/content/${tlc.type}/${tlc.id}/preview?series_id=${path.id}`;
      info = {
        url,
        isCompleted: false,
        openLinkInNewTab: tlc.openLinkDirectlyInNewTab,
      };
    }

    return info;
  }

  static getTrainingClassDisplayInfo(
    trainingClass,
    isAdminReview,
    _isEndUserPreview,
    pathAssignment,
    eventSessionAttendees,
    requireAttendanceForCompletion,
  ) {
    let onModuleSelectUrl;
    let showRegisteredNotAttendedMsg;
    const isLearnerView = pathAssignment && !isAdminReview;

    const learnerHasCompletedEvent = _.some(eventSessionAttendees, (attendee) => {
      if (requireAttendanceForCompletion) {
        return attendee.registeredAndMarkedAttended;
      }
      return attendee.registeredAndSessionHasPassed;
    });

    const learnerHasRegisteredForEventSession = _.some(eventSessionAttendees, (attendee) =>
      new EventAttendeeRegistrationStatus(attendee.registrationStatus).isRegistered(),
    );

    showRegisteredNotAttendedMsg = learnerHasRegisteredForEventSession && !learnerHasCompletedEvent;

    if (isLearnerView) {
      onModuleSelectUrl = Urls.eventOverview(trainingClass.id, pathAssignment && pathAssignment.id);
    }

    return {
      url: onModuleSelectUrl,
      isCompleted: learnerHasCompletedEvent,
      showRegisteredNotAttendedMsg,
      useShortDescription: true,
    };
  }

  static findGuideUserAssignment(pathAssignment, guideId) {
    const ret = _.find(pathAssignment.courseUserAssignments, (assignment) => assignment.courseId === guideId);

    if (ret) {
      return ret;
    }
    console.error(`No assignment for guide ${guideId}`);
  }

  static findResourceUserAssignment(pathAssignment, resourceId) {
    const ret = _.find(pathAssignment.resourceUserAssignments, (assignment) => assignment.resourceId === resourceId);

    if (ret) {
      return ret;
    }
    console.error(`No assignment for resource ${resourceId}`);
  }

  static findChallengeUserAssignment(pathAssignment, challengeId) {
    const ret = _.find(pathAssignment.challengeUserAssignments, (assignment) => assignment.challengeId === challengeId);

    if (ret) {
      return ret;
    }
    console.error(`No assignment for challenge ${challengeId}`);
  }

  static findTLCAssignment(pathAssignment, tlcId) {
    // TODO: can change to topLevelContentId on next line?
    const ret = _.find(pathAssignment.assignments || [], (assignment) => {
      const assnTlcId = assignment.topLevelContentId ? assignment.topLevelContentId : assignment.topLevelContent.id;
      return assnTlcId === tlcId;
    });

    if (ret) {
      return ret;
    }
    console.error(`No assignment for top-level-content ${tlcId}`);
  }

  static getTimeEstimate(path) {
    const assets = PathUtils.getOrderedAssets(path);
    const allAssetTimeEstimates = _.map(assets, (asset) => asset.asset.timeEstimateSeconds);
    const validAssetTimeEstimates = _.filter(allAssetTimeEstimates, (second) => second);
    const totalTimeEstimateSeconds =
      validAssetTimeEstimates.length === assets.length ? sumArray(validAssetTimeEstimates) : null;
    return totalTimeEstimateSeconds;
  }

  static getRemovalWarningMessage() {
    const TRANSLATED_REMOVE_MODULE_MESSAGE = I18n.t('js.admin.paths.remove_module_msg');

    return TRANSLATED_REMOVE_MODULE_MESSAGE;
  }

  static getAddWarningMessage() {
    const TRANSLATED_ADD_MODULE_MESSAGE = I18n.t('js.admin.paths.add_module_msg');

    return TRANSLATED_ADD_MODULE_MESSAGE;
  }
}
