import type { NodeFormData } from '../types';

export const WORKFLOWS_BASE_URL = '/admin/workflows';
export const WORKFLOWS_API_ROOT = 'workflows';
export const WORKFLOWS_API_STEPS = 'steps';

export const UNTITLED_PLACEHOLDER = 'Untitled Automation';

export const WORKFLOW_ZINDEX = 1040;

export const PLACEHOLDER_NODE_ID = '__placeholder__';

export const STRING_BOOLEAN_TRUE = 'true';
export const STRING_BOOLEAN_FALSE = 'false';
export const DISABLED_OPERATOR = 'on or before today';

export const DEFAULT_FORM_VALUES: NodeFormData = {
  content: null,
  selectableNodeType: null,
  group: null,
  reviewers: null,
  inputs: null,
  retroactive: STRING_BOOLEAN_TRUE,
};
