window.KeyCodes = {
  ENTER: 13,
  ESC: 27,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
};

const getCharCode = (event) => event.which || event.charCode || event.keyCode;
export const isEnterKey = (event) => getCharCode(event) === KeyCodes.ENTER;
export const isEscKey = (event) => getCharCode(event) === KeyCodes.ESC;
