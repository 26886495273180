import { useLayoutEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useResize = (onResize, dependencies = []) => {
  useLayoutEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, dependencies);
};

export default useResize;
