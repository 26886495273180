import TimeAgo from '~/components/common/time-ago';
import WRDates from '~/wr-dates';

export function EditTime({ time }) {
  //  show timeago if edit was less than a day ago
  const UTCEditTime = new Date(time).getTime();
  if (Date.now() - UTCEditTime < 1000 * 60 * 60 * 24) {
    return <TimeAgo date={UTCEditTime} />;
  }
  return (
    <span className="redactor-versioning-version-datetime">
      {WRDates.getDayFormat(UTCEditTime)}{' '}
      <span className="redactor-versioning-version-time">{WRDates.getTimeFormat(UTCEditTime)}</span>
    </span>
  );
}
