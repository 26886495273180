import styled from 'styled-components';
import { WRDOMPurify } from '~/wr-dompurify';
import { theme, media, common } from '../../styles';

const PathAbout = (props) => (
  <Wrapper>
    <Label>{props.label}</Label>
    <Description dangerouslySetInnerHTML={{ __html: WRDOMPurify.sanitize(props.description) }} />
  </Wrapper>
);

const Wrapper = styled.div`
  background-color: ${theme.color.lightBackground};
  padding: 2.5rem;
  width: 35%;
  box-sizing: border-box;
  ${media.screenXsMax} {
    width: 100%;
  }
`;

const Label = styled.div`
  color: ${theme.color.textPrimary};
  ${theme.font.bold}
  font-size: 0.85rem;
  text-transform: uppercase;
  margin-bottom: 1.2rem;
`;

const Description = styled.div`
  color: ${theme.color.textDescription};
  font-size: 0.85rem;
  line-height: 1.3rem;
  ${common.breakWord}
  figure {
    margin: 0;
  }
  iframe {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16/9;
    height: auto !important;
  }
  img {
    max-width: 100%;
    height: auto;
  }
`;

export default PathAbout;
