import { useState } from 'react';
import styled from 'styled-components';
import ColorUtils from '../../common/color-utils';

/** @deprecated prefer `import { Button } from '@workramp/ui';` */
export default function Button({
  className,
  children,
  selected,
  disableBackgroundHoverChange,
  onClick,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  theme,
  leftIcon,
  rightIcon,
  xSmall,
  small,
  large,
  innerRef,
  tabIndex = 0,
}) {
  const [focused, setFocused] = useState(false);
  const themeToUse = themes[theme] || themes.default;

  const handleClick = () => {
    setFocused(true);
    onClick && onClick({ focused: true });
  };

  const handleBlur = () => {
    setFocused(false);
    onBlur && onBlur();
  };

  return (
    <DataVizButton
      className={className}
      disableBackgroundHoverChange={disableBackgroundHoverChange}
      ref={innerRef}
      theme={themeToUse}
      onClick={handleClick}
      onBlur={handleBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      white
      xSmall={xSmall}
      small={small}
      large={large}
      selected={selected}
      role="button"
      tabIndex={tabIndex}
      onKeyDown={(event) => {
        const { key } = event;
        if (key === 'Enter' || key === ' ') handleClick(event);
      }}
    >
      <DataVizButtonBody focused={focused} theme={themeToUse} selected={selected}>
        {leftIcon && leftIcon}
        {children}
        {rightIcon && rightIcon}
      </DataVizButtonBody>
    </DataVizButton>
  );
}

// Figma documentation: https://www.figma.com/file/gU0vTjwALEVmEkifdPJLbv/Reporting-Visualization-II?node-id=175%3A6661
const themes = {
  default: {
    color: '#253256',
    background: '#FFFFFF',
    borderColor: '#E6EAEF',
    backgroundHover: '#E6EAEF',
  },
  blue: {
    color: '#FFFFFF',
    background: '#0F48E6',
    borderColor: '#0F48E6',
    backgroundHover: ColorUtils.lightenDarkenColor('#0F48E6', 15),
  },
  ghost: {
    color: '#253256',
    background: 'none',
    borderColor: 'transparent',
    backgroundHover: 'none',
  },
  darkBlue: {
    color: '#FFFFFF',
    background: '#12192B',
    borderColor: '#12192B',
    backgroundHover: ColorUtils.lightenDarkenColor('#12192B', 15),
  },
  alert: {
    color: '#FFFFFF',
    background: '#ED3E31',
    borderColor: '#ED3E31',
    backgroundHover: ColorUtils.lightenDarkenColor('#ED3E31', 15),
  },
  gray: {
    color: '#12192B',
    background: '#F2F4F7',
    borderColor: '#F2F4F7',
    backgroundHover: ColorUtils.lightenDarkenColor('#F2F4F7', 15),
  },
};

const DataVizButtonBody = styled.div`
  display: flex;
  align-items: center;
  opacity: ${({ selected, focused }) => (selected || focused ? '0.9' : '0.8')};

  > * {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const DataVizButton = styled.button`
  display: inline-block;
  cursor: pointer;
  border-radius: 8px;

  color: ${({ theme }) => theme.color};
  background: ${({ theme, selected }) => (selected ? theme.backgroundHover : theme.background)};
  border: 1px solid ${({ theme }) => theme.borderColor};

  padding: ${({ xSmall, small, large }) => {
    if (xSmall) return '4.5px 10px';
    if (small) return '8px 12px';
    if (large) return '14px';
    return '12px';
  }};

  &:hover {
    ${({ theme, disableBackgroundHoverChange }) =>
      !disableBackgroundHoverChange &&
      `
        background: ${theme.backgroundHover};
    `}
    > * {
      opacity: 1;
    }
  }

  &:focus-visible {
    outline: 4px solid #074be233;
    outline-offset: 0;
  }
`;
