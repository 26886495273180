import { useTheme } from 'styled-components';
import { Icon } from '@workramp/ui';
import { iconFromAssetType, colorFromAssetType } from './util';
import AssetPickerTypes from './asset-picker-types';

/** @deprecated prefer import { ContentIcon } from '~/features/common'; */
const LearningObjectComplexIcon = ({ type }) => {
  const theme = useTheme();

  const lowercaseType = type.toLowerCase();
  const color = colorFromAssetType(lowercaseType);
  const iconColor = color ? theme.colors.decorative[color][500] : theme.colors.secondary[500];

  let iconName = `${iconFromAssetType(lowercaseType)}:duotone`;
  if (type === AssetPickerTypes.GO1_CONTENT) {
    iconName = 'search:regular';
  }

  return <Icon name={iconName} color={iconColor} size="lg" />;
};

export default LearningObjectComplexIcon;
