import styled from 'styled-components';
import { common, theme } from '../../styles';
import { WrTooltip } from '../../styled-components/common/wr-tooltip';
import { TimeEstimateWithIcon } from '../common/time-estimate-with-icon';
import LearningObjectComplexIcon from '../common/asset-picker/LearningObjectComplexIcon';
import PathModuleStatusCircle from './path-module-status-circle';

const PathContentRow = ({
  completed,
  description,
  isReviewMode,
  isMentorOnly,
  locked,
  name,
  percentComplete,
  showRegisteredNotAttendedMsg,
  status,
  timeEstimateSeconds,
  type,
  iconType,
  url,
  openLinkDirectlyInNewTab,
}) => {
  let rowIsClickable;
  const registeredNotAttendedMsg = I18n.t('js.learner.paths.registered_not_attended');
  if (!url || locked || (isFeatureEnabled('manager_view') && isReviewMode && isMentorOnly)) {
    rowIsClickable = false;
  } else {
    rowIsClickable = true;
  }

  return (
    <Wrapper
      onClick={
        rowIsClickable
          ? () => {
              window.open(url, openLinkDirectlyInNewTab ? '_blank' : '_self');
            }
          : null
      }
      clickable={rowIsClickable}
    >
      <Indicator>
        <PathModuleStatusCircle completed={completed} percentComplete={percentComplete} locked={locked} type={type} />
      </Indicator>
      <Line />
      <MainBlock
        role="link"
        completed={completed}
        tabIndex={0}
        onKeyDown={
          rowIsClickable
            ? ({ key }) => {
                if (key === 'Enter' || key === ' ') window.open(url, openLinkDirectlyInNewTab ? '_blank' : '_self');
              }
            : null
        }
      >
        <TypeBlock>
          <LearningObjectComplexIcon type={iconType} />
          <Name completed={completed} role="heading" aria-level="3">
            {name}
          </Name>
          <Status>{status}</Status>
          <TimeEstimateWithIcon timeEstimateSeconds={timeEstimateSeconds} />
        </TypeBlock>
        <WrTooltip content={description}>
          <Description>{description}</Description>
        </WrTooltip>
        {showRegisteredNotAttendedMsg && (
          <RegisteredNotAttendedWrapper>{registeredNotAttendedMsg}</RegisteredNotAttendedWrapper>
        )}
      </MainBlock>
    </Wrapper>
  );
};

const Line = styled.div`
  position: absolute;
  width: 2px;
  background-color: #e5ebf1;
  top: 27px;
  bottom: -27px;
  left: -41px;
`;

const MainBlock = styled.div`
  // opacity: ${({ completed }) => (completed ? 0.6 : 1.0)};
`;

const Wrapper = styled.div`
  position: relative;
  margin-left: 36px;
  padding: 1.3rem 5px;

  ${({ clickable }) =>
    clickable
      ? `
    cursor: pointer;

    &:hover {
      background-color: #F9FAFB;
    }
  `
      : ''}

  &:not(:last-child) {
    border-bottom: 1px solid #e7edf4;
  }

  &:last-child {
    ${Line} {
      visibility: hidden;
    }
  }
`;

const Indicator = styled.div`
  position: absolute;
  left: -50px;
  z-index: 2;
`;

const TypeBlock = styled.div`
  ${common.flex}
  margin-bottom: 4px;
`;

const Status = styled.div`
  margin-left: 1rem;
  font-size: 0.7rem;
  ${theme.font.semibold}
  color: ${theme.color.textDescription};
`;

const Name = styled.div`
  ${({ completed }) => !completed && theme.font.semibold}
  color: ${({ completed }) => (completed ? theme.color.textDescription : theme.color.textPrimary)};
  text-decoration: ${({ completed }) => (completed ? 'line-through' : 'none')};
  font-size: 1rem;
  ${common.hideOverflow}
  padding-left: 8px;
`;

const Description = styled.div`
  color: ${theme.color.textDescription};
  ${common.hideOverflow}
  font-size: 0.85rem;
  line-height: 1.3rem;
  margin-left: 14px;
`;

const RegisteredNotAttendedWrapper = styled(Description)`
  font-style: italic;
`;

export default PathContentRow;
