import _ from 'underscore';
import { Component } from 'react';
import { isValidEmailAddress } from '../../common';

class MultiInputField extends Component {
  state = {
    results: null,
    showInput: false,
  };

  componentDidMount() {
    this.addHandlers();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.showInput && this.state.showInput) {
      this.addHandlers();
      if (this.refs.input) {
        $(this.refs.input).focus();
      }
    }

    if (this.props.autoCompleteUrl !== prevProps.autoCompleteUrl) this.addHandlers();
  }

  render() {
    const me = this;
    const tagNodes = _.map(this.props.tags, (tag) => (
      <div className="input-pill hide-overflow" key={tag}>
        <span className="mrm">{tag}</span>
        <img
          src="/images/cross_icon_white.svg"
          data-selected-id={tag}
          className="remove-pill-btn cursor-pointer"
          onClick={me.removeTag}
        />
      </div>
    ));

    const multiInput = (
      <div style={{ width: '100%' }}>
        <div className="form-input-text flex" style={{ flexWrap: 'wrap' }}>
          {tagNodes}
          {this.renderLinkOrInput()}
        </div>
        {this.props.children}
      </div>
    );

    if (this.props.inputOnly) return multiInput;

    return (
      <div className="form-field">
        <div className="form-label" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{this.props.name}</div>
          <div style={{ cursor: 'pointer', color: '#888888' }} onClick={this.props.extraAction}>
            {this.props.extraActionText}
          </div>
        </div>
        {!this.props.disabled && multiInput}
      </div>
    );
  }

  renderLinkOrInput = () => {
    if (this.props.addLinkMsg && !this.state.showInput) {
      return (
        <a href="#" onClick={this.showInput}>
          {this.props.addLinkMsg}
        </a>
      );
    }
    const onFocus = this.props.onFocus || function () {};
    return (
      <input
        className="flex-1"
        autoComplete="nope"
        onChange={this.onChange}
        onFocus={onFocus}
        onKeyUp={this.onKeyUp}
        onPaste={this.onPaste}
        placeholder={this.props.placeholder}
        ref="input"
        style={{ border: 'none', outline: 'none', padding: '0' }}
        type="text"
        value={this.props.input || ''}
      />
    );
  };

  showInput = (e) => {
    e.preventDefault();
    this.setState({
      showInput: true,
    });
  };

  removeTag = (e) => {
    const tagToRemove = $(e.target).attr('data-selected-id');
    this.props.onChange(null, null, tagToRemove, this.props.type);
  };

  addHandlers = () => {
    if (this.refs.input) {
      const input = $(this.refs.input);
      if (this.props.autoCompleteUrl) {
        input.autocomplete({
          source: this.props.autoCompleteUrl,
          minLength: 2,
          select: (e, ui) => {
            this.props.onChange(ui.item, null, null, this.props.type);
            this.setState({ showInput: false });
            return false;
          },
          response: (e, ui) => {
            if (this.props.tagsForSuggestionFiltration) {
              let currentItem;
              for (let i = 0; i < ui.content.length; i++) {
                currentItem = ui.content[i];
                if (this.props.tagsForSuggestionFiltration.includes(currentItem.label)) ui.content.splice(i, 1);
              }
            }
            this.setState({
              results: ui.content,
            });
          },
        });

        // allow components to override the rendering function for each autocomplete item
        if (this.props.renderItemFunction) {
          input.data('ui-autocomplete')._renderItem = this.props.renderItemFunction;
        }
      }
    }
  };

  onKeyUp = (e) => {
    const me = this;
    const val = $(e.target).val().trim();
    let result;
    if (val) {
      if (e.keyCode == 13) {
        if (this.props.autoCompleteUrl && this.state.results !== null && this.state.results.length > 0) {
          if (this.state.results.length == 1) {
            result = this.state.results[0];
            me.setState({
              results: null,
            });
            me.props.onChange(result, null, null, me.props.type);
            me.setState({ showInput: false });
          } else {
            const foundExactMatch = _.find(this.state.results, (result) => result.value.toLowerCase() == val);
            if (foundExactMatch) {
              this.props.onChange(foundExactMatch, null, null, me.props.type);
              me.setState({ showInput: false });
            } else if (this.props.forceSelectFromSuggestions) {
              result = this.state.results[0];
              me.setState({
                results: null,
              });
              me.props.onChange(result, null, null, me.props.type);
              me.setState({ showInput: false });
            } else {
              this.props.onChange(foundExactMatch, val, null, me.props.type);
              me.setState({ showInput: false });
            }
          }
          $(this.refs.input).autocomplete('close');
        } else {
          if (this.props.forceSelectFromSuggestions) {
            const tagItemName = this.props.tagItemName || 'tag';
            alert(`Please select an existing ${tagItemName}.`);
            return;
          }

          let valid = false;

          if (!this.props.checkForEmails || isValidEmailAddress(val)) {
            valid = true;
          } else {
            alert('Please enter in a valid email address');
          }

          if (!this.props.noCommasAllowed || val.indexOf(',') === -1) {
            valid = valid && true;
          } else {
            valid = false;
            alert('Entry cannot contain commas.');
          }

          if (valid) {
            this.props.onChange(null, val, null, me.props.type);
            me.setState({ showInput: false });
          }
        }
      } else if (e.keyCode == 32 && this.props.useSpaceDelimiter) {
        if (!this.props.checkForEmails || isValidEmailAddress(val)) {
          this.props.onChange(null, val, null, me.props.type);
          me.setState({ showInput: false });
        }
      }
    }
  };

  processSpaceDelimitedTags = (tags) => {
    const me = this;
    _.each(tags, (tag) => {
      if (!me.props.checkForEmails || isValidEmailAddress(tag.trim())) {
        me.props.onChange(null, tag.trim(), null, me.props.type);
        me.setState({ showInput: false });
      }
    });
  };

  onPaste = (e) => {
    const me = this;
    const node = $(e.target);
    setTimeout(() => {
      const val = node.val().trim();
      if (me.props.pasteProcessor) {
        tags = me.props.pasteProcessor(val);
        me.processSpaceDelimitedTags(tags);
      } else if (me.props.useSpaceDelimiter) {
        var tags = val.split(' ');
        me.processSpaceDelimitedTags(tags);
      } else {
        // Fix for pasting in a space-delimited group of emails when useSpaceDelimiter is false
        // We split the pasted string by spaces and check if the first value is a valid email address.
        // If it is a valid email address, we assume the whole list is valid email addresses and go from there.
        const spaceDelimitedVals = val.split(' ');
        if (spaceDelimitedVals.length > 0 && isValidEmailAddress(spaceDelimitedVals[0])) {
          me.processSpaceDelimitedTags(spaceDelimitedVals);
        } else if (!me.props.checkForEmails || isValidEmailAddress(val)) {
          me.props.onChange(null, val, null, me.props.type);
          me.setState({ showInput: false });
        }
      }
    }, 100);
  };

  onChange = (e) => {
    const val = $(e.target).val();
    this.props.onInput(val, this.props.type);
  };
}

window.MultiInputField = MultiInputField;

export default MultiInputField;
