import _ from 'underscore';
import { useEffect, useState } from 'react';
import Tooltip from 'react-tooltip-lite';

// https://github.com/bsidelinger912/react-tooltip-lite

/** @deprecated prefer `import { Tooltip } from '@workramp-ui';` */
export const WrTooltip = ({
  arrow = true,
  children,
  className = 'react-tooltip-lite-default-style',
  content = '',
  direction = 'down',
  inline = false,
  rounded = false,
  styles = {},
  tagName = 'div',
  tipContentHover = false,
  useDefaultStyles = true,
  zIndex = 1000,
  ...props
}) => {
  const [statefulContent, setStatefulContent] = useState(content);

  useEffect(() => {
    setStatefulContent(content);
  }, [content]);

  if (_.isEmpty(statefulContent)) {
    return children;
  }

  if (inline) {
    className += ' react-tooltip-lite-inline';
  }
  if (rounded) {
    className += ' react-tooltip-lite-rounded';
  }

  return (
    <Tooltip
      {...props}
      arrow={arrow}
      className={className}
      content={statefulContent}
      direction={direction}
      styles={styles}
      tagName={tagName}
      tipContentHover={tipContentHover}
      useDefaultStyles={useDefaultStyles}
      zIndex={zIndex}
    >
      {children}
    </Tooltip>
  );
};
