import { useTheme } from 'styled-components';
import { Icon } from '@workramp/ui';
import AssetPickerTypes from './asset-picker-types';
import { iconFromAssetType, colorFromAssetType } from './util';

/** @deprecated prefer import { ContentIcon } from '~/features/common'; */
const LearningObjectIcon = ({ type, height, width, style, iconClassName }) => {
  const theme = useTheme();

  const lowercaseType = type.toLowerCase();
  const useNonSvgIcon = AssetPickerTypes.specialCaseIcons.includes(lowercaseType);

  const color = colorFromAssetType(lowercaseType);
  const iconColor = color ? theme.colors.decorative[color][500] : theme.colors.secondary[500];

  const createIcon = () => {
    const iconComponent = <Icon name={`${iconFromAssetType(lowercaseType)}:regular`} color={iconColor} />;

    if (iconClassName) {
      return <span className={iconClassName}>{iconComponent}</span>;
    }
    return iconComponent;
  };

  return (
    <>
      {useNonSvgIcon && (
        <img
          src={AssetPickerTypes.getIconSrc(lowercaseType)}
          width={width}
          height={height}
          style={style}
          alt={type}
          className={iconClassName}
        />
      )}
      {!useNonSvgIcon && createIcon()}
    </>
  );
};

export default LearningObjectIcon;
