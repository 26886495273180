import type { HTMLAttributes, ReactNode } from 'react';
import { Text } from './IconList.styles';

export interface IconListTextProps extends HTMLAttributes<HTMLParagraphElement> {
  isInteractive?: boolean;
  children: ReactNode;
}

export function IconListText({ children, isInteractive, ...props }: IconListTextProps) {
  return (
    <Text size={0} $isInteractive={isInteractive} {...props}>
      {children}
    </Text>
  );
}
