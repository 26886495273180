import styled from 'styled-components';
import { common, theme } from '../../../styles';
import CompletedIcon from '../../icons/completed-icon';

const Wrapper = styled.div`
  width: 100%;
  ${theme.font.semibold}
  font-size: 0.7rem;
  color: #7f8ba6;

  ${(props) => (props.flex ? common.flex : '')}
  ${(props) => (props.center ? 'justify-content: center;' : '')}
`;

const ProgressText = styled.div`
  color: ${(props) => (props.color ? props.color : '#434853')};
  box-sizing: border-box;
`;

const CompletedIconWrapper = styled.span`
  margin-right: 6px;
`;

const NormalText = styled.span`
  color: ${(props) => (props.color ? props.color : '')};
`;

export const FooterProgress = (props) => (
  <Wrapper flex center>
    <ProgressText color={props.color}>{props.text}</ProgressText>
  </Wrapper>
);

export const FooterCompleted = (props) => (
  <Wrapper flex center>
    <CompletedIconWrapper>
      <CompletedIcon fill={props.color} width="18px" height="16px" />
    </CompletedIconWrapper>
    <NormalText color={props.color}>{props.text}</NormalText>
  </Wrapper>
);

export const FooterStart = (props) => (
  <Wrapper flex center>
    <NormalText color={props.color}>{props.text}</NormalText>
  </Wrapper>
);
