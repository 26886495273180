import type { IconNames } from '@workramp/ui';
import { Icon, Span } from '@workramp/ui';

export interface IconListIconProps {
  name: IconNames;
  size?: number;
}

export function IconListIcon({ name, size = 0 }: IconListIconProps) {
  return (
    <Span size={size}>
      <Icon name={name} size="match-font" />
    </Span>
  );
}
