import { Component } from 'react';
import XDate from 'xdate';
import { WrTooltip } from '../../styled-components/common/wr-tooltip';

// Properties
// date - timestamp
// style - Class to apply to the timeago element
// tooltip - If true, will display a tooltip with full date on hover

/** @deprecated prefer `import { RelativeTimeText } from '~/components/common/RelativeTimeText';` */
class TimeAgo extends Component {
  componentDidMount() {
    const $timestamp = $(this.timestamp);
    if (typeof timeAgoLocales[I18n.locale] !== 'undefined') {
      $.timeago.settings.strings = timeAgoLocales[I18n.locale];
    }
    $timestamp.timeago();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.date !== this.props.date) {
      const $timestamp = $(this.timestamp);
      $timestamp.timeago('update', this.getISODateFormat());
    }
  }

  render() {
    const style = this.props.style ? this.props.style : '';
    const tooltipStyle = this.props.tooltip ? 'time-ago--tooltip' : '';

    if (this.props.returnDateOnly) return this.getFullDateFormat();

    return (
      <WrTooltip content={this.getFullDateFormat()} tagName="span" inline>
        <time
          className={`${style} ${tooltipStyle}`}
          dateTime={this.getISODateFormat()}
          ref={(timestamp) => {
            this.timestamp = timestamp;
          }}
        >
          {this.getFullDateFormat()}
        </time>
      </WrTooltip>
    );
  }

  getFullDateFormat = () => I18n.l('time.formats.long', new Date(this.props.date));

  getISODateFormat = () => {
    const date = new XDate(this.props.date);
    return date.toISOString();
  };
}

window.TimeAgo = TimeAgo;
export default TimeAgo;

//  https://github.com/rmm5t/jquery-timeago/tree/master/locales
const timeAgoLocales = {
  de_de: {
    prefixAgo: 'vor',
    prefixFromNow: 'in',
    suffixAgo: '',
    suffixFromNow: '',
    inPast: 'jetzt gleich',
    seconds: 'wenigen Sekunden',
    minute: 'etwa einer Minute',
    minutes: '%d Minuten',
    hour: 'etwa einer Stunde',
    hours: '%d Stunden',
    day: 'etwa einem Tag',
    days: '%d Tagen',
    month: 'etwa einem Monat',
    months: '%d Monaten',
    year: 'etwa einem Jahr',
    years: '%d Jahren',
  },
  en_us: {
    prefixAgo: null,
    prefixFromNow: null,
    suffixAgo: 'ago',
    suffixFromNow: 'from now',
    seconds: 'less than a minute',
    minute: 'about a minute',
    minutes: '%d minutes',
    hour: 'about an hour',
    hours: 'about %d hours',
    day: 'a day',
    days: '%d days',
    month: 'about a month',
    months: '%d months',
    year: 'about a year',
    years: '%d years',
    wordSeparator: ' ',
    numbers: [],
  },
  es_es: {
    prefixAgo: 'hace',
    prefixFromNow: 'dentro de',
    suffixAgo: '',
    suffixFromNow: '',
    seconds: 'menos de un minuto',
    minute: 'un minuto',
    minutes: 'unos %d minutos',
    hour: 'una hora',
    hours: '%d horas',
    day: 'un día',
    days: '%d días',
    month: 'un mes',
    months: '%d meses',
    year: 'un año',
    years: '%d años',
  },
  fr_fr: {
    prefixAgo: 'il y a',
    prefixFromNow: "d'ici",
    seconds: "moins d'une minute",
    minute: 'environ une minute',
    minutes: 'environ %d minutes',
    hour: 'environ une heure',
    hours: 'environ %d heures',
    day: 'environ un jour',
    days: 'environ %d jours',
    month: 'environ un mois',
    months: 'environ %d mois',
    year: 'un an',
    years: '%d ans',
  },
  it_it: {
    prefixAgo: null,
    prefixFromNow: 'fra',
    suffixAgo: 'fa',
    suffixFromNow: null,
    seconds: 'meno di un minuto',
    minute: 'circa un minuto',
    minutes: '%d minuti',
    hour: "circa un'ora",
    hours: 'circa %d ore',
    day: 'un giorno',
    days: '%d giorni',
    month: 'circa un mese',
    months: '%d mesi',
    year: 'circa un anno',
    years: '%d anni',
  },
  ja_ja: {
    prefixAgo: '',
    prefixFromNow: '今から',
    suffixAgo: '前',
    suffixFromNow: '後',
    seconds: '1 分未満',
    minute: '約 1 分',
    minutes: '%d 分',
    hour: '約 1 時間',
    hours: '約 %d 時間',
    day: '約 1 日',
    days: '約 %d 日',
    month: '約 1 ヶ月',
    months: '約 %d ヶ月',
    year: '約 1 年',
    years: '約 %d 年',
    wordSeparator: '',
  },
  ko_ko: {
    prefixAgo: null,
    prefixFromNow: null,
    suffixAgo: '전',
    suffixFromNow: '후',
    seconds: '1분',
    minute: '약 1분',
    minutes: '%d분',
    hour: '약 1시간',
    hours: '약 %d시간',
    day: '하루',
    days: '%d일',
    month: '약 1개월',
    months: '%d개월',
    year: '약 1년',
    years: '%d년',
    wordSeparator: ' ',
    numbers: [],
  },
  nl_nl: {
    prefixAgo: null,
    prefixFromNow: 'over',
    suffixAgo: 'geleden',
    suffixFromNow: null,
    seconds: 'minder dan een minuut',
    minute: 'ongeveer een minuut',
    minutes: '%d minuten',
    hour: 'ongeveer een uur',
    hours: 'ongeveer %d uur',
    day: 'een dag',
    days: '%d dagen',
    month: 'ongeveer een maand',
    months: '%d maanden',
    year: 'ongeveer een jaar',
    years: '%d jaar',
    wordSeparator: ' ',
    numbers: [],
  },
  pt_br: {
    prefixAgo: 'há',
    prefixFromNow: 'em',
    suffixAgo: null,
    suffixFromNow: null,
    seconds: 'alguns segundos',
    minute: 'um minuto',
    minutes: '%d minutos',
    hour: 'uma hora',
    hours: '%d horas',
    day: 'um dia',
    days: '%d dias',
    month: 'um mês',
    months: '%d meses',
    year: 'um ano',
    years: '%d anos',
  },
  sv_se: {
    prefixAgo: null,
    prefixFromNow: null,
    suffixAgo: 'sedan',
    suffixFromNow: 'från nu',
    seconds: 'mindre än en minut',
    minute: 'ungefär en minut',
    minutes: '%d minuter',
    hour: 'ungefär en timme',
    hours: 'cirka %d timmar',
    day: 'ett dygn',
    days: '%d dagar',
    month: 'ungefär en månad',
    months: '%d månader',
    year: 'ungefär ett år',
    years: '%d år',
    wordSeparator: ' ',
    numbers: [],
  },
  zh_cn: {
    prefixAgo: null,
    prefixFromNow: null,
    suffixAgo: '之前',
    suffixFromNow: '之后',
    seconds: '不到1分钟',
    minute: '大约1分钟',
    minutes: '%d分钟',
    hour: '大约1小时',
    hours: '大约%d小时',
    day: '1天',
    days: '%d天',
    month: '大约1个月',
    months: '%d月',
    year: '大约1年',
    years: '%d年',
    numbers: [],
    wordSeparator: '',
  },
};
