import _ from 'underscore';
import EnvironmentalAlgoliaIndex from './environmental-algolia-index';

class AlgoliaEnvironment {
  constructor(env) {
    this.env = env;
    this.getIndicesForEnvironment = this.getIndicesForEnvironment.bind(this);
  }

  getIndicesForEnvironment(indices) {
    return _.map(indices, (index) => new EnvironmentalAlgoliaIndex(index, this.env));
  }
}

window.AlgoliaEnvironment = AlgoliaEnvironment;

export default AlgoliaEnvironment;
