import { Component } from 'react';
import styled from 'styled-components';
import ClickOff from '../common/click-off';
import AutoPosition from '../../autoposition';

/** @deprecated prefer `import { Menu } from '@workramp/ui';` */
class Dropdown extends Component {
  state = {
    show: false,
    menuPosition: { top: 'auto', right: 'auto', bottom: 'auto', left: 'auto' },
  };

  render() {
    let list;

    const { menuPosition } = this.state;
    const style = {
      top: menuPosition.top,
      right: menuPosition.right,
      bottom: menuPosition.bottom,
      left: menuPosition.left,
    };
    style.display = this.state.show ? 'block' : 'none';

    list = (
      <DropdownList className="dropdown--list" style={style} height={this.props.height}>
        {this.props.children}
      </DropdownList>
    );

    return (
      <div className="dropdown">
        <ClickOff onDOMClick={this.closeMenu}>
          <div
            ref={(label) => (this.label = label)}
            onClick={this.toggleMenu}
            role="button"
            tabIndex={0}
            onKeyDown={(event) => {
              const { key } = event;
              if (key === 'Enter' || key === ' ') this.toggleMenu(event);
            }}
          >
            {this.props.button}
          </div>
          {this.props.allowDropdownClick && list}
        </ClickOff>
        {!this.props.allowDropdownClick && list}
      </div>
    );
  }

  toggleMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      show: !this.state.show,
      menuPosition: this.props.position || AutoPosition.calculateMenuPosition(this.label, this.props.alwaysDropDown),
    });
  };

  closeMenu = () => {
    this.setState({ show: false });
  };
}

const DropdownList = styled.ul`
  ${(props) =>
    props.height &&
    `
    max-height: ${props.height}px;
    overflow: auto;
  `}
`;

window.Dropdown = Dropdown;

export default Dropdown;
