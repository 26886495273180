import PropTypes from 'prop-types';

const UniversityPropTypes = {
  university: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),

  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      action: PropTypes.func,
    }),
  ),
};

window.UniversityPropTypes = UniversityPropTypes;

export default UniversityPropTypes;
