import AssetPickerTypes from '../../components/common/asset-picker/asset-picker-types';
import LearningObjectIcon from '../../components/common/asset-picker/LearningObjectIcon';

const AcademyIndexFormatters = {
  formatResult: (result) => {
    const snippetResult = result._snippetResult;
    const highlightResult = result._highlightResult;
    const contentType = result.content_type || result.type;

    const valueFromResult = (field) => {
      let value;
      if (snippetResult || highlightResult) {
        value = (snippetResult || highlightResult)[field]?.value;
      }
      value ||= result[field];
      value ||= '';
      return value;
    };

    const name = valueFromResult('name');
    // First want to prioritize any match that has a highlighted result
    //  Prioritization in this order:
    //    1. Description (description)
    //    2. Section Titles / Task Names (section_and_task_titles)
    //    3. Text Blocks (content)
    const keys = ['description', 'section_and_task_titles', 'content'];
    let contentSnippet;

    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      const data = (snippetResult || highlightResult)[key];
      if (Array.isArray(data)) {
        contentSnippet = AcademyIndexFormatters._formatListResult(data);
      } else if (typeof data === 'object' && AcademyIndexFormatters._isMatch(data)) {
        contentSnippet = data.value;
      }

      if (contentSnippet != null) break;
    }
    contentSnippet ||= valueFromResult('description');

    // If any tags "match", also include those in search result
    const tags = AcademyIndexFormatters._formatListResult(highlightResult.tags) || '';
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { objectID, type, content_id, content_type, parent_id, parent_type } = result;
    const redirectParams = {
      resultId: objectID,
      resultType: type,
      contentId: content_id,
      contentType: content_type,
      parentId: parent_id,
      parentType: parent_type,
    };
    const url = AssetPickerTypes.getViewItemUrlForAcademyLearnerSearch(redirectParams);

    return {
      icon: <LearningObjectIcon type={contentType} />,
      lastUpdatedAt: result.updated_at,
      name,
      tags,
      content: contentSnippet,
      onSelect: (e) => {
        const openInNewTab = result.open_in_new_tab || !!(e.ctrlKey || e.metaKey);
        if (openInNewTab) {
          window.open(url, '_blank');
        } else {
          window.location.href = url;
        }
      },
      searchResult: result,
      type: result.type,
      contentType,
      parentType: result.parent_type,
      parentName: result.parent_name,
    };
  },
};

AcademyIndexFormatters._isMatch = function (snippet) {
  return snippet && snippet.matchLevel !== 'none';
};

AcademyIndexFormatters._getTags = function (result) {
  const highlightResult = result._highlightResult;
  const tags = highlightResult?.tags;
  if (tags) {
    return AcademyIndexFormatters._formatListResult(tags);
  }
};

AcademyIndexFormatters._formatListResult = function (listResult) {
  const filteredResult = listResult?.filter((res) => AcademyIndexFormatters._isMatch(res))?.map((res) => res.value);
  return filteredResult == null || filteredResult.length === 0 ? null : filteredResult.join(' ');
};

window.AcademyIndexFormatters = AcademyIndexFormatters;
