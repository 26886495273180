import { getCurrentUser } from '~/common/explicit-exports';

const { ADMIN, ACADEMY_ADMIN, EDITOR, MANAGER } = window.APP_CONSTANTS.PERMISSIONS;

export function getUserPermissions(): string[] {
  const currentUser = getCurrentUser();
  const userPermissions = currentUser.rolePermissionNames;

  if (currentUser.admin) userPermissions.push(ADMIN);
  if (currentUser.is_mentor) userPermissions.push(MANAGER);
  if (currentUser.is_editor) userPermissions.push(EDITOR);
  if (currentUser.isAcademyAdmin) userPermissions.push(ACADEMY_ADMIN);

  return userPermissions;
}
