import { Component } from 'react';

class PillList extends Component {
  render() {
    return <div className="flex flex-wrap">{this.props.pills.map((pill) => this.renderPill(pill))}</div>;
  }

  renderPill = (item) => {
    let cls = 'input-pill hide-overflow';
    if (this.props.onSelection) {
      cls += ' cursor-pointer';
    }

    return (
      <div className={cls} key={item} data-selected-id={item} onClick={this.onSelectPill}>
        <span className="mrm">{item}</span>
        {this.props.allowDeletion && (
          <img
            src="/images/cross_icon_white.svg"
            data-selected-id={item}
            className="remove-pill-btn cursor-pointer"
            onClick={this.onDeletePill}
          />
        )}
      </div>
    );
  };

  onSelectPill = (e) => {
    if (this.props.onSelection) {
      let pill = $(e.target).attr('data-selected-id');
      if (!pill) {
        pill = $(e.target).parent().attr('data-selected-id');
      }

      if (pill) {
        this.props.onSelection(pill);
      }
    }
  };

  onDeletePill = (e) => {
    const pill = $(e.target).attr('data-selected-id');
    this.props.onDeletePill(pill);
  };
}

window.PillList = PillList;
export default PillList;
