class EnvironmentalAlgoliaIndex {
  constructor(index, env) {
    this.index = index;
    this.env = env;

    this.generateQuery = this.generateQuery.bind(this);
    this.getEnvironmentalIndexName = this.getEnvironmentalIndexName.bind(this);
    this.format = this.format.bind(this);
    this.filter = this.filter.bind(this);
  }

  generateQuery(query, params) {
    return {
      indexName: this.getEnvironmentalIndexName(),
      query,
      params: this.index.getParams(params),
    };
  }

  getEnvironmentalIndexName() {
    return `${this.index.name}_${this.env}`;
  }

  format(result) {
    return this.index.format(result);
  }

  filter(results, afterFilter) {
    return this.index.filter(results, afterFilter);
  }
}

window.EnvironmentalAlgoliaIndex = EnvironmentalAlgoliaIndex;

export default EnvironmentalAlgoliaIndex;
