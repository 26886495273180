import styled from 'styled-components';
import Rating from 'react-rating';
import ReactTooltip from 'react-tooltip';
import pluralize from 'pluralize';
import { theme } from '../../styles';

export default function RatingsWithOverlay({ tooltipId, ratings }) {
  const starColor = getCurrentUser().enterprise.scheme_color || theme.color.darkBlue;
  const emptyStar = <Star className="far fa-star" starColor={starColor} />;
  const fullStar = <Star className="fas fa-star" starColor={starColor} />;
  return (
    <div>
      <div data-tip data-for={`${tooltipId}ratings`}>
        <Rating emptySymbol={emptyStar} fullSymbol={fullStar} initialRating={ratings.avg_rating} readonly />
      </div>
      <ReactTooltip id={`${tooltipId}ratings`} effect="solid" className="react-tooltip-series-card-content">
        {pluralize('Rating', ratings.num_ratings, true)}
      </ReactTooltip>
    </div>
  );
}

const Star = styled.i`
  color: ${(props) => props.starColor};
  font-size: 0.7rem;
  filter: drop-shadow(1px 1px 1px grey);
  margin-left: 2px;
`;
