import { Component } from 'react';
import styled from 'styled-components';
import { guid } from '../../common';
import UploadWithProgress from './upload-with-progress';

export default class EditableImage extends Component {
  constructor(props) {
    super(props);

    /**
     * Note: if upload is passed in via props, the file upload is being handled elsewhere. This does not change the
     * functionality of the editable image itself. It will render with the uploaded image instead of any default.
     * @type {{image, upload}}
     */
    this.state = {
      upload: this.props.upload,
      image: this.props.image,
    };
  }

  // This is a hack to get around the fact that the image is not being updated when the props change.
  // TODO: Figure out where in the app this hack is required to avoid all this hackiness

  componentDidMount() {
    this.componentIsMounted = true;
  }

  componentDidUpdate() {
    if (this.componentIsMounted && this.props.image !== this.state.image) {
      this.setState({ image: this.props.image });
    }
    if (this.componentIsMounted && this.props.upload && this.state.upload && this.props.upload !== this.state.upload) {
      this.setState({ upload: this.props.upload });
    }
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  render() {
    return (
      <div className={this.props.className} onClick={this.props.onClick}>
        <Image src={this.state.image} styles={this.props.styles}>
          {this.state.image ? null : this.props.placeholder}
        </Image>
        {this.props.editMode ? this.getOverlayContent() : ''}
        {this.props.children}
      </div>
    );
  }

  getOverlayContent = () => {
    const overlayClass = this.props.overlayClass || '';
    const uploadingClass = this.state.upload ? 'uploading' : '';
    if (this.state.upload) {
      const { upload } = this.state;
      return (
        <label className={`university-logo--overlay university-logo--overlay--active ${overlayClass}`}>
          <div className={`university-image-upload ${uploadingClass} ${overlayClass}`}>
            <UploadWithProgress
              data={upload.data}
              url={this.props.uploadUrl}
              name={null}
              method="PUT"
              uploadCompleted={this.handleUploadEndState}
              uploadFailed={this.handleUploadEndState}
              onlyShowProgressBar={this.props.onlyShowProgressBar}
            />
          </div>
        </label>
      );
    }
    return (
      <label className={`university-logo--overlay ${overlayClass}`}>
        {this.props.customOverlayMessage ? (
          <div className="overlay-messaging">
            <img className={`university-logo--edit-icon ${overlayClass}`} src="/images/edit_icon_white.svg" />
            {this.props.customOverlayMessage}
          </div>
        ) : (
          <img className={`university-logo--edit-icon ${overlayClass}`} src="/images/edit_icon_white.svg" />
        )}
        <input name="file-upload" style={{ display: 'none' }} type="file" onChange={this.onInputChange} />
      </label>
    );
  };

  handleUploadEndState = (result) => {
    let newImage;
    if (this.props.inLearnerHome) {
      newImage = result.avatar;
    } else if (this.props.imageFieldName === 'card_banner') {
      newImage = result.cardBanner;
      if (this.props.handleResultCB) this.props.handleResultCB(result.cardBanner, result.cardBannerName);
    } else {
      newImage = result.content.logo;
    }

    this.setState({ upload: null, image: newImage });
  };

  onInputChange = (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    const data = new FormData();
    data.append(this.props.imageFieldName, file);

    const parent = $(e.target).parent();
    parent.trigger('reset');

    if (file && data) {
      const upload = {
        id: guid(),
        data,
        name: file.name,
      };

      this.setState({ upload });
    }
  };
}

const Image = styled.div`
  background-image: ${(props) => (props.src ? `url(${props.src})` : '')};
  height: ${(props) => (props.styles && props.styles.height ? props.styles.height : '80px')};
  width: ${(props) => (props.styles && props.styles.width ? props.styles.width : '80px')};
  border-radius: ${(props) => (props.styles && props.styles.borderRadius ? props.styles.borderRadius : '50%')};
  position: ${(props) => (props.position ? props.position : '')};

  background-position: center;
  background-size: cover;

  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
`;
