import type { SemanticColorNames } from '@workramp/ui';
import { P, Utility } from '@workramp/ui';
import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.on[100]};
  border-radius: ${({ theme }) => theme.radii.xl}px;
  padding-top: ${({ theme }) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }) => theme.spacing(4)}px;
  overflow: auto;
`;

export const StatWrapper = styled.div`
  box-sizing: border-box;
  flex: 1 0 0%;
  width: 100%;
  max-width: 100%;
  padding-left: ${({ theme }) => theme.spacing(4)}px;
  padding-right: ${({ theme }) => theme.spacing(4)}px;

  & + & {
    border-left: 1px solid ${({ theme }) => theme.colors.on[100]};
  }
`;

export const StatValue = styled(P)<{ $color?: SemanticColorNames }>`
  color: ${({ theme, $color }) => $color && theme.colors[$color][500]};
`;

export const StatLabel = styled(Utility)`
  color: ${({ theme }) => theme.colors.on[600]};
`;

export const LabelWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;
