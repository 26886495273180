export const toAbbreviatedNumberString = (num, digits = 2, withSpace = false) => {
  /*
      TODO: We probably want to replace the code below with a package to handle edge cases associated
        with Internationalization.
      Adapted from: https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
   */
  const lookup = [
    { value: 0, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 't' },
    { value: 1e15, symbol: 'q' },
    { value: 1e18, symbol: 'Q' },
    { value: 1e21, symbol: 's' },
    { value: 1e24, symbol: 'S' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => Math.abs(num) >= item.value);

  if (item) {
    const itemSymbol = withSpace ? ` ${item.symbol}` : item.symbol;
    return (num / (item.value || 1)).toFixed(digits).replace(rx, '$1') + itemSymbol;
  }
  return '0';
};

window.WRNumbers = {
  toAbbreviatedNumberString,
};
