import _ from 'underscore';
import { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import XDate from 'xdate';
import FilesApi from '~/agent/files';
import TimeUtils from '../../../common/time-utils';
import EditableImage from '../../common/editable-image';
import RatingsWithOverlay from '../../common/ratings-with-overlay';
import TruncatedDiv from '../../widget/truncated-div';
import { theme } from '../../../styles';
import LearningObjectIcon from '../../common/asset-picker/LearningObjectIcon';
import { FooterProgress, FooterCompleted, FooterStart } from './footer-views';

class EndUserCardView extends Component {
  TRANSLATED_OVERDUE = I18n.t('js.learner.card.overdue');

  TRANSLATED_TAGS = I18n.t('js.learner.card.tags');

  TRANSLATED_ITEMS = I18n.t('js.learner.card.items');

  TRANSLATED_COMPLETED = I18n.t('js.learner.card.completed');

  TRANSLATED_GET_STARTED = I18n.t('js.learner.card.get_started');

  TRANSLATED_FAILED = I18n.t('js.learner.card.failed');

  TRANSLATED_REVIEW = I18n.t('js.learner.card.review');

  constructor(props) {
    super(props);
    this.state = {
      cardBanner: this.props.cardBanner,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.mounted && prevProps.cardBanner !== this.props.cardBanner) {
      this.setState({ cardBanner: this.props.cardBanner });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  cardName = () =>
    this.props.name || this.props.cardHeaderText ? (
      <TruncatedDiv
        className="end-user-card--body-name--2019"
        text={this.props.name || this.props.cardHeaderText}
        numLines={2}
        withRerenderHack
        role="heading"
        aria-level="3"
      />
    ) : null;

  getAssignedDateString = () => {
    const { assignedDate } = this.props;
    if (!assignedDate) return null;

    const dateToCheck = new Date(assignedDate);
    let dateString = I18n.l('date.formats.short', new XDate(assignedDate, true));

    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (yesterday.toDateString() === dateToCheck.toDateString()) {
      dateString = I18n.t('date.time_constructs.yesterday');
    } else if (today.toDateString() === dateToCheck.toDateString()) {
      dateString = I18n.t('date.time_constructs.today');
    }

    return `${I18n.t('js.learner.card.assigned', {
      date: dateString,
    })} • `;
  };

  cardDate = () => {
    const { dueDate, assignedDate, startTime, endTime } = this.props;
    let TRANSLATED_DATE_STRING = null;

    if (dueDate) {
      TRANSLATED_DATE_STRING = I18n.t('js.learner.card.due', {
        date: I18n.l('date.formats.short', new XDate(dueDate, true)),
      });
    } else if (startTime && endTime) {
      TRANSLATED_DATE_STRING = WRDates.getFormattedDateRangeInLocalTimeWithTZLabel(startTime, endTime);
    }

    if (TRANSLATED_DATE_STRING) {
      return (
        <div className="end-user-card--body-date">
          <img src="/images/calendar_icon.svg" width="16px" height="18px" style={{ marginTop: '-5px' }} />
          {this.getAssignedDateString()}
          {TRANSLATED_DATE_STRING}
        </div>
      );
    }
  };

  footer = () => {
    let footer = null;
    let footerInner = null;

    if (!this.props.hideFooter) {
      if (this.props.percent == 100) {
        if (this.props.failed) {
          footerInner = (
            <FooterStart color={theme.color.textDescription} text={this.props.footerText || this.TRANSLATED_REVIEW} />
          );
        } else {
          footerInner = (
            <FooterCompleted
              color={theme.color.textDescription}
              text={this.props.footerText || this.TRANSLATED_COMPLETED}
            />
          );
        }
      } else if (this.props.percent == 0) {
        footerInner = (
          <FooterStart
            color={theme.color.textDescription}
            text={this.props.footerText || this.TRANSLATED_GET_STARTED}
          />
        );
      } else {
        footerInner = (
          <FooterProgress
            color={theme.color.textDescription}
            percent={this.props.percent}
            text={this.props.progressText}
          />
        );
      }
      footer = <div className="end-user-card--footer--2019">{footerInner}</div>;
    }

    return footer;
  };

  renderRatings = () => (
    <RatingsWrapper>
      <RatingsWithOverlay ratings={this.props.ratings} tooltipId={this.props.tooltipId} />
    </RatingsWrapper>
  );

  timeEstimate = () =>
    this.props.timeEstimateSeconds ? (
      <NewTimeEstimate>
        <img src="/images/time_estimate_icon_white.svg" width="12px" height="12px" aria-hidden="true" />
        {TimeUtils.displayDurationShort(this.props.timeEstimateSeconds)}
      </NewTimeEstimate>
    ) : null;

  fetchPresignedFileUrl = async () => {
    const { cardBanner } = this.state;
    const bannerImage = cardBanner ? await FilesApi.getPresignedFileUrl(cardBanner) : null;
    this.setState({ cardBanner: bannerImage ? bannerImage.presignedFileUrl : null });
  };

  cardBannerImg = () => {
    if (this.state.cardBanner && this.state.cardBanner.indexOf('uploads') !== 0) return this.state.cardBanner;
    if (this.state.cardBanner && this.state.cardBanner.indexOf('uploads') === 0) this.fetchPresignedFileUrl();
    switch (this.props.type) {
      case AssetPickerTypes.GUIDE:
        return APP_CONSTANTS.DEFAULT_COVER_IMAGES.GUIDE;
      case AssetPickerTypes.SERIES:
        return APP_CONSTANTS.DEFAULT_COVER_IMAGES.SERIES;
      case AssetPickerTypes.CHALLENGE:
        return APP_CONSTANTS.DEFAULT_COVER_IMAGES.CHALLENGE;
      case AssetPickerTypes.CLASS:
        return APP_CONSTANTS.DEFAULT_COVER_IMAGES.CLASS;
      default:
        return APP_CONSTANTS.DEFAULT_COVER_IMAGES.GUIDE;
    }
  };

  stopEditorClickPropagation = (e) => {
    if (this.props.editMode) {
      e.stopPropagation();
    }
  };

  tagsInfo = () => (
    <SeriesContentInfo data-tip data-for={`${this.props.tooltipId}tags`}>
      <SeriesContentIcon src="/images/tag_solid_white.svg" />
      {this.props.objectTags.length}
    </SeriesContentInfo>
  );

  seriesContentInfo = () => (
    <SeriesContentInfo data-tip="" data-for={this.props.tooltipId}>
      <SeriesContentIcon src="/images/path_content_icon.svg" />
      {this.props.orderedSeriesAssetTitles.length}
    </SeriesContentInfo>
  );

  render() {
    const {
      actionsNode,
      allowEditBanner,
      cardBannerUploadUrl,
      descriptionLines,
      descriptionNode,
      descriptionText,
      dueDate,
      editMode,
      failed,
      handleResultCB,
      hideFooter,
      infoNode,
      location,
      moreOptionsNode,
      objectTags,
      onClick,
      orderedSeriesAssetTitles,
      percent,
      ratings,
      tooltipId,
      type,
    } = this.props;

    const name = this.cardName();
    const date = this.cardDate();
    const footer = this.footer();
    const timeEstimate = this.timeEstimate();
    const cardBannerImg = this.cardBannerImg();

    const isOverdue = percent !== 100 && TimeUtils.hasDatePassed(dueDate);
    const clickableClass = onClick ? ' end-user-card--clickable' : '';

    const assetTitlesForTooltip = _.map(orderedSeriesAssetTitles, (title) => <div key={title}>{title}</div>);

    const tagTitlesForTooltip = _.map(objectTags, (tag) => <div key={tag.id}>{tag.name}</div>);
    let eventCls = '';
    if (type === AssetPickerTypes.CLASS) eventCls = 'end-user-card-events';

    return (
      <div className="card-wrapper card-wrapper--2019" ref={(cardWrapper) => (this.cardWrapper = cardWrapper)}>
        <div
          className={`end-user-card js-euc ${clickableClass} end-user-card--2019 ${eventCls}`}
          onClick={onClick}
          role="button"
          tabIndex={0}
          onKeyDown={({ key }) => {
            if (key === 'Enter' || key === ' ') onClick();
          }}
        >
          <BannerImageOverlay />
          {editMode || allowEditBanner ? (
            <EditableImage
              className="editable-image"
              overlayClass="end-user-card-banner--overlay"
              image={cardBannerImg}
              uploadUrl={cardBannerUploadUrl}
              imageFieldName="card_banner"
              onlyShowProgressBar
              onClick={this.stopEditorClickPropagation}
              editMode={editMode || allowEditBanner}
              handleResultCB={handleResultCB}
              styles={{
                height: '100px',
                width: 'inherit',
                borderRadius: '6px 6px 0 0',
                position: 'relative',
              }}
            />
          ) : (
            <BannerImage src={cardBannerImg} />
          )}
          {isOverdue && <OverdueBanner>{this.TRANSLATED_OVERDUE}</OverdueBanner>}
          {failed && percent === 100 && <FailedBanner>{this.TRANSLATED_FAILED}</FailedBanner>}
          {/* leaving ratings in in case we add it back later - quite likely? */}
          {false && ratings && ratings.num_ratings > 0 && this.renderRatings()}
          {moreOptionsNode && (
            <div className="end-user-card--more-options-node--2019" onClick={this.stopEditorClickPropagation}>
              {moreOptionsNode}
            </div>
          )}
          <NewIconCircle size="30px">
            <LearningObjectIcon type={type} width="15px" />
          </NewIconCircle>
          <CardBannerInfoIcons>
            {objectTags && !_.isEmpty(objectTags) && this.tagsInfo()}
            {orderedSeriesAssetTitles && this.seriesContentInfo()}
            {timeEstimate}
          </CardBannerInfoIcons>
          <div className="end-user-card--body end-user-card--body--2019">
            {name}
            {date}
            {location ? (
              <div className="end-user-card--body-location flex" style={{ marginTop: '5px', fontSize: '.7rem' }}>
                <i className="fas fa-map-marker-alt" style={{ fontSize: '16px', margin: '0 10px 0 2px' }} />
                <div className="hide-overflow">{location}</div>
              </div>
            ) : null}
            <div className="end-user-card--body-description">
              <TruncatedDiv text={descriptionText} numLines={descriptionLines || (infoNode ? 2 : 3)} withRerenderHack />
              {descriptionNode}
            </div>
          </div>
          <div>
            {infoNode && <div className="end-user-card--body-info">{infoNode}</div>}
            {actionsNode || hideFooter ? null : <div className="end-user-card--divider" />}
            {actionsNode || footer}
          </div>
        </div>
        <ReactTooltip id={`${tooltipId}tags`} effect="solid" className="react-tooltip-series-card-content">
          <TooltipSubtitle>{this.TRANSLATED_TAGS}</TooltipSubtitle>
          {tagTitlesForTooltip}
        </ReactTooltip>
        <ReactTooltip id={tooltipId} effect="solid" className="react-tooltip-series-card-content">
          <TooltipSubtitle>{this.TRANSLATED_ITEMS}</TooltipSubtitle>
          {assetTitlesForTooltip}
        </ReactTooltip>
      </div>
    );
  }
}

const CardBannerInfoIcons = styled.div`
  position: absolute;
  top: 80px;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 2; /* places icons above banner image overlay */

  > div {
    margin-right: 10px;
  }
`;

const NewTimeEstimate = styled.div`
  color: white;
  ${theme.font.semibold}
  font-size: 0.7rem;
  display: flex;
  margin-top: 1px;

  img {
    margin-right: 4px;
  }
`;

const SeriesContentInfo = styled.div`
  color: white;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
    margin-bottom: 1px;
  }
`;

const SeriesContentIcon = styled.img`
  height: 12px;
  width: 12px;
`;

const TooltipSubtitle = styled.div`
  color: #929fb0;
`;

export const OverdueBanner = styled.div`
  height: 20px;
  width: 55px;
  background-color: ${theme.color.overdue};
  color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -5px;
  top: 10px;
`;

const NewIconCircle = styled.div`
  position: absolute;
  top: 85px;
  left: 10px;
  flex-shrink: 0;
  background: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(185, 209, 201, 0.47);
  z-index: 2; /* places icon above banner image overlay */
`;

const NewIconCircleFolder = styled(NewIconCircle)`
  box-shadow: unset;
  position: absolute;
  left: 14px;
  top: 14px;
  background: transparent;
  width: unset;
  height: unset;
  border-radius: unset;
`;

const BannerImage = styled.div`
  min-height: 100px;
  border-radius: 6px 6px 0 0;
  position: relative;
  background-image: ${(props) => (props.src ? `url(${props.src})` : '')};
  width: inherit;

  background-position: center;
  background-size: cover;

  box-sizing: border-box;
`;

const BannerImageOverlay = styled.div`
  height: 100px;
  border-radius: 6px 6px 0 0;
  width: 100%;
  background-image: linear-gradient(
    transparent,
    transparent,
    transparent,
    rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0.4)
  );
  position: absolute;
  z-index: 1;
  pointer-events: none;
`;

const RatingsWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const FailedBanner = styled(OverdueBanner)``;

window.EndUserCardView = EndUserCardView;

export default EndUserCardView;
