import '~/lib/datadog'; // Init Datadog RUM and Logs

// Libraries with plugin ecosystems
import './jquery-setup';
import './moment-setup';
import './bootstrap-setup';
import './redactor-setup';

// Everything else
import React from 'react';
import _ from 'underscore';
import XDate from 'xdate';
import pluralize from 'pluralize';
import 'react-dates/initialize';

window.React = React; // Required for react-ziggeo

/**
 * Leaving these on here for a fallback but please do not use them.
 * They have been removed from the Window type and from globals in .eslintrc.js
 * in order to discourage their use.
 */
/** @deprecated prefer explicit import `import _ from 'underscore';` */
window._ = _;
/** @deprecated prefer explicit import `import XDate from 'xdate';` */
window.XDate = XDate;
/** @deprecated prefer explicit import `import pluralize from 'pluralize';` */
window.pluralize = pluralize;

declare global {
  interface Window {
    /** @deprecated prefer explicit import `import Module from 'package-name';` */
    React: typeof React;
  }
}
