export type AttachableUser = {
  id: string;
  status: {
    status: string;
  };
  taskAssignment: {
    isDeleted: boolean;
    isCompleted: boolean;
  };
  testAssignment: {
    isCurrentlyComplete: boolean;
    updatedAt: string | number;
  };
};

type CourseSection = {
  courseSectionsTasks: CourseSectionTask[];
};

type CourseSectionTask = {
  objects: Record<string, unknown>[];
};

export type CourseCreator = {
  id: number;
  displayName: string;
  avatar?: string | null;
  email?: string;
};

export type Guide = {
  id: string;
  cardBanner?: string;
  displayTitle: string;
  courseSections: CourseSection[];
  showExplanationOnCorrectAnswers: boolean;
  showExplanationOnIncorrectAnswers: boolean;
  originalAuthor: CourseCreator | null;
};

export type GuideSubmissionsProps = {
  attachablesUsers: Record<string, unknown>[];
  bestPracticesOff: boolean;
  currentUser: Record<string, unknown>;
  guide?: Guide;
  guideDeleted: boolean;
  hasScorm: boolean;
  isEndUserView: boolean;
  showNeedsReview: boolean;
  showReviewed: boolean;
  showStats: boolean;
  showGradingButtons: boolean;
  showBestPractices: boolean;
  taskAssignments: Record<string, unknown>;
};

export type Question = {
  id: string;
  contributesToGrading: boolean;
  attachable: {
    invalidForReview: boolean;
  };
};

export enum StatsEnum {
  QuestionsCompleted = 'Questions Completed',
  QuestionsGraded = 'Questions Graded',
  CurrentGrade = 'Current Grade',
  RatingAverage = 'Rating Average',
}

export type Stats = {
  [k in StatsEnum]: string;
};

export type StatsList = [StatsEnum, string][];
