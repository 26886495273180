import _ from 'underscore';
import Urls from '../urls';
import Fetch from './fetch';

const trainingSeriesTrainingClassFieldsToBackendColumns = {
  requireAttendanceForCompletion: 'require_attendance_for_completion',
};

/**
 * Given an object containing a trainingSeriesTrainingClass's fields and values
 * as they would appear in state, this function transform the object's keys to
 * match the backend columns they'll be saved to.
 *
 * For example, given: { requireAttendanceForCompletion: true }
 * this function will return: { require_attendance_for_completion: true }
 * @param {*} tstcFields
 */
const mapTrainingSeriesTrainingClassFieldsToBackendColumns = (tstcFields) =>
  _.reduce(
    tstcFields,
    (backendColumnsToVals, fieldVal, fieldKey) => {
      const backEndColumnName = trainingSeriesTrainingClassFieldsToBackendColumns[fieldKey];

      return {
        ...backendColumnsToVals,
        [backEndColumnName]: fieldVal,
      };
    },
    {},
  );

const TrainingSeriesApi = {
  updateTrainingSeriesTrainingClass: (trainingSeriesTrainingClassId, data) =>
    Fetch.put(
      `training_series_training_classes/${trainingSeriesTrainingClassId}`,
      mapTrainingSeriesTrainingClassFieldsToBackendColumns(data),
    ),
  bulkUpdateTrainingSeriesUserAssignments: (seriesId, assignmentIds, showArchived, successHandler, errorHandler) => {
    const url = Urls.trainingSeriesUserAssignments(seriesId);
    const data = {
      assignment_ids: assignmentIds,
      is_archived: !showArchived,
    };
    Fetch.put(url, data, successHandler, errorHandler);
  },
  markTrainingSeriesUserAssignmentViewed: (assignmentId) => {
    const date = new Date();
    const url = `training_series/${assignmentId}/mark_assignment_viewed?time_viewed=${date.getTime()}`;
    Fetch.post(url);
  },
};

export default TrainingSeriesApi;
