import { WRDOMPurify } from '~/wr-dompurify';
import isFeatureEnabled from '../is-feature-enabled';

export function RedactorVersionViewer({ content }) {
  return (
    <div
      style={{ wordWrap: 'break-word' }}
      className={`task-object-textarea redactor-styles redactor-in ${
        isFeatureEnabled('fy24_q3_text_editor_replacement') ? 'redactor-inter' : ''
      }`}
      id="tempt"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: WRDOMPurify.sanitize(content) }}
    />
  );
}
