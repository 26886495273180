export default class StringUtils {
  static guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  static getOrdinalSuffixOfNumber(number) {
    const value = Number(number);
    let ord = 'th';

    if (value % 10 === 1 && value % 100 !== 11) {
      ord = 'st';
    } else if (value % 10 === 2 && value % 100 !== 12) {
      ord = 'nd';
    } else if (value % 10 === 3 && value % 100 !== 13) {
      ord = 'rd';
    }

    return ord;
  }

  static stripEmojisFromString(string) {
    // pattern from https://stackoverflow.com/questions/10992921/how-to-remove-emoji-code-using-javascript
    const unicodeEmojis =
      /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
    return string.toString().replace(unicodeEmojis, '');
  }

  static extractEmailsFromString(string) {
    // pattern from https://stackoverflow.com/questions/14440444/extract-all-email-addresses-from-bulk-text-using-jquery
    const emailPattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
    return string.toString().match(emailPattern);
  }

  static isEmailValid(string) {
    // pattern from extractEmailsFromString
    const emailPattern =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    return !!string.toString().match(emailPattern);
  }

  static isEmailUsernameValid(string) {
    // pattern from extractEmailsFromString
    const emailUsernamePattern = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*$/;
    return !!string.toString().match(emailUsernamePattern);
  }

  static encodeStringForCSV(thing) {
    // per security audit, strip out leading special characters from CSV cells to prevent CSV formula injection
    const blacklistedLeadingChars = ['+', '-', '@', '|', '='];
    if (typeof thing === 'string' && blacklistedLeadingChars.includes(thing.charAt(0))) {
      thing = ` ${thing}`;
    }
    if (typeof thing === 'string' && thing.includes(',')) {
      thing = `"${thing}"`;
    }
    return thing;
  }

  static slugify(str) {
    if (!str) return '';

    const modifiedString = this.stripEmojisFromString(str)
      .replace(/[^a-zA-Z0-9]+/g, ' ') // replace non-alphanumeric characters with spaces
      .trim() // remove leading and trailing spaces
      .replace(/\s+/g, '-'); // replace spaces with hyphens

    return encodeURIComponent(modifiedString).replace(/%20/g, '-').toLowerCase();
  }

  static capFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static combineClassNames(classNames = []) {
    return classNames
      .map((cn) => (cn != null ? cn.trim() : cn))
      .filter((cn) => cn != null && cn !== '')
      .join(' ');
  }

  static getLocalizedTypeFromReadableEnglishType(type, contentLabelOverrides, isExternal = false) {
    const typesWithOverrides = [
      AssetPickerTypes.ACADEMY_CERTIFICATION,
      AssetPickerTypes.ACADEMY_PATH,
      AssetPickerTypes.ACADEMY_EVENT,
    ];
    const getDisplayNameFn = isExternal ? AssetPickerTypes.getAcademiesDisplayName : AssetPickerTypes.getDisplayName;
    if (typesWithOverrides.includes(type)) {
      switch (type) {
        case AssetPickerTypes.ACADEMY_CERTIFICATION:
          return contentLabelOverrides && contentLabelOverrides.certification
            ? contentLabelOverrides.certification
            : getDisplayNameFn(AssetPickerTypes.ACADEMY_CERTIFICATION);
        case AssetPickerTypes.ACADEMY_PATH:
          return contentLabelOverrides && contentLabelOverrides.path
            ? contentLabelOverrides.path
            : getDisplayNameFn(AssetPickerTypes.ACADEMY_PATH);
        case AssetPickerTypes.ACADEMY_EVENT:
          return contentLabelOverrides && contentLabelOverrides.event
            ? contentLabelOverrides.event
            : getDisplayNameFn(AssetPickerTypes.ACADEMY_EVENT);
      }
    } else {
      return getDisplayNameFn(type);
    }
    console.log(`Error. Tried to localize an item type that does not exist: ${type}`);
  }

  static getSectionLabelOverride({ contentLabelOverrides, section, label, overrideDefault = null }) {
    const labelLower = label.toLowerCase();
    const key = `${section.toLowerCase()}-${labelLower}`;
    return (
      contentLabelOverrides[key] ||
      overrideDefault ||
      APP_CONSTANTS.ACADEMY_LABEL_OVERRIDABLE_SECTION_DEFAULTS[labelLower]
    );
  }

  static getAddOrRemoveFeaturedSection(contentLabelOverrides, section, isFeatured) {
    const name = this.getSectionLabelOverride({
      contentLabelOverrides,
      section,
      label: APP_CONSTANTS.ACADEMY_LABEL_OVERRIDABLE_SECTION_KEY.FEATURED_SECTION,
    });
    return isFeatured ? `Remove from ${name} Section` : `Add to ${name} Section`;
  }

  static getSSOTypeDisplayName(type) {
    switch (type) {
      case APP_CONSTANTS.SSO_TYPES.SAML:
        return I18n.t('js.common.sso.saml');
      case APP_CONSTANTS.SSO_TYPES.OAUTH:
        return I18n.t('js.common.sso.oauth');
    }
  }

  static getInitialsFromString(string, firstAndLast = true) {
    /*
      getInitialsFromString('John Doe Smith') => 'jd'
        -- this was the initials that the <Avatar /> component used to display, so keeping the capability
      getInitialsFromString('John Doe Smith', true) => 'js'
    */
    //  return if empty string
    if (string === '') return '';
    //  split string on space
    const nameSplit = string.split(' ');
    //  get initial of string part
    let initials = nameSplit[0].charAt(0);
    //  if string has more than one part in it
    if (nameSplit.length > 1) {
      if (firstAndLast) {
        //  get initial of last string part
        initials += nameSplit[nameSplit.length - 1].charAt(0);
      } else {
        //  get initial of second string part
        initials += nameSplit[1].charAt(0);
      }
    }
    return initials;
  }

  static ensureNonOrphanedWord = (string) => {
    // make the last space a "non breaking space" so that there is never an orphaned word on the final row
    const stringSplitOnSpaces = string.split(' ');
    const { length } = stringSplitOnSpaces;

    if (length < 2) return string;

    return `${stringSplitOnSpaces.slice(0, length - 1).join(' ')}\u00A0${stringSplitOnSpaces[length - 1]}`;
  };

  // https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
  static generateHash = (string) => {
    let hash = 0;
    let i;
    let chr;
    if (!string) return hash;
    for (i = 0; i < string.length; i++) {
      chr = string.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  static getCapitalizedWordsFromUnderscoredString(string, andHyphens = false) {
    let capitalizedString = string
      .split('_')
      .map((word) => StringUtils.capFirstLetter(word))
      .join(' ');

    if (andHyphens) {
      capitalizedString = capitalizedString
        .split('-')
        .map((word) => StringUtils.capFirstLetter(word))
        .join('-');
    }

    return capitalizedString;
  }

  static getCapitalizedWordsFromCamelCasedString(string) {
    const result = string.replace(/([a-z])([A-Z])/g, '$1 $2');
    return result.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  static prettifyEscapedString(str) {
    // we don't want to display the escaped & as &amp;
    return str.replace(/&amp;/g, '&');
  }

  static useCorrectNounForName(str) {
    const firstChar = str.charAt(0);

    if (firstChar.match(/[aeiou]/i)) {
      return `an ${str}`;
    }

    return `a ${str}`;
  }
}
