import _ from 'underscore';
import { Component } from 'react';
import Immutable from 'immutable';
import PillList from '../widget/pill-list';
import ActionMessages from '../widget/action-messages';
import { apiPost, apiDelete, hideLoader, showLoader, handleApiError, MixPanel } from '../../common';
import MultiInputField from './multi-input-field';
import Modal from './modal';

class AddTagsModal extends Component {
  constructor(props) {
    super(props);
    const { tags, recommendedTags } = this.props;
    this.state = {
      tagsToAdd: [],
      currentTagToAdd: null,
      tags,
      recommendedTags: Immutable.Set(recommendedTags).sort(),
    };
  }

  pasteProcessor = (pasteString) => pasteString.split(',').map((string) => string.trim());

  renderRecommendedTags = () => {
    const { recommendedTags } = this.state;
    if (recommendedTags && recommendedTags.size > 0) {
      return (
        <div className="form-field">
          <div className="form-label">Recommended Tags</div>
          <div>
            <PillList pills={recommendedTags} onSelection={this.onRecommendedTagSelected} />
          </div>
        </div>
      );
    }
  };

  renderExistingTags = () => {
    const { tags } = this.state;
    const { objectData, showExistingTags, existingTagsTitle } = this.props;
    const title =
      existingTagsTitle ||
      `Existing Tags${objectData ? ` on this ${AssetPickerTypes.getDisplayName(objectData.object_type)}` : ''}`;

    if (tags && showExistingTags) {
      const me = this;

      if (tags.length > 0) {
        const currentTags = _.sortBy(tags, (tag) => tag);

        const tagNodes = _.map(currentTags, (tag) => (
          <div className="mbm flex" key={tag}>
            <span>{tag}</span>
            <span className="mlm">
              <img
                src="/images/cross_icon_blue.svg"
                width="10px"
                height="10px"
                className="cursor-pointer"
                onClick={me.removeTag}
                data-selected-tag-name={tag}
              />
            </span>
          </div>
        ));

        return (
          <div className="form-field">
            <div className="form-label">{title} </div>
            <div>{tagNodes}</div>
          </div>
        );
      }
    }
    return null;
  };

  onRecommendedTagSelected = (tag) => {
    const { tagsToAdd, recommendedTags } = this.state;
    MixPanel.track('add_recommended_tag_from_add_tags_modal');
    tagsToAdd.push(tag);
    this.setState({
      tagsToAdd,
      recommendedTags: recommendedTags.delete(tag),
    });
  };

  onSave = (onFinish) => {
    const {
      saveUrl,
      onSaveTag,
      skipSaveApiCall,
      objectData,
      isObjectTag,
      onSave,
      onClose,
      forceSelectFromSuggestions,
    } = this.props;
    const { currentTagToAdd } = this.state;

    if (this.state.tagsToAdd.length > 0 || this.state.currentTagToAdd !== null) {
      let tagsToAdd = this.state.tagsToAdd.slice();
      if (currentTagToAdd && currentTagToAdd.trim()) {
        tagsToAdd.push(currentTagToAdd.trim());
      }
      tagsToAdd = _.uniq(tagsToAdd);

      if (tagsToAdd.length === 0) {
        if (onFinish) onFinish();
        alert('Please enter a valid tag.');
        return;
      }

      const data = objectData || {};
      data.tag_names = tagsToAdd;

      if (skipSaveApiCall) {
        let tagsToCreate = tagsToAdd;
        if (currentTagToAdd && forceSelectFromSuggestions) {
          WRModals.alert(`The following Tag was unable to be added: ${currentTagToAdd}.`);
          tagsToCreate = _.without(tagsToAdd, currentTagToAdd);
        }
        if (tagsToCreate.length) {
          onSaveTag(tagsToCreate);
        }
        onClose(this.state.tags);
        return;
      }
      showLoader();
      apiPost(
        saveUrl,
        data,
        (newTags) => {
          hideLoader();
          this.setState(
            {
              tagsToAdd: [],
              currentTagToAdd: null,
              tags: _.uniq(_.union(this.state.tags, newTags)),
            },
            () => {
              const inContentEditor = objectData;
              if (isObjectTag && inContentEditor) {
                const tagsFailedToCreate = this.tagsNotCreated(tagsToAdd);
                if (!_.isEmpty(tagsFailedToCreate)) {
                  WRModals.alert(`The following Tags were unable to be added: ${tagsFailedToCreate.join(', ')}.`);
                }
              }

              if (!_.isEmpty(newTags)) ActionMessages.generateMessage({ message: 'Successfully Added Tags' });

              onSave(this.state.tags);
              if (onFinish) onFinish();
            },
          );
        },
        handleApiError(onFinish),
        isObjectTag ? null : '/knowledge/',
      );
    } else {
      onClose(this.state.tags);
      if (onFinish) onFinish();
    }
  };

  tagsNotCreated = (tagsAttemptedToCreate) => {
    tagsAttemptedToCreate = _.map(tagsAttemptedToCreate, (tagAttemptedToCreate) => tagAttemptedToCreate.toLowerCase());
    const lowerCaseTagsInState = _.map(this.state.tags, (tagInState) => tagInState.toLowerCase());
    return _.difference(tagsAttemptedToCreate, lowerCaseTagsInState);
  };

  onChange = (item, tagToAdd, tagToRemove) => {
    let { recommendedTags } = this.state;

    if (item) {
      this.state.tagsToAdd.push(item.value);
    } else if (tagToAdd) {
      this.state.tagsToAdd.push(tagToAdd);
    } else if (tagToRemove) {
      // Remove the tag from the list of tags to add
      this.state.tagsToAdd = _.filter(this.state.tagsToAdd, (tag) => tag.toLowerCase() !== tagToRemove.toLowerCase());

      // We also have a recommended tag list. If we remove the tag from the
      // list of 'tags to add' as above, we need to check whether this
      // tag was a recommended tag. If it is, add it back to the recommended
      // list
      const lowerCaseRecommendedTags = _.map(this.props.recommendedTags, (recommendedTag) =>
        recommendedTag.toLowerCase(),
      );
      if (_.contains(lowerCaseRecommendedTags, tagToRemove.toLowerCase())) {
        recommendedTags = recommendedTags.add(tagToRemove);
      }
    }

    this.setState({
      tagsToAdd: this.state.tagsToAdd,
      currentTagToAdd: null,
      recommendedTags: recommendedTags.sort(),
    });
  };

  onInput = (tag) => {
    this.setState({
      currentTagToAdd: tag,
    });
  };

  onClose = () => {
    const { tags } = this.state;
    const { onClose } = this.props;
    this.setState({
      tagsToAdd: [],
      currentTagToAdd: null,
    });
    onClose(tags);
  };

  removeTag = (e) => {
    const { user, tags } = this.state;
    const { objectData, onDelete, isObjectTag, skipDeleteApiCall } = this.props;
    let { deleteUrl } = this.props;
    e.preventDefault();

    const cont = confirm('Are you sure you want to remove this tag?');

    if (cont) {
      const tagName = $(e.target).attr('data-selected-tag-name');
      const newTags = _.filter(tags, (tag) => tag.toLowerCase() !== tagName.toLowerCase());
      if (skipDeleteApiCall) {
        onDelete(tagName, () => {
          this.setState({
            tags: newTags,
          });
        });
        return;
      }

      const data = objectData || {};
      objectData ? (data.name = tagName) : (deleteUrl += tagName);

      showLoader();
      apiDelete(
        deleteUrl,
        data,
        () => {
          hideLoader();
          this.setState(
            {
              tags: newTags,
              user,
            },
            () => {
              if (onDelete) onDelete(newTags);
            },
          );
        },
        handleApiError(),
        isObjectTag ? null : '/knowledge/',
      );
    }
  };

  render() {
    const {
      objectData,
      autoCompleteUrl,
      noCommasAllowed,
      forceSelectFromSuggestions,
      show,
      modalTitle,
      inputPlaceHolderForceSelect,
      customInputHeader,
    } = this.props;
    const { currentTagToAdd, tagsToAdd } = this.state;
    const inputFieldTitle =
      customInputHeader ||
      `New Tags${objectData ? ` for this ${AssetPickerTypes.getDisplayName(objectData.object_type)}` : ''}`;
    const givenTags = this.state.tags || [];

    return (
      <Modal disableSaveOnEnter onClose={this.onClose} onSave={this.onSave} show={show} title={modalTitle}>
        <div className="my-form">
          {this.renderExistingTags()}
          <MultiInputField
            autoCompleteUrl={autoCompleteUrl}
            input={currentTagToAdd}
            name={inputFieldTitle}
            noCommasAllowed={noCommasAllowed}
            onChange={this.onChange}
            onInput={this.onInput}
            placeholder={forceSelectFromSuggestions ? inputPlaceHolderForceSelect : 'Enter tags'}
            tags={tagsToAdd}
            tagsForSuggestionFiltration={givenTags.concat(tagsToAdd)}
            forceSelectFromSuggestions={forceSelectFromSuggestions}
            pasteProcessor={this.pasteProcessor}
          />
          {this.renderRecommendedTags()}
        </div>
      </Modal>
    );
  }
}

AddTagsModal.defaultProps = {
  modalTitle: 'Add Tags',
  inputPlaceHolderForceSelect: 'Select Tags',
};

window.AddTagsModal = AddTagsModal;
export default AddTagsModal;
