import _ from 'underscore';
import propTypes from 'prop-types';
import styled from 'styled-components';
import PathUtils from '../../training/path-utils';
import { media } from '../../styles';
import PathContentRow from './path-content-row';

const PathContent = (props) => {
  const assetNodes = _.map(PathUtils.getOrderedAssets(props.path), (asset, idx) => {
    const info = props.assetsDisplayInfo[asset.asset.id];

    return (
      <PathContentRow
        key={idx}
        url={info.url}
        type={AssetPickerTypes.getDisplayName(asset.asset.type)}
        iconType={asset.asset.type}
        name={asset.asset.title || asset.asset.name}
        description={info.useShortDescription ? asset.asset.shortDescription : asset.asset.description}
        completed={info.isCompleted}
        percentComplete={info.percentComplete}
        status={info.status}
        locked={info.isLocked}
        showRegisteredNotAttendedMsg={info.showRegisteredNotAttendedMsg}
        timeEstimateSeconds={asset.asset.timeEstimateSeconds}
        isReviewMode={props.isReviewMode}
        isMentorOnly={props.isMentorOnly}
        openLinkDirectlyInNewTab={info.openLinkInNewTab}
      />
    );
  });

  return <Wrapper fullWidth={props.fullWidth}>{assetNodes}</Wrapper>;
};

PathContent.propTypes = {
  fullWidth: propTypes.bool,
  path: propTypes.shape({
    trainingSeriesGuides: propTypes.arrayOf(
      propTypes.shape({
        isDeleted: propTypes.bool,
        order: propTypes.number,
      }),
    ),
    trainingSeriesChallenges: propTypes.arrayOf(
      propTypes.shape({
        isDeleted: propTypes.bool,
        order: propTypes.number,
      }),
    ),
    trainingSeriesResources: propTypes.arrayOf(
      propTypes.shape({
        isDeleted: propTypes.bool,
        order: propTypes.number,
      }),
    ),
  }),
};

const Wrapper = styled.div`
  width: ${(props) => (props.fullWidth ? '100%' : '65%')};
  padding: 1.5rem 2.5rem;
  box-sizing: border-box;
  ${media.screenXsMax} {
    width: 100%;
  }
`;

export default PathContent;
