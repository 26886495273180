import { Component } from 'react';
import { apiGet, MixPanel } from '../../../common';

window.withPortalData = function (WrappedComponent, portal) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        portal,
      };
    }

    render() {
      return (
        <WrappedComponent
          portal={this.state.portal}
          reloadPortal={this.reloadPortal}
          openPage={this.openPage}
          openFolder={this.openFolder}
          {...this.props}
        />
      );
    }

    reloadPortal = (secondaryCallback) => {
      apiGet(`knowledge/portals/${this.state.portal.id}`, null, (result) => {
        this.setState({ portal: result });
        if (typeof secondaryCallback === 'function') secondaryCallback();
      });
    };

    openPage = (id) => {
      MixPanel.track('open_portal_page');
      let url = this.props.prependUrl ? this.props.prependUrl : '/knowledge/';
      url = `${url}pages/${id}`;
      if (this.props.query) {
        url = `${url}?query=${this.props.query}`;
      }
      window.location = url;
    };

    openFolder = (id) => {
      MixPanel.track('open_portal_folder');
      let url = this.props.prependUrl ? this.props.prependUrl : '/knowledge/';
      url = `${url}folders/${id}`;
      if (this.props.query) {
        url = `${url}?query=${this.props.query}`;
      }
      window.location = url;
    };
  };
};
