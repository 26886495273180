import _ from 'underscore';
import isFeatureEnabled from '~/components/common/is-feature-enabled';
import { getPercent } from '../common';

export default class AssignmentUtils {
  /**
   * @param assignment - The CourseUserAssignment. May include course or not.
   * @param guide - Optional Course object. Must be present if assignment.course is not.
   */
  static getPercentCompletedForGuideAssignment(assignment, guide) {
    var guide = guide || assignment.course;

    // For cases where admin went and added a task after the fact, the user should still show 100% completion if they
    // previously completed all the tasks.
    if (assignment.isCompleted) return 100;

    return guide && guide.numTotalTasks > 0 ? getPercent(assignment.numCompletedTasks, guide.numTotalTasks) : 0;
  }

  static getProgressTextForSeriesAssignment(
    assignment,
    trainingSeriesTrainingClasses,
    trainingClassSessionAttendeesByEventId,
  ) {
    const { numTotalModules, numCompleted } = this._calcSeriesAssignmentProgress(
      assignment,
      trainingSeriesTrainingClasses,
      trainingClassSessionAttendeesByEventId,
    );
    const TRANSLATED_PROGRESS = I18n.t('common.application.assignment_utils.series_progress', {
      completed: numCompleted,
      total: numTotalModules,
    });

    return TRANSLATED_PROGRESS;
  }

  static getPercentCompletedForSeriesAssignment(
    assignment,
    trainingSeriesTrainingClasses,
    trainingClassSessionAttendeesByEventId,
  ) {
    const { numTotalModules, numCompleted } = this._calcSeriesAssignmentProgress(
      assignment,
      trainingSeriesTrainingClasses,
      trainingClassSessionAttendeesByEventId,
    );
    return (numCompleted / numTotalModules) * 100;
  }

  static _calcSeriesAssignmentProgress(
    assignment,
    trainingSeriesTrainingClasses,
    trainingClassSessionAttendeesByEventId,
  ) {
    /* Hack for not counting duplicates */
    const challengeUserAssignments = _.uniq(
      assignment.challengeUserAssignments,
      false,
      (assignment) => assignment.userId && assignment.trainingSeriesUserAssignmentId && assignment.challengeId,
    );
    let totalNonTrainingClassModules = assignment.courseUserAssignments
      .concat(challengeUserAssignments || [])
      .concat(assignment.resourceUserAssignments || []);
    if (isFeatureEnabled('top_level_content_paths')) {
      totalNonTrainingClassModules = totalNonTrainingClassModules.concat(assignment.assignments || []);
    }
    const numTotalModules = _.size(totalNonTrainingClassModules) + _.size(trainingSeriesTrainingClasses);
    if (assignment.completedAt) return { numTotalModules, numCompleted: numTotalModules };

    let numCompleted = 0;
    _.each(totalNonTrainingClassModules, (module) => {
      if (module.isCompleted || module.isSubmitted) {
        numCompleted++;
      }
    });

    numCompleted += _.reduce(
      trainingSeriesTrainingClasses,
      (numCompletedTrainingClassModules, trainingSeriesTrainingClass) => {
        const hasCompletedTrainingClassModule = _.some(
          trainingClassSessionAttendeesByEventId[trainingSeriesTrainingClass.trainingClassId],
          (attendee) => {
            if (trainingSeriesTrainingClass.requireAttendanceForCompletion) {
              return attendee.registeredAndMarkedAttended;
            }
            return attendee.registeredAndSessionHasPassed;
          },
        );

        return hasCompletedTrainingClassModule
          ? numCompletedTrainingClassModules + 1
          : numCompletedTrainingClassModules;
      },
      0,
    );

    return { numTotalModules, numCompleted };
  }

  static getPathAssignmentStarted(
    pathAssignment,
    trainingSeriesTrainingClasses,
    trainingClassSessionAttendeesByEventId,
  ) {
    if (pathAssignment.completedAt) {
      return true;
    }

    const assignments = [
      ...pathAssignment.challengeUserAssignments,
      ...pathAssignment.resourceUserAssignments,
      ...pathAssignment.courseUserAssignments,
      ...(isFeatureEnabled('top_level_content_paths') ? pathAssignment.assignments : []),
    ];

    const assignmentsStarted = assignments.some(
      (assignment) => assignment.isCompleted || assignment.isSubmitted || (+assignment.completionPercentage ?? 0) > 0,
    );

    if (assignmentsStarted) {
      return true;
    }

    const trainingSeriesAssignmentsStarted = Object.values(trainingSeriesTrainingClasses || []).some((trainingClass) =>
      trainingClassSessionAttendeesByEventId[trainingClass.trainingClassId]?.some((attendee) => {
        if (trainingClass.requireAttendanceForCompletion) {
          return attendee.registeredAndMarkedAttended;
        }

        return attendee.registeredAndSessionHasPassed;
      }),
    );

    return trainingSeriesAssignmentsStarted;
  }

  static showNewZiggeoRecorderOrPlayer(ziggeoFlipEnabled, existingVideoUrl, existingVideoProcessing, ziggeoToken) {
    if (!ziggeoFlipEnabled) {
      return false;
    }

    if (ziggeoToken) {
      return true;
    }

    if (existingVideoUrl || existingVideoProcessing) {
      return false;
    }

    return true;
  }

  static getTrainingNameFromAssignment(assignment) {
    let name = '';
    if (assignment.course) {
      name = assignment.course.displayTitle;
    } else if (assignment.trainingSeries) {
      name = assignment.trainingSeries.name;
    } else if (assignment.challenge) {
      name = assignment.challenge.name;
    } else if (assignment.topLevelContent) {
      name = assignment.topLevelContent.title;
    }
    return name;
  }

  static getAssignmentProgress(assignment, sessionAttendeesByEventId) {
    if (assignment.course) {
      return AssignmentUtils.getPercentCompletedForGuideAssignment(assignment);
    }
    if (assignment.trainingSeries) {
      return AssignmentUtils.getPercentCompletedForSeriesAssignment(
        assignment,
        assignment.trainingSeries.trainingSeriesTrainingClasses,
        sessionAttendeesByEventId,
      );
    }
    if (assignment.topLevelContent) {
      return assignment.completionPercentage;
    }
    return 0; // TODO (olivia) - No way to complete a challenge yet
  }
}
