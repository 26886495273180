import { ViewportUtils } from './common';

const AutoPosition = {
  calculateMenuPosition(label, alwaysDropDown = false) {
    const $label = $(label);

    let top;
    let right;
    let bottom;
    let left;
    const windowHeight = ViewportUtils.viewportHeight();
    const windowWidth = ViewportUtils.viewportWidth();

    const pixelOffset = 7;

    const labelHeight = $label.outerHeight();

    const labelOffset = label.getBoundingClientRect();
    const labelX = labelOffset.left;
    const labelY = labelOffset.top;

    if (labelX > windowWidth / 2) {
      right = '0px';
      left = 'auto';
    } else {
      right = 'auto';
      left = '0px';
    }

    if (labelY > windowHeight / 2) {
      top = 'auto';
      bottom = `${labelHeight + pixelOffset}px`;
    } else {
      top = `${labelHeight + pixelOffset}px`;
      bottom = 'auto';
    }

    if (alwaysDropDown) {
      top = `${labelHeight + pixelOffset}px`;
      bottom = 'auto';
    }

    return {
      top,
      bottom,
      left,
      right,
    };
  },
};
export default AutoPosition;
