import chroma from 'chroma-js';

export default class ColorUtils {
  // accepts hex as #fff or #ffffff
  static hexToRGBA(hex, alpha = 1) {
    if (!hex || [4, 7].indexOf(hex.length) === -1) {
      return; // throw new Error('Bad Hex');
    }

    hex = hex.substr(1);
    // if shortcuts (#F00) -> set to normal (#FF0000)
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((el) => `${el + el}`)
        .join('');
    }

    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  static hslaToHex(hsla) {
    const hsl = hsla.replace('hsla(', '').replace(')', '').split(',');
    const h = parseInt(hsl[0]);
    const s = parseInt(hsl[1].replace('%', '')) / 100;
    const l = parseInt(hsl[2].replace('%', '')) / 100;

    return chroma.hsl(h, s, l).hex();
  }

  static hexToRGB(hex) {
    let normalizedHex = hex.replace('#', '');

    if (normalizedHex.length === 3) {
      normalizedHex = normalizedHex
        .split('')
        .map((el) => `${el + el}`)
        .join('');
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(normalizedHex);

    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  static addAlphaToColor(color, alpha = 100) {
    if (color.startsWith('#')) {
      return this.hexToRGBA(color, alpha / 100);
    }

    if (color.startsWith('rgba')) {
      const colorArray = color.split(',');
      colorArray[3] = `${alpha / 100})`;
      return colorArray.join(',');
    }

    if (color.startsWith('rgb')) {
      return `${color.replace(')', `, ${alpha / 100})`)}`;
    }

    if (color.startsWith('hsla')) {
      const colorArray = color.split(',');
      colorArray[3] = `${alpha / 100})`;
      return colorArray.join(',');
    }

    if (color.startsWith('hsl')) {
      return `${color.replace(')', `, ${alpha / 100})`)}`;
    }

    return color;
  }

  static getTextColorForHex(hex) {
    const rgb = this.hexToRGB(hex);
    // algorithm to determine contrast between background and foreground: https://www.w3.org/TR/AERT/#color-contrast
    const o = Math.round((parseInt(rgb.r) * 299 + parseInt(rgb.g) * 587 + parseInt(rgb.b) * 114) / 1000);
    return o > 125 ? 'black' : 'white';
  }

  // Accepts: Color values in the form #FFF or #FFFFFF, and a number to add to it (0 to 255)
  static lightenDarkenColor(color, amt) {
    const withHash = color[0] == '#';
    const withoutHash = withHash ? color.substr(1) : color;
    const isShortHex = withoutHash.length == 3;
    const rgbAsStrings = isShortHex ? withoutHash.split('').map((c) => `${c}${c}`) : withoutHash.match(/.{2}/g);
    const rgbAsInts = rgbAsStrings.map((c) => parseInt(c, 16));
    const additionApplied = rgbAsInts.map((c) => Math.min(255, c + amt));

    return (
      (withHash ? '#' : '') +
      additionApplied.reduce((a, c) => `${a}${c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`}`, '')
    );
  }

  static isDark(color) {
    const rgb = this.hexToRGB(color);
    return rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 <= 125;
  }

  static lightenOrDarkenColor(color, amt) {
    const hexColor = chroma(color).hex();
    return this.isDark(hexColor) ? this.lightenDarkenColor(hexColor, amt) : this.lightenDarkenColor(hexColor, -amt);
  }

  /**
   * Blend the given RGBA color string with a specified opacity.
   *
   * @param {string} rgbaString - The RGBA color string, e.g., "rgba(255, 0, 0, 0.5)".
   * @param {number} opacity - The opacity value ranging from 0 to 100.
   * @returns {string} - The new RGBA color string with the adjusted opacity.
   */
  static getColorWithOpacity(color, opacity = 0) {
    if (!color) return null;

    const colorInRGB = chroma(color).css();

    return chroma(colorInRGB)
      .alpha(opacity / 100)
      .css();
  }

  /**
   * Determines whether color should be white or black based on the contrast with the color arg.
   * @param {string} color - The color in hexadecimal, RGB, or named format.
   * @returns {string} The color ('black' or 'white') that has higher contrast with the color arg.
   */
  static getContrastColor(color) {
    // Convert the background color to a Chroma.js color object
    const chromaColor = chroma(color);

    // Calculate the contrast ratio against black and white
    const blackContrast = chroma.contrast(chromaColor, 'black');
    const whiteContrast = chroma.contrast(chromaColor, 'white');

    // Determine which color (black or white) has higher contrast
    return blackContrast > whiteContrast ? 'black' : 'white';
  }
}
