import moment from 'moment';
import XDate from 'xdate';
import { roundToOneDecimal } from '../common';

const MINUTES = 'minutes';
const HOURS = 'hours';
const MS_PER_DAY = 1000 * 60 * 60 * 24; // Milliseconds in a day

export default class TimeUtils {
  static convertToSeconds(number, unit) {
    if (unit === MINUTES) {
      return Math.round(number * 60);
    }
    if (unit === HOURS) {
      return Math.round(number * 3600);
    }
  }

  static getDisplayTimeEstimate(seconds, longUnit = false) {
    const TRANSLATED_MINUTES_LONG = I18n.t('common.application.time_utils.minute.long', { count: 2 });
    const TRANSLATED_HOURS_LONG = I18n.t('common.application.time_utils.hour.long', { count: 2 });
    const timeInHours = seconds / 3600;
    const timeInMinutes = seconds / 60;
    const timeInHoursHasOnlyOneDecimal = parseFloat(timeInHours.toFixed(1)) === timeInHours;
    const timeInMinutesHasOnlyOneDecimal = parseFloat(timeInMinutes.toFixed(1)) === timeInMinutes;
    let useHours;
    let timeEstimateNumber;

    if (Number.isInteger(timeInHours) || (timeInHours > 1 && timeInHoursHasOnlyOneDecimal)) {
      timeEstimateNumber = timeInHours;
      useHours = true;
    } else if (Number.isInteger(timeInMinutes) || timeInMinutesHasOnlyOneDecimal) {
      timeEstimateNumber = timeInMinutes;
      useHours = false;
    } else if (timeInHours > 1) {
      useHours = true;
      timeEstimateNumber = roundToOneDecimal(timeInHours);
    } else {
      useHours = false;
      timeEstimateNumber = roundToOneDecimal(timeInMinutes);
    }

    return {
      timeEstimateNumber,
      timeEstimateUnit: useHours ? TRANSLATED_HOURS_LONG : TRANSLATED_MINUTES_LONG,
      timeEstimateDisplayNumber: TimeUtils.getTimeEstimateDisplayNumber(seconds, longUnit),
    };
  }

  static getTimeEstimateDisplayNumber(seconds, longUnit = false) {
    let unit;
    let quarterHours;
    let roundedHours;
    let roundedQuarterHours;
    const minutes = seconds / 60;

    if (seconds < 60) {
      const TRANSLATED_SECONDS_LONG = I18n.t('common.application.time_utils.second.long', { count: seconds });
      const TRANSLATED_SECONDS_SHORT = I18n.t('common.application.time_utils.second.short');
      unit = longUnit ? TRANSLATED_SECONDS_LONG : TRANSLATED_SECONDS_SHORT;
      roundedHours = seconds;
    } else if (minutes < 60) {
      const TRANSLATED_MINUTES_LONG = I18n.t('common.application.time_utils.minute.long', { count: minutes });
      const TRANSLATED_MINUTES_SHORT = I18n.t('common.application.time_utils.minute.short');
      unit = longUnit ? TRANSLATED_MINUTES_LONG : TRANSLATED_MINUTES_SHORT;
      roundedHours = Math.floor(minutes);
    } else {
      quarterHours = minutes / 15;

      if (minutes % 15 > 7) {
        roundedQuarterHours = Math.ceil(quarterHours);
      } else {
        roundedQuarterHours = Math.floor(quarterHours);
      }

      roundedHours = roundedQuarterHours / 4;

      const countForTranslation = minutes / 60 === 1 ? 1 : 2;
      const TRANSLATED_HOURS_LONG = I18n.t('common.application.time_utils.hour.long', { count: countForTranslation });
      const TRANSLATED_HOURS_SHORT = I18n.t('common.application.time_utils.hour.short');
      unit = longUnit ? TRANSLATED_HOURS_LONG : TRANSLATED_HOURS_SHORT;
    }
    const TRANSLATED_DURATION = longUnit
      ? I18n.t('common.application.time_utils.time_duration', {
          duration: roundedHours,
          unit,
        })
      : I18n.t('common.application.time_utils.time_duration_no_space', {
          duration: roundedHours,
          unit,
        });
    return (roundedQuarterHours !== quarterHours ? '~' : '') + TRANSLATED_DURATION;
  }

  static displayDurationShort(seconds) {
    const { timeEstimateNumber, timeEstimateUnit, timeEstimateDisplayNumber } = TimeUtils.getDisplayTimeEstimate(
      seconds,
      false,
    );
    return timeEstimateDisplayNumber;
  }

  static displayDurationMedium(seconds) {
    const { timeEstimateNumber, timeEstimateUnit, timeEstimateDisplayNumber } = TimeUtils.getDisplayTimeEstimate(
      seconds,
      false,
    );
    const TRANSLATED_HOURS_LONG = I18n.t('common.application.time_utils.hour.long', { count: 2 });

    let unit = '';
    if (timeEstimateUnit === TRANSLATED_HOURS_LONG) {
      unit = I18n.t('common.application.time_utils.hour.medium', { count: timeEstimateNumber });
    } else {
      unit = I18n.t('common.application.time_utils.minute.medium', { count: timeEstimateNumber });
    }

    return I18n.t('common.application.time_utils.time_duration', { duration: timeEstimateNumber, unit });
  }

  static displayDurationLong(seconds) {
    const { timeEstimateNumber, timeEstimateUnit, timeEstimateDisplayNumber } = TimeUtils.getDisplayTimeEstimate(
      seconds,
      true,
    );
    return timeEstimateDisplayNumber;
  }

  static hasDatePassed(date = null) {
    if (date) {
      const curDate = new XDate();
      const dueDay = new XDate(date, true).toString('MM/dd/yyyy');

      return new XDate(dueDay).addDays(1) < curDate;
    }
    return false;
  }

  static closestDateTimeForMinuteInterval(dateTimeMoment, minuteInterval = 30) {
    const remainder = minuteInterval - (dateTimeMoment.minutes() % minuteInterval);
    return moment(dateTimeMoment).add(remainder, 'minutes');
  }

  static timePickerStartMinTime = (momentDatetimeStart, withFormatting = true) => {
    const tzId = momentDatetimeStart.tz();
    const minTime = moment.max(
      moment(momentDatetimeStart).startOf('day'),
      TimeUtils.closestDateTimeForMinuteInterval(moment(new Date()).tz(tzId), 30),
    );

    return withFormatting ? minTime.format('hh:mma') : minTime;
  };

  static timePickerEndMinTime = ({
    momentDatetimeStart,
    momentDatetimeEnd,
    endTimeBufferInMinutes = 0,
    withFormatting = true,
  }) => {
    const minStartTime = moment(TimeUtils.timePickerStartMinTime(momentDatetimeStart, false));
    const minTime = moment.max(
      minStartTime.add(endTimeBufferInMinutes, 'minutes'),
      moment(momentDatetimeEnd).startOf('day'),
    );

    return withFormatting ? minTime.format('hh:mma') : minTime;
  };

  static lastNumDaysSinceMidnight = (numDays) => ({
    start: TimeUtils.toStartOfDay(moment().subtract(numDays, 'days')),
    end: TimeUtils.toEndOfDay(moment().subtract(1, 'days')),
  });

  static toStartOfDay = (momentObj) => {
    if (momentObj == null) return null;

    return moment(momentObj).startOf('day');
  };

  static toMiddleOfDay = (momentObj) => {
    if (momentObj == null) return null;

    return TimeUtils.toStartOfDay(momentObj).add(12, 'hours');
  };

  static toEndOfDay = (momentObj) => {
    if (momentObj == null) return null;

    return moment(momentObj).endOf('day');
  };

  static isWeekend = (dayOfWeek) => dayOfWeek === 6 || dayOfWeek === 0;

  static numDaysBetween = ({ start, end, includeStart }) => {
    const numDays = end.diff(start, 'days');
    return includeStart ? numDays + 1 : numDays;
  };

  /**
   * Return the difference between two JS dates in days
   */
  static dateDiffInDays = (jsDateA, jsDateB) => {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(jsDateA.getFullYear(), jsDateA.getMonth(), jsDateA.getDate());
    const utc2 = Date.UTC(jsDateB.getFullYear(), jsDateB.getMonth(), jsDateB.getDate());

    return Math.floor((utc2 - utc1) / MS_PER_DAY);
  };

  static jsDateToUtc = (jsDate) => {
    const utcDate = new Date(jsDate.getTime() + jsDate.getTimezoneOffset() * 60000);
    return utcDate;
  };
}
