export default function MoreOptionsIcon({ fill }) {
  return (
    <svg width="18px" height="4px" viewBox="0 0 18 4" version="1.1">
      <g id="More Options Icon" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g id="more_options_icon" fillRule="nonzero" fill={fill}>
          <path
            d="M2,4 C0.897081413,4 0,3.10322628 0,2 C0,0.897358987 0.897081413,0 2,0 C3.10291859,0 4,0.897310215 4,2 C3.99995123,3.10322628 3.10291859,4 2,4 Z"
            id="Shape"
          />
          <path
            d="M9,4 C7.89708141,4 7,3.10322628 7,2 C7,0.897358987 7.89708141,0 9,0 C10.1027235,0 11,0.897310215 11,2 C10.9999512,3.10322628 10.1027235,4 9,4 Z"
            id="Shape"
          />
          <path
            d="M16,4 C14.8972765,4 14,3.10322628 14,2 C14,0.897358987 14.8972277,0 16,0 C17.1027723,0 18,0.897310215 18,2 C18,3.10322628 17.1027723,4 16,4 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
}
MoreOptionsIcon.defaultProps = {
  fill: '#3b3b3b',
};
window.MoreOptionsIcon = MoreOptionsIcon;
