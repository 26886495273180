export enum ReportType {
  GROUPS = 'groups',
  GUIDES = 'guides',
  CHALLENGES = 'challenges',
  SCORM = 'scorm',
  SERIES = 'series',
  USERS = 'users',
  GUIDE_SUBMISSIONS = 'guide_submissions',
  CHALLENGE_SUBMISSIONS = 'challenge_submissions',
  BADGES = 'badges',
  EVENTS = 'events',
  RESOURCES = 'resources',
  LIBRARIES = 'libraries',
  TRAININGS = 'trainings',
  GUIDE_SUBMISSIONS_NEW = 'guide_submissions_new',
}
