function CompletedIcon({ width, height, fill }) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 18" version="1.1">
      <desc>Created with Sketch.</desc>
      <defs>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="3.061617e-15%" id="linearGradient-1">
          <stop stopColor={fill} offset="0%" />
          <stop stopColor="#fff" offset="100%" />
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="HOME" transform="translate(-482.000000, -958.000000)" fill="url(#linearGradient-1)">
          <g id="2.-main" transform="translate(478.000000, 35.000000)">
            <g id="cards" transform="translate(1.000000, 99.000000)">
              <g id="class-(completed)" transform="translate(0.000000, 555.000000)">
                <path
                  d="M20.8847257,269.673619 L9.04992307,281.408935 L5.86708127,277.736251 C4.46461177,276.561983 2.25744998,277.93016 3.26101565,279.690455 L7.04507697,286.100909 C7.64398232,286.883728 9.04992307,287.665852 10.4547067,286.100909 C11.0547402,285.318063 22.4883538,271.432471 22.4883538,271.432471 C23.8931375,269.867528 22.0871647,268.499377 20.8847257,269.672177 L20.8847257,269.673619 Z"
                  id="Shape-2"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

CompletedIcon.defaultProps = {
  fill: '#1D9EEF',
  width: '20px',
  height: '18px',
};

export default CompletedIcon;
