/* eslint-disable max-classes-per-file */
import { Component } from 'react';
import ReactDOM from 'react-dom';
import TimeAgo from '../components/common/time-ago';
import { apiGet } from '../common';
import { RedactorVersions } from '~/components/common/RedactorVersions';

(function($R) {
  $R.add('plugin', 'versioning', {
    modals: {
      'version-history' : '<div class="redactor-versioning"></div>',
    },
    onmodal: {
      'version-history' : {
          open: function ($modal) {
            this.component = ReactDOM.render(<RedactorVersions id={this.versioningId} />, $modal.getBody().nodes[0]);
          },
          closed: function ($modal) {
            // clean up react component
            ReactDOM.unmountComponentAtNode($modal.getBody().nodes[0]);
          },
          restoreVersion: function($modal) {
            // update redactor field to currently selected version
            var state = this.component.state;
            if (state.current_version) {
              var content = state.version_content[state.current_version.url];
              if (content) {
                this.app.api('source.setCode', content);
                this.app.api('module.modal.close');
              }
              else {
                // TODO: wrmodal is behind this modal. need to notify user they need to wait for selecte version to load
              }
            }
            else {
              this.app.api('module.modal.close');
            }
          }
      }
    },
    translations: {
      en: {
        "versioning" : "Version History",
        "save-version": "Save Version",
        "see-versions": "See Versions",
      }
    },
    init: function(app)
    {
      this.app = app;
      this.lang = app.lang;
      this.block = app.block;
      this.toolbar = app.toolbar;
      //  this id is used to retrieve versions from the versioning api
      this.versioningId = app.opts.versioningId;
    },
    // public
    start: function()
    {
      var dropdown = {};

      var $button = this.toolbar.addButton('versioning', { title: this.lang.get('versioning'), api: 'plugin.versioning.open' });
      $button.setIcon('<img src="/images/version_history.svg" style="height:1em" />');
    },
    open: function() {
      var options = {
          title: 'Version History', // the modal title
          name: 'version-history', // the modal variable in modals object
          width: '80vw',
          commands: {
              restoreVersion: { title: 'Restore This Version' },
              cancel: { title: 'Cancel' }
          }
      };

      // open the modal with API
      this.app.api('module.modal.build', options);
    },
  });
})(Redactor);
