import { Component } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';

/** @deprecated prefer `import { DatePicker } from '@workramp/ui';` */
export default class DatePicker extends Component {
  componentDidMount() {
    this.addDateHandler();
  }

  render() {
    return (
      <input
        ref={(dateInput) => {
          this.dateInput = dateInput;
        }}
        type="text"
        className="date-picker-input form-input-text pvs prs fss1"
        placeholder={this.props.placeholder}
        value={this.props.date ? this.formatDateForDatePicker(this.props.date) : ''}
        data-date-orientation={this.props.orientation ?? 'auto'}
        readOnly
      />
    );
  }

  addDateHandler() {
    const node = $(this.dateInput);
    $(node)
      .datepicker({
        autoclose: true,
        daysOfWeekDisabled: this.props.daysOfWeekDisabled || [],
        todayHighlight: true,
        weekStart: this.props.weekStart || 0,
        clearBtn: this.props.clearBtn,
        container: this.props.container,
      })
      .on('changeDate', (e) => {
        this.parseAndUpdateDateExternally(e.date, node);
      })
      .on('clearDate', () => this.props.onClearDate?.());
  }

  formatDateForDatePicker(intDate) {
    return new XDate(intDate, true).toString('MM/dd/yyyy');
  }

  parseAndUpdateDateExternally(date, $node) {
    const newDate = new XDate(date).setUTCMode(true, true);
    this.props.onDateUpdate(newDate.getTime());
  }
}

DatePicker.propTypes = {
  date: PropTypes.number, // Integer for date value
  onDateUpdate: PropTypes.func.isRequired, // Function to call on value update. Takes date as integer value.
  placeholder: PropTypes.string,
};

window.DatePicker = DatePicker;
