import type { AssignmentFilterOptions, AssignmentSortOptions, EventSessionFilterOptions } from '../types';
import isFeatureEnabled from '~/components/common/is-feature-enabled';

export const SIDEBAR_WIDTH = 256;
export const HEADER_HEIGHT = 64;

export const LEARNER_ROUTES = {
  admin: '/admin',
  home: '/guides',
  profile: '/profile',
  settings: '/settings',
  events: '/events',
  review: '/reviewer_home',
  libraries: isFeatureEnabled('fy24_q2_library_learner_view') ? '/libraries' : '/universities',
  development: '/development',
  developmentSearch: '/development/search',
  portals: '/knowledge',
  logout: '/logout',
  companyDirectory: '/company/directory',
  companyLeaderboards: '/company/leaderboards',
  managerDashboard: '/managers',
  managerDashboardTeam: '/managers/team',
  managerDashboardProfile: '/managers/profile',
  managerDashboardTraining: '/managers/training',
  help: 'https://help.workramp.com/',
  search: '/advanced_search',
  savedContent: '/bookmarks',
};

export const PLACEHOLDER_LOGO_URL = '/images/logo_new.png';

export const PASSWORD_VALIDATION = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

export const SETTINGS_API_ROOT = 'settings';
export const USERS_API_ROOT = 'users';
export const PROFILE_API_ROOT = 'profile';

export const SORT_OPTIONS: Record<AssignmentSortOptions, AssignmentSortOptions> = {
  recently_assigned: 'recently_assigned',
  date: 'date',
  name: 'name',
  progress: 'progress',
};

export const ASSIGNMENT_FILTER_OPTIONS: Record<AssignmentFilterOptions, AssignmentFilterOptions> = {
  in_progress: 'in_progress',
  overdue: 'overdue',
  completed: 'completed',
  archived: 'archived',
};

export const EVENT_SESSION_FILTER_OPTIONS: Record<EventSessionFilterOptions, EventSessionFilterOptions> = {
  past_events: 'past_events',
  view_all: 'view_all',
};

export const LEADERBOARD_TABLE_ROWS = 10;
