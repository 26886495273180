import type { IconNames } from '@workramp/ui';
import AssetPickerTypes from '~/components/common/asset-picker/asset-picker-types';
import { ReportType } from '~/types/reports';

export const ICON_MAP: Record<string, IconNames> = {
  [AssetPickerTypes.CHALLENGE]: 'challenge:regular',
  [AssetPickerTypes.CLASS]: 'event:regular',
  [AssetPickerTypes.EVENT]: 'event:regular',
  [AssetPickerTypes.GO1_CONTENT]: 'search:regular',
  [AssetPickerTypes.GUIDE]: 'guide:regular',
  [AssetPickerTypes.LIBRARY]: 'library:regular',
  [AssetPickerTypes.PATH]: 'path:regular',
  [AssetPickerTypes.RESOURCE]: 'resource:regular',
  [AssetPickerTypes.EVENT_TEMPLATE]: 'schedule-template:regular',
  [AssetPickerTypes.SCORM]: 'scorm:regular',
  [AssetPickerTypes.SERIES]: 'path:regular',
  [AssetPickerTypes.UNIVERSITY]: 'library:regular',
  [ReportType.BADGES]: 'badge:regular',
  [ReportType.CHALLENGES]: 'challenge:regular',
  [ReportType.CHALLENGE_SUBMISSIONS]: 'textblock:regular',
  [ReportType.EVENTS]: 'event:regular',
  [ReportType.GROUPS]: 'group:regular',
  [ReportType.GUIDES]: 'guide:regular',
  [ReportType.GUIDE_SUBMISSIONS]: 'textblock:regular',
  [ReportType.LIBRARIES]: 'library:regular',
  [ReportType.RESOURCES]: 'resource:regular',
  [ReportType.SERIES]: 'path:regular',
  [ReportType.GUIDE_SUBMISSIONS_NEW]: 'textblock:regular',
  [ReportType.TRAININGS]: 'guide:regular',
  [ReportType.USERS]: 'profile:regular',
};

export const ICON_MAP_DUOTONE: Record<string, IconNames> = {
  [AssetPickerTypes.CHALLENGE]: 'challenge:duotone',
  [AssetPickerTypes.CLASS]: 'event:duotone',
  [AssetPickerTypes.EVENT]: 'event:duotone',
  [AssetPickerTypes.GO1_CONTENT]: 'content:duotone',
  [AssetPickerTypes.GUIDE]: 'guide:duotone',
  [AssetPickerTypes.LIBRARY]: 'library:duotone',
  [AssetPickerTypes.PATH]: 'path:duotone',
  [AssetPickerTypes.RESOURCE]: 'resource:duotone',
  [AssetPickerTypes.EVENT_TEMPLATE]: 'schedule-template:duotone',
  [AssetPickerTypes.SCORM]: 'scorm:duotone',
  [AssetPickerTypes.SERIES]: 'path:duotone',
  [AssetPickerTypes.UNIVERSITY]: 'library:duotone',
  [ReportType.CHALLENGES]: 'challenge:duotone',
  [ReportType.EVENTS]: 'event:duotone',
  [ReportType.GUIDES]: 'guide:duotone',
  [ReportType.LIBRARIES]: 'library:duotone',
  [ReportType.RESOURCES]: 'resource:duotone',
  [ReportType.SERIES]: 'path:duotone',
  [ReportType.TRAININGS]: 'guide:duotone',
};
