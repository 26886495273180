import { Component } from 'react';
import { apiGet } from '~/common';
import { RedactorVersionViewer } from './RedactorVersionViewer';
import { EditTime } from './EditTime';

export class RedactorVersions extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      versions: [],
      loading: true,
      loadError: false,
      version_content: {},
    });
    //  Get URLs to request versions and time version was created
    this.loadVersions();
  }

  componentDidUpdate() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { current_version, version_content } = this.state;
    const { onChange } = this.props;

    onChange?.(version_content?.[current_version?.url]);

    if (current_version && !version_content[current_version.url]) {
      this.loadVersionContent(current_version);
    }
  }

  versionsLoaded(versions) {
    this.setState({
      loading: false,
      versions,
      current_version: versions[0],
    });
  }

  loadVersions() {
    const { id } = this.props;

    apiGet(
      `versions/${id}`,
      null,
      (versions) => this.versionsLoaded(versions.list),
      (error) => this.errorLoadingVersions(error),
    );
  }

  loadVersionContent(version) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { version_content } = this.state;

    if (version_content[version.url] === undefined) {
      window.$.get(version.url).done((content) => {
        this.setState({
          version_content: {
            ...version_content,
            [version.url]: content,
          },
        });
      });
    }
  }

  errorLoadingVersions(error) {
    this.setState({
      loading: false,
      loadError: error,
    });
  }

  viewableVersions() {
    const { versions } = this.state;

    const oneMinute = 1000 * 60;
    const oneHour = oneMinute * 60;
    const oneDay = oneHour * 24;
    const slotsTaken = {};

    return versions.filter((version) => {
      const editTime = new Date(version.time).getTime();
      const timeAgo = Date.now() - editTime;
      let slot;
      // show most recent update per day for updates older than a day
      //             ''          per hour            ''         an hour
      //             ''          per 5 minutes       ''         a minute
      if (timeAgo > oneDay) slot = parseInt(editTime / oneDay, 10);
      else if (timeAgo > oneHour) slot = parseInt(editTime / oneHour, 10);
      else if (timeAgo > oneMinute) slot = parseInt(editTime / (5 * oneMinute), 10);

      // show only most recent update for updates younger than 1 minute
      const alreadyTaken = slotsTaken[slot];
      if (!alreadyTaken) slotsTaken[slot] = true;
      return !alreadyTaken;
    });
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { loading, loadError, versions, current_version, version_content } = this.state;

    const NO_PREVIOUS_VERSIONS = 'No Previous Versions Available';

    let content;
    if (loading) {
      content = <div className="redactor-versioning-loading">loading...</div>;
    } else if (loadError) {
      content = <div className="redactor-versioning-load-error">{JSON.stringify(loadError)}</div>;
    } else if (versions.length === 0) {
      content = <div className="redactor-versioning-no-versions">{NO_PREVIOUS_VERSIONS}</div>;
    } else {
      content = (
        <div className="redactor-versioning-versions">
          <div className="redactor-versioning-version-viewer">
            <RedactorVersionViewer id={current_version.url} content={version_content[current_version.url]} />
          </div>
          <div className="redactor-versioning-version-list">
            {this.viewableVersions().map((version) => (
              <div
                key={version.url}
                className={`redactor-versioning-version-list-item${version === current_version ? ' selected' : ''}`}
                onClick={() => this.setState({ current_version: version })}
                role="button"
                tabIndex={0}
                onKeyDown={() => this.setState({ current_version: version })}
              >
                <div>
                  <EditTime time={version.time} />
                </div>
                <div className="redactor-versioning-version-updater">{version.updater.displayName}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return <div className="redactor-versioning">{content}</div>;
  }
}
