import moment from 'moment';
import 'moment-timezone';
import XDate from 'xdate';

import { tzAbbreviation } from '~/common/timezone-utils';

const WRDates = {
  getMoment(date, timezoneId) {
    const zoneId = timezoneId == null ? moment.tz.guess() : timezoneId;
    return moment(date).tz(zoneId);
  },

  getStartOfDayToday() {
    return this.getMoment(Date.now()).startOf('day');
  },

  getFormattedDateRange(start, end) {
    const startMoment = this.getMoment(start);
    const endMoment = this.getMoment(end);

    if (startMoment.isSame(endMoment, 'day')) {
      return `${this.getStandardFormat(start)} - ${this.getTimeFormat(end)}`;
    }
    return `${this.getStandardFormat(start)} - ${this.getStandardFormat(end)}`;
  },

  getFormattedDateRangeInLocalTime(start, end, dateTimeFormatKey = null) {
    const startMoment = this.getMoment(start);
    const endMoment = this.getMoment(end);
    let startTimeInDateTimeFormat;
    let endTimeInDateTimeFormat;
    if (dateTimeFormatKey) {
      startTimeInDateTimeFormat = this.getStandardFormatInLocalTime(start, dateTimeFormatKey);
      endTimeInDateTimeFormat = this.getStandardFormatInLocalTime(end, dateTimeFormatKey);
    } else {
      startTimeInDateTimeFormat = this.getStandardFormatInLocalTime(start);
      endTimeInDateTimeFormat = this.getStandardFormatInLocalTime(end);
    }

    if (startMoment.isSame(endMoment, 'day')) {
      return `${startTimeInDateTimeFormat} - ${this.getTimeFormatInLocalTime(end)}`;
    }
    return `${startTimeInDateTimeFormat} - ${endTimeInDateTimeFormat}`;
  },

  getFormattedDateRangeInTzTime(start, end, dateTimeFormatKey = null, timezoneId) {
    // NOTE: Consider requiring timezoneId.... defaulting to browsers timezone if timezoneId is null for the time being
    const tzId = timezoneId == null ? moment.tz.guess() : timezoneId;
    const startMoment = this.getMoment(start, tzId);
    const endMoment = this.getMoment(end, tzId);

    const startTimeInDateTimeFormat = this._i18nTimeStringFormatted(
      // startMoment.format(), -- this is the original code, but it formats in the wrong timezone
      startMoment.format('YYYY-MM-DDTHH:mm:ss'),
      dateTimeFormatKey || 'long_alt_1',
    );

    const endTimeInDateTimeFormatKey = startMoment.isSame(endMoment, 'day')
      ? 'time_only_long'
      : dateTimeFormatKey || 'long_alt_1';

    const endTimeInDateTimeFormat = this._i18nTimeStringFormatted(
      endMoment.format('YYYY-MM-DDTHH:mm:ss'),
      endTimeInDateTimeFormatKey,
    );

    return `${startTimeInDateTimeFormat}-${endTimeInDateTimeFormat} ${tzAbbreviation(tzId)}`;
  },

  getDayFormat(date, formatKey = 'long') {
    return this._i18nDateFormatted(date, formatKey, true);
  },

  getDayFormatInLocalTime(date, formatKey = 'long') {
    return this._i18nDateFormatted(date, formatKey, false);
  },

  getStandardFormat(date, formatKey = 'long') {
    return this._i18nTimeFormatted(date, formatKey, true);
  },

  getStandardFormatInLocalTime(date, formatKey = 'long') {
    return this._i18nTimeFormatted(date, formatKey, false);
  },

  getTimeFormat(date, formatKey = 'time_only_long') {
    return this._i18nTimeFormatted(date, formatKey, true);
  },

  getTimeFormatInLocalTime(date, formatKey = 'time_only_long') {
    return this._i18nTimeFormatted(date, formatKey, false);
  },

  getTimeFormatInLocalTimeWithTZLabel(date, formatKey) {
    return this.getTimeFormatInLocalTime(date, formatKey) + this._withTZLabel();
  },

  getStandardFormatInLocalTimeWithTZLabel(date, formatKey) {
    return this.getStandardFormatInLocalTime(date, formatKey) + this._withTZLabel();
  },

  /**
   *
   * @param { start time in milliseconds since the Epoch } startTime
   * @param { end time in milliseconds since the Epoch } endTime
   */
  getFormattedDateRangeInLocalTimeWithTZLabel(startTime, endTime, formatKey = null) {
    return this.getFormattedDateRangeInLocalTime(startTime, endTime, formatKey) + this._withTZLabel();
  },

  /**
   * Helper method that returns i18n translated time
   * @param date
   * @param formatKey: See translation files (i.e. en_use.yml)
   * @param utcMode: Reference: https://arshaw.com/xdate/ --> UTC Mode
   * @returns { string } : A formatted time
   * @private
   */
  _i18nTimeFormatted: (date, formatKey, utcMode) => I18n.l(`time.formats.${formatKey}`, new XDate(date, utcMode)),

  // NOTE: Use _i18nTimeFormatted instead if possible.
  // This method was added to support showing time in timezone different than the browsers timezone
  _i18nTimeStringFormatted: (dateString, formatKey) => I18n.l(`time.formats.${formatKey}`, dateString),

  /**
   * Helper method that returns i18n translated date
   * @param date
   * @param formatKey: See translation files (i.e. en_use.yml)
   * @param utcMode: Reference: https://arshaw.com/xdate/ --> UTC Mode
   * @returns { string } : A formatted date
   * @private
   */
  _i18nDateFormatted: (date, formatKey, utcMode) => I18n.l(`date.formats.${formatKey}`, new XDate(date, utcMode)),

  _withTZLabel: (tzFormat = 'z') => ` ${moment.tz(moment.tz.guess()).format(tzFormat)}`,
};

window.WRDates = WRDates;
export default WRDates;
