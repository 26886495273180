import _ from 'underscore';
import GlobalIcon from '../components/icons/global-icon';
import LockIcon from '../components/icons/lock-icon';

class KnowledgePortalPermissions {
  // Permission Levels
  static get NO_ACCESS() {
    const obj = {
      code: -1,
      name: 'No Access',
      color: 'hsla(168, 3%, 44%, 1)',
    };
    obj.description = <span>Users with {this.getFormattedName(obj)} cannot view or edit content in this portal.</span>;
    return obj;
  }

  static get READ() {
    const obj = {
      code: 0,
      name: 'Viewer',
      color: '#AB3694',
    };
    obj.description = (
      <span>
        {this.getFormattedName(obj, true)} can view and comment within this portal, but cannot edit or delete.
      </span>
    );
    return obj;
  }

  static get WRITE() {
    const obj = {
      code: 1,
      name: 'Editor',
      color: '#3694AB',
    };
    obj.description = (
      <span>{this.getFormattedName(obj, true)} can view, edit, and delete anything in the portal.</span>
    );
    return obj;
  }

  static get ADMIN() {
    const obj = {
      code: 2,
      name: 'Admin',
      color: '#36AB94',
    };
    obj.description = (
      <span>
        {this.getFormattedName(obj, true)} have all the same privileges as {this.getFormattedName(this.WRITE, true)},
        but can also flag content as recommended, and permanently delete items.
      </span>
    );
    return obj;
  }

  static get PUBLIC() {
    const obj = {
      private: false,
      color: '#646464',
      name: 'Public',
      icon: <GlobalIcon fill="#FFFFFF" />,
    };

    obj.description = (
      <span>
        {this.getFormattedName(obj, false)} portals are accessible to all users in your organization. They are
        recommended for company wide knowledge and information.
      </span>
    );
    return obj;
  }

  static get PRIVATE() {
    const obj = {
      private: true,
      color: '#152944',
      name: 'Private',
      icon: <LockIcon fill="#FFFFFF" />,
    };

    obj.description = (
      <span>
        {this.getFormattedName(obj, false)} portals are only accessible to users and groups specifically added below.
        They are recommended for more sensitive sharing between teams and departments.
      </span>
    );
    return obj;
  }

  static get permissionLevels() {
    return [this.READ, this.WRITE, this.ADMIN];
  }

  static get portalTypes() {
    return [this.PRIVATE, this.PUBLIC];
  }

  static hasAccess(code) {
    return code > this.NO_ACCESS.code;
  }

  static isReader(code) {
    return code >= this.READ.code;
  }

  static isAuthor(code) {
    return code >= this.WRITE.code;
  }

  static isAdmin(code) {
    return code >= this.ADMIN.code;
  }

  static getLevelByCode(code) {
    const levels = this.permissionLevels;
    return _.find(levels, (level) => level.code === code);
  }

  static getFormattedName(level, plural) {
    return (
      <span className="font-ws" style={{ color: level.color }}>
        {level.name}
        {plural ? 's' : ''}
      </span>
    );
  }
}
window.KnowledgePortalPermissions = KnowledgePortalPermissions;
