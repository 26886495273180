import DOMPurify from 'dompurify';

const DOMPurifyOptions = {
  ADD_ATTR: APP_CONSTANTS.DEFAULT_DOMPURIFY_OPTIONS.ADD_ATTR,
  ADD_TAGS: APP_CONSTANTS.DEFAULT_DOMPURIFY_OPTIONS.ADD_TAGS,
};

export const WRDOMPurify = {
  sanitize(text, options = DOMPurifyOptions) {
    return DOMPurify.sanitize(text, options);
  },
};

window.WRDOMPurify = WRDOMPurify;
