import _ from 'underscore';
import { Component } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import AddTagsModal from '~/components/common/add-tags-modal';
import { theme } from '../../styles';
import ObjectTagsApi from '../../agent/object_tags';
import TagUtils from '../../training/tag-utils';
import isFeatureEnabled from '../common/is-feature-enabled';
import { GuideSidebarIcon } from '../task/GuideSidebarIcon';

export default class ObjectTagsIcon extends Component {
  TRANSLATED_TAGS_TYPE_DISPLAY_NAME = I18n.t('common.content.tag', { count: 2 });

  constructor(props) {
    super(props);

    this.state = {
      tags: this.props.objectTags || null,
    };
  }

  componentDidMount() {
    if (!this.state.tags) {
      ObjectTagsApi.getForObject(this.props.objectId, this.props.objectType, getUrlParameters().series_id).then(
        (tags) => this.setState({ tags }),
      );
    }
  }

  render() {
    const { viewOnly } = this.props;

    const askATeammateEnabled = isFeatureEnabled('fy23_q4_ask_a_teammate');

    if (!this.state.tags) return null;

    if (viewOnly && _.isEmpty(this.state.tags)) return null;

    return (
      <TagsContent
        data-tip
        data-for={`${this.props.objectId}tags`}
        className={this.props.className}
        onClick={viewOnly ? null : this.openEditTagsModal}
        tagNumberColor={this.props.tagNumberStyles?.color}
        tagNumberFontSize={this.props.tagNumberStyles?.fontSize}
        viewOnly={viewOnly}
        $newStyles={askATeammateEnabled}
        aria-label="Tags"
      >
        <GuideSidebarIcon name="tag:solid" aria-hidden="true" />
        {TagUtils.textForTagIcon(this.state.tags)}
        {!viewOnly && (
          <Pencil
            src={this.props.editIconSrc || '/images/edit_icon_grey.svg'}
            width={this.props.tagIconWidth || '12px'}
            height={this.props.tagIconWidth || '12px'}
          />
        )}
        {!_.isEmpty(this.state.tags) && this.tagToolTip()}
      </TagsContent>
    );
  }

  tagToolTip = () => (
    <ReactTooltip
      id={`${this.props.objectId}tags`}
      effect="solid"
      className="react-tooltip-series-card-content"
      place={this.props.toolTipRight ? 'right' : 'left'}
    >
      <TooltipSubtitle>{this.TRANSLATED_TAGS_TYPE_DISPLAY_NAME}</TooltipSubtitle>
      {this.tagTitlesForTooltip()}
    </ReactTooltip>
  );

  tagTitlesForTooltip = () => _.map(this.state.tags, (tag) => <div key={tag}>{tag}</div>);

  openEditTagsModal = () => {
    WRModals.custom(
      AddTagsModal,
      {
        autoCompleteUrl: '/api/object_tags/search',
        deleteUrl: 'object_tags/',
        forceSelectFromSuggestions: getCurrentUser().enterprise.restrictObjectTagCreation,
        isObjectTag: true,
        noCommasAllowed: true,
        objectData: { object_id: this.props.objectId, object_type: this.props.objectType },
        onDelete: (tags) => this.setState({ tags }),
        saveUrl: 'object_tags/',
        showExistingTags: true,
        tags: this.state.tags,
      },
      (tags) => this.setState({ tags }),
    );
  };
}

const Pencil = styled.img`
  ${theme.editPencilIcon.context}
`;

const TagsContent = styled.div`
  ${(props) => (props.viewOnly ? theme.contentDetailWrapper.context : theme.editableContentDetailWrapper.context)}

  ${({ $newStyles, theme: mainTheme }) =>
    $newStyles &&
    `
      color: ${mainTheme.colors.secondary[600]};
      font-weight: ${mainTheme.typography.fontWeight.medium};
    `}

  ${(props) => props.tagNumberFontSize && `font-size: ${props.tagNumberFontSize};`}
  ${(props) => props.tagNumberColor && `color: ${props.tagNumberColor};`}

  &:hover ${Pencil} {
    opacity: 1;
  }
`;

const TooltipSubtitle = styled.div`
  color: ${theme.color.textDescription};
`;
