import _ from 'underscore';
import { Component, createRef } from 'react';
import { MODAL_BASE_CLASS } from './modal';

class ClickOff extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDOMClick, { capture: true });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDOMClick, { capture: true });
  }

  isNotAModal(target) {
    let node = target;
    const parents = [];
    while (node) {
      parents.unshift(node);
      node = node.parentNode;
    }
    const modalClass = MODAL_BASE_CLASS;
    return !_.any(parents, (parent) => parent.className && parent.className.indexOf(modalClass) > -1);
  }

  render() {
    return (
      <div className="click-off" ref={this.ref}>
        {this.props.children}
      </div>
    );
  }

  handleDOMClick = (e) => {
    const { target } = e;
    if (target.isConnected && this.ref.current && !this.ref.current.contains(target)) {
      if (!this.props.ignoreModals || this.isNotAModal(target)) {
        this.props.onDOMClick(e);
      }
    }
  };
}

window.ClickOff = ClickOff;
export default ClickOff;
