import type { ReactNode } from 'react';
import { StyledList, Item } from './IconList.styles';
import { IconListIcon } from './IconListIcon';
import { IconListText } from './IconListText';

export interface IconListProps {
  children: ReactNode;
}

export const IconList = ({ children }: IconListProps) => <StyledList>{children}</StyledList>;

IconList.Item = Item;
IconList.Text = IconListText;
IconList.Icon = IconListIcon;
