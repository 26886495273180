import _ from 'underscore';
import { Component } from 'react';
import { algoliaV3 } from '~/lib/algoliasearch-v3';
import AlgoliaEnvironment from '~/algolia/algolia-environment';

export default function withAlgoliaSearch(WrappedComponent, indices) {
  return class extends Component {
    constructor(props) {
      super(props);

      const searchEnvironment = new AlgoliaEnvironment(env);
      this.indices = searchEnvironment.getIndicesForEnvironment(indices);
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleItemSelect={this.handleResultSelect}
          filter={this.filter}
          format={this.format}
          search={this.search}
        />
      );
    }

    search = (query, afterSearch, params) => {
      const queries = this.getQueries(query, params);
      algoliaV3.search(queries, (error, response) => {
        if (error) {
          console.error(`${error.name}: ${error.message}. Reload the page and try again`);
        }
        afterSearch(response?.results || []);
      });
    };

    getQueries = (query, params) => _.map(this.indices, (index) => index.generateQuery(query, params));

    filter = (algoliaResults, afterFilter) => {
      const promises = _.map(algoliaResults, (results, i) => this.filterResultsForIndex(this.indices[i], results));

      Promise.all(promises).then(
        (values) => {
          afterFilter(values);
        },
        (_err) => {},
      );
    };

    filterResultsForIndex(index, results) {
      return Promise.resolve(index.filter(results));
    }

    format = (algoliaResults) =>
      _.map(algoliaResults, (results, i) => this.formatResultsForIndex(this.indices[i], results));

    formatResultsForIndex = (index, results) => {
      let formattedResults = [];

      _.each(results.hits, (result) => {
        const singleFormatted = index.format(result);
        formattedResults = formattedResults.concat(singleFormatted);
      });

      formattedResults = _.filter(formattedResults, (result) => !!result);
      return formattedResults;
    };

    handleResultSelect(algoliaResult, e) {
      if (algoliaResult && typeof algoliaResult.onSelect === 'function') algoliaResult.onSelect(e);
    }
  };
}

window.withAlgoliaSearch = withAlgoliaSearch;
