import type { IconProps } from '@workramp/ui';
import { Icon } from '@workramp/ui';
import styled, { useTheme } from 'styled-components';

const IconWrapper = styled.span`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

interface GuideSidebarIconProps extends Omit<IconProps, 'color'> {}

export const GuideSidebarIcon = (props: GuideSidebarIconProps) => {
  const { size } = props;
  const theme = useTheme();
  return (
    <IconWrapper>
      <Icon color={theme.colors.secondary[600]} size={size || 'sm'} {...props} />
    </IconWrapper>
  );
};
