import { createContext, useContext, type ReactNode } from 'react';
import { consumer } from '~/features/common';

type ActionCableConsumer = typeof consumer;

export const ActionCableConsumerContext = createContext<ActionCableConsumer | null>(null);

type Props = {
  children: ReactNode;
};

export function ActionCableConsumerProvider({ children }: Props) {
  return <ActionCableConsumerContext.Provider value={consumer}>{children}</ActionCableConsumerContext.Provider>;
}

export function useActionCableConsumer() {
  const contextConsumer = useContext(ActionCableConsumerContext);

  if (!contextConsumer) {
    throw new Error('useActionCableConsumer must be used within an ActionCableProvider');
  }

  return contextConsumer;
}
