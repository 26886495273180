(function($R)
{
  $R.add('plugin', 'fontsize', {
    translations: {
      en: {
        "size": "Size",
        "remove-size": "Remove Font Size"
      }
    },
    init: function (app) {
      this.app = app;
      this.opts = app.opts;
      this.lang = app.lang;
      this.inline = app.inline;
      this.toolbar = app.toolbar;

      // local
      this.sizes = [10, 11, 12, 14, 16, 18, 20, 24, 28, 30];
    },
    // public
    start: function () {
      var dropdown = {};
      for (var i = 0; i < this.sizes.length; i++) {
        var size = this.sizes[i];
        dropdown[i] = {
          title: size + 'px',
          api: 'plugin.fontsize.set',
          args: size
        };
      }

      dropdown.remove = {
        title: this.lang.get('remove-size'),
        api: 'plugin.fontsize.remove'
      };

      var $button = this.toolbar.addButton('fontsize', {title: this.lang.get('size')});
      if (this.opts.useFoundationIconography) {
        $button.setIcon('<svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 4L5.96816 3.82444H5.03184L5.5 4ZM12 4L12.3536 3.64645L12 3.29289L11.6464 3.64645L12 4ZM12 11.5L11.6464 11.8536L12 12.2071L12.3536 11.8536L12 11.5ZM2.96816 12.1756L4.09316 9.17556L3.15684 8.82444L2.03184 11.8244L2.96816 12.1756ZM4.09316 9.17556L5.96816 4.17556L5.03184 3.82444L3.15684 8.82444L4.09316 9.17556ZM5.03184 4.17556L6.90684 9.17556L7.84316 8.82444L5.96816 3.82444L5.03184 4.17556ZM6.90684 9.17556L8.03184 12.1756L8.96816 11.8244L7.84316 8.82444L6.90684 9.17556ZM3.625 9.5H7.375V8.5H3.625V9.5ZM10.3536 6.35355L12.3536 4.35355L11.6464 3.64645L9.64645 5.64645L10.3536 6.35355ZM11.6464 4.35355L13.6464 6.35355L14.3536 5.64645L12.3536 3.64645L11.6464 4.35355ZM9.64645 9.85355L11.6464 11.8536L12.3536 11.1464L10.3536 9.14645L9.64645 9.85355ZM12.3536 11.8536L14.3536 9.85355L13.6464 9.14645L11.6464 11.1464L12.3536 11.8536ZM11.5 4V11.5H12.5V4H11.5Z" fill="currentColor"></path></svg>');
      } else {
        $button.setIcon('<i class="re-icon-fontsize"></i>');
      }
      $button.setDropdown(dropdown);
    },
    set: function (size) {
      var args = {
        tag: 'span',
        style: {'font-size': size + 'px'},
        type: 'toggle'
      };

      this.inline.format(args);
    },
    remove: function () {
      this.inline.remove({style: 'font-size'});
    }
  });
})(Redactor);
