import _ from 'underscore';
import isFeatureEnabled from './components/common/is-feature-enabled';

window.DocumentObjects = {
  sections: {
    content: {
      title: 'Add Content',
      types: {
        textarea: {
          type: 'textarea',
          icon: '/images/editor_icons/add_text_icon_white.svg',
          darkIcon: '/images/editor_icons/add_text_icon_black.svg',
          testOnly: false,
          displayName: 'Text',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
          showOnPortals: true,
        },
        file: {
          type: 'file',
          icon: '/images/editor_icons/attach_file_icon_white.svg',
          darkIcon: '/images/editor_icons/attach_file_icon_black.svg',
          testOnly: false,
          displayName: 'Attach File',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
          showOnPortals: true,
        },
        video: {
          type: 'file',
          fileType: 'video',
          icon: '/images/editor_icons/upload_video_icon_white.svg',
          darkIcon: '/images/editor_icons/upload_video_icon_black.svg',
          testOnly: false,
          displayName: 'Upload Video',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
          showOnPortals: true,
        },
        recordVideo: {
          type: 'recorded-video',
          featureFlip: 'old_video_recorder',
          icon: '/images/editor_icons/record_video_icon_white.svg',
          darkIcon: '/images/editor_icons/record_video_icon_black.svg',
          testOnly: false,
          displayName: 'Record Video',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
          showOnPortals: true,
        },
        zoomRecording: {
          type: 'zoom-live-recording',
          featureFlip: 'old_video_recorder',
          icon: '/images/editor_icons/add_video_recording_icon_white.svg',
          darkIcon: '/images/editor_icons/add_video_recording_icon_black.svg',
          testOnly: true,
          displayName: 'Record with Zoom',
          isIntegration: false,
          requiresKnowledge: false,
          requiresZoom: true,
          tooltipText: 'Drag and Drop',
        },
        audio: {
          type: 'file',
          fileType: 'audio',
          icon: '/images/editor_icons/embed_audio_icon_white.svg',
          darkIcon: '/images/editor_icons/embed_audio_icon_black.svg',
          testOnly: false,
          displayName: 'Audio',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
          showOnPortals: true,
        },
        image: {
          type: 'file',
          fileType: 'image',
          icon: '/images/editor_icons/embed_image_icon_white.svg',
          darkIcon: '/images/editor_icons/embed_image_icon_black.svg',
          testOnly: false,
          displayName: 'Image',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
          showOnPortals: true,
        },
        url: {
          type: 'embedded-url',
          icon: '/images/editor_icons/embed_url_icon_white.svg',
          darkIcon: '/images/editor_icons/embed_url_icon_black.svg',
          testOnly: false,
          displayName: 'Embed',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
          showOnPortals: true,
        },
        knowledgePage: {
          type: 'knowledge-page',
          icon: '/images/knowledge_page_icon_white.svg',
          darkIcon: '/images/knowledge_page_icon_black.svg',
          testOnly: false,
          displayName: 'Portal Page',
          isIntegration: false,
          requiresKnowledge: true,
          tooltipText: 'Drag and Drop',
        },
        flipCard: {
          type: 'flip-card',
          icon: '/images/editor_icons/flipcard_icon.svg',
          darkIcon: '/images/editor_icons/flipcard_icon.svg',
          testOnly: false,
          displayName: 'Flip Card',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
          showOnPortals: true,
        },
        callout: {
          type: 'callout',
          icon: '/images/editor_icons/add_callout_icon_white.svg',
          darkIcon: '/images/editor_icons/add_callout_icon_black.svg',
          testOnly: false,
          displayName: 'Callout',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
          showOnPortals: false,
        },
        scorm: {
          type: 'scorm',
          featureFlip: 'scorm_document_object',
          fileType: 'scorm',
          icon: '/images/scorm_icon.svg',
          darkIcon: '/images/scorm_icon_dark.svg',
          testOnly: false,
          displayName: 'SCORM',
          isIntegration: false,
          requiresScorm: true,
          tooltipText: 'Drag and Drop',
        },
      },
    },
    questions: {
      title: 'Add Questions',
      types: {
        freeForm: {
          type: 'simple-test',
          icon: '/images/editor_icons/add_free_form_question_icon_white.svg',
          darkIcon: '/images/editor_icons/add_free_form_question_icon_black.svg',
          testOnly: true,
          displayName: 'Text Answer',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
        },
        multipleChoice: {
          type: 'multiple-choice-test',
          icon: '/images/editor_icons/add_multiple_choice_icon_white.svg',
          darkIcon: '/images/editor_icons/add_multiple_choice_icon_black.svg',
          testOnly: true,
          displayName: 'Multiple Choice',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
        },
        hotSpot: {
          type: 'hot-spot-test',
          icon: '/images/editor_icons/add_hot_spot_question_icon_white.svg',
          darkIcon: '/images/editor_icons/add_hot_spot_question_icon_black.svg',
          testOnly: true,
          displayName: 'Hot Spot',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
        },
        survey: {
          type: 'survey-test',
          icon: '/images/editor_icons/add_survey_question_icon_white.svg',
          darkIcon: '/images/editor_icons/add_survey_question_icon_black.svg',
          testOnly: true,
          displayName: 'Survey',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
        },
        matching: {
          type: 'matching-test',
          icon: '/images/editor_icons/add_matching_question_icon_white.svg',
          darkIcon: '/images/editor_icons/add_matching_question_icon_black.svg',
          testOnly: true,
          displayName: 'Matching',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
        },
        fileUpload: {
          type: 'attachment-test',
          icon: '/images/editor_icons/add_file_upload_icon_white.svg',
          darkIcon: '/images/editor_icons/add_file_upload_icon_black.svg',
          testOnly: true,
          displayName: 'File Upload',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
        },
        checkList: {
          type: 'checklist-test',
          icon: '/images/editor_icons/add_checklist_icon_white.svg',
          darkIcon: '/images/editor_icons/add_checklist_icon_black.svg',
          testOnly: true,
          displayName: 'To-Do Checklist',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
        },
        videoRecording: {
          type: 'video-test',
          featureFlip: 'old_video_recorder',
          icon: '/images/editor_icons/record_video_icon_white.svg',
          darkIcon: '/images/editor_icons/record_video_icon_black.svg',
          testOnly: true,
          displayName: 'Video Recording',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
        },
        zoomLiveRecording: {
          type: 'zoom-live-recording-test',
          featureFlip: 'old_video_recorder',
          icon: '/images/editor_icons/add_video_recording_icon_white.svg',
          darkIcon: '/images/editor_icons/add_video_recording_icon_black.svg',
          testOnly: true,
          displayName: 'Zoom Recording',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
          requiresZoom: true,
        },
        voicemailRecording: {
          type: 'voice-test',
          icon: '/images/editor_icons/add_voicemail_icon_white.svg',
          darkIcon: '/images/editor_icons/add_voicemail_icon_black.svg',
          testOnly: true,
          displayName: 'Voice Recording',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
        },
        eSignature: {
          type: 'e-signature-test',
          icon: '/images/editor_icons/add_esignature_icon_white.svg',
          darkIcon: '/images/editor_icons/add_esignature_icon_black.svg',
          testOnly: true,
          displayName: 'E-Signature',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
        },
      },
    },
    integrations: {
      title: 'Integrations',
      types: {
        classMarker: {
          type: 'class-marker-test',
          icon: '/images/editor_icons/class_marker_icon_color.svg',
          darkIcon: '/images/editor_icons/class_marker_icon_black.svg',
          featureFlip: 'fy25_q2_classmarker_integration',
          testOnly: true,
          displayName: 'ClassMarker Exam',
          isIntegration: false,
          requiresKnowledge: false,
          tooltipText: 'Drag and Drop',
        },
      },
    },
  },

  additionalTypes: {
    aiAssist: {
      type: 'ai-assist',
      icon: '/images/editor_icons/magic_icon_black.svg',
      darkIcon: '/images/editor_icons/magic_icon_black.svg',
      displayName: 'AI Assist',
      isIntegration: false,
      requiresKnowledge: false,
      tooltipText: 'Drag and Drop',
    },
  },

  // Available options:
  // --------------------------------
  // -includeTestableTypes: include all types that are considered tests
  // -includeKnowledgeTypes: include all knowledge integrations (should only be used if this app is enabled)
  // -integrations: all integrations that should be enabled (array keyed by name of the integration)
  getSections(menuOptions) {
    const sections = _.values(this.sections);

    return _.chain(sections)
      .map((section) => ({
        title: section.title,
        types: getFilteredTypes(section.types, menuOptions),
      }))
      .filter((section) => section.types.length > 0)
      .value();
  },

  getTextAndIcon(targetType, targetFileType) {
    const allTypes = this.getAllTypes();

    return allTypes.find((element) =>
      targetFileType ? element.fileType == targetFileType : element.type === targetType,
    );
  },

  getAllTypes(object = _.values(this.sections).concat(_.values(this.additionalTypes)), allTypes = []) {
    for (var key in object) {
      if (key === 'types') {
        const newTypes = Object.keys(object[key]).map((innerKey) => object[key][innerKey]);
        Array.prototype.push.apply(allTypes, newTypes);
      }

      if (typeof object[key] === 'object') {
        this.getAllTypes(object[key], allTypes);
      }
    }
    return allTypes;
  },
};

window.getFilteredTypes = function (types, menuOptions) {
  const includeTestTypes = menuOptions.includeTestableTypes;
  const { includeKnowledgeTypes } = menuOptions;
  const { includeScormTypes } = menuOptions;
  const { includeZoomTypes } = menuOptions;
  const { integrations } = menuOptions;
  const { portalsObjectsOnly } = menuOptions;

  types = _.values(types);
  types = _.filter(types, (type) => {
    if (type.featureFlip) {
      return isFeatureEnabled(type.featureFlip);
    }
    return true;
  });

  types = _.filter(types, (type) => {
    if (type.isIntegration) {
      return integrations && integrations[type.type];
    }

    return true;
  });

  if (!includeTestTypes) {
    types = _.filter(types, (type) => !type.testOnly);
  }

  if (!includeKnowledgeTypes) {
    types = _.filter(types, (type) => !type.requiresKnowledge);
  }

  if (!includeScormTypes) {
    types = _.filter(types, (type) => !type.requiresScorm);
  }

  if (!includeZoomTypes) {
    types = _.filter(types, (type) => !type.requiresZoom);
  }

  if (portalsObjectsOnly) {
    types = _.filter(types, (type) => type.showOnPortals);
  }

  return types;
};
