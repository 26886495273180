import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import StringUtils from '../../common/string-utils';

/** @deprecated prefer `import { Avatar } from '@workramp/ui';` */
export default class Avatar extends Component {
  constructor(props) {
    super(props);

    this.avatarRef = createRef();
  }

  componentDidMount() {
    const { name, avatarSrc, size, fontSize, useCircle } = this.props;
    Avatar.generateAvatar(this.avatarRef.current, name, avatarSrc, size, fontSize, useCircle);
  }

  componentDidUpdate(prevProps) {
    const { name, avatarSrc, size, fontSize, useCircle } = this.props;
    if (name !== prevProps.name || avatarSrc !== prevProps.avatarSrc) {
      Avatar.generateAvatar(this.avatarRef.current, name, avatarSrc, size, fontSize, useCircle);
    }
  }

  render() {
    let cls = 'avatar';
    if (this.props.cls) cls = `${cls} ${this.props.cls}`;
    if (this.props.className) cls = `${cls} ${this.props.className}`;

    return <div ref={this.avatarRef} className={cls} />;
  }
}

Avatar.propTypes = {
  size: PropTypes.number,
  fontSize: PropTypes.number,

  /** Source for an avatar image if available */
  avatarSrc: PropTypes.string,

  /** If no image, generate one based on the name */
  name: PropTypes.string,
};

Avatar.defaultProps = {
  size: 25,
};

/**
 * This is public because UsersAndGroupsMultiInputField needs it. If able to use React, use the Avatar component
 * instead.
 */
Avatar.generateAvatar = function (el, name, avatar, size, fontSize, useCircle) {
  el.style.width = `${size}px`;
  el.style.height = `${size}px`;
  el.style.cursor = 'default';
  el.style.flex = `0 0 ${size}px`;

  if (avatar) {
    el.style.backgroundSize = 'cover';
    el.style.backgroundPosition = '0 0';
    el.style.backgroundRepeat = 'no-repeat';
    el.style.backgroundImage = `url(${avatar})`;
  } else {
    const colors = [
      '#1abc9c',
      '#3498db',
      '#9b59b6',
      '#16a085',
      '#27ae60',
      '#2980b9',
      '#8e44ad',
      '#f1c40f',
      '#e67e22',
      '#e74c3c',
      '#f39c12',
      '#d35400',
      '#c0392b',
    ];

    if (name) {
      const initials = StringUtils.getInitialsFromString(name);
      const colorIndex = (initials.charCodeAt(0) - 65) % 13 || 0;

      el.style.backgroundColor = colors[colorIndex];
      el.style.textAlign = 'center';
      el.style.font = fontSize ? `${fontSize}px Arial` : '11px Arial';
      el.style.color = '#ffffff';
      el.style.lineHeight = `${size}px`;
      el.style.textTransform = 'uppercase';
      el.innerHTML = initials;
    }
  }

  if (useCircle) {
    el.style.borderRadius = '50%';
  }
};

window.Avatar = Avatar;
