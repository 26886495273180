import _ from 'underscore';
import { Component } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import PathUtils from '../../training/path-utils';
import PathHeader from './path-header';
import PathAbout from './path-about';
import PathContent from './path-content';

export default class Path extends Component {
  TRANSLATED_OVERLAY_RESUME = I18n.t('js.learner.paths.resume');

  TRANSLATED_OVERLAY_REVIEW = I18n.t('js.learner.paths.review');

  TRANSLATED_OVERLAY_START = I18n.t('js.learner.paths.start');

  TRANSLATED_OVERLAY_PREVIEW = I18n.t('js.learner.card.preview');

  TRANSLATED_ABOUT_LABEL = I18n.t('js.learner.paths.about_label');

  render() {
    const assetsDisplayInfo = PathUtils.getAssetsDisplayInfo(
      this.props.path,
      this.props.isReviewMode,
      this.props.isEndUserPreview,
      this.props.pathAssignment,
      this.props.universityId,
      this.props.sessionAttendeesByEventId,
    );
    const assets = PathUtils.getOrderedAssets(this.props.path);
    assets.forEach((asset) => {
      // only for admin review
      asset.isReviewable = APP_CONSTANTS.TOP_LEVEL_CONTENT_NOT_REVIEWABLE.indexOf(asset.asset.contentType) === -1;
    });
    const noneReviewable = assets.every((asset) => !asset.isReviewable);

    let continueText;
    let continueUrl;
    let openLinkInNewTab = false;

    for (let i = 0; i < assets.length; i++) {
      const asset = assets[i];
      const info = assetsDisplayInfo[asset.asset.id];
      if (!info.isCompleted && (!this.props.isReviewMode || asset.isReviewable) && info.url) {
        continueText = i == 0 && !info.percentComplete ? this.TRANSLATED_OVERLAY_START : this.TRANSLATED_OVERLAY_RESUME;

        if (info.url.includes('preview')) {
          continueText = this.TRANSLATED_OVERLAY_PREVIEW;
        }

        continueUrl = info.url;
        openLinkInNewTab = info.openLinkInNewTab;
        break;
      }
    }
    if (!continueUrl && !_.isEmpty(assets) && (!this.props.isReviewMode || !noneReviewable)) {
      continueText = this.TRANSLATED_OVERLAY_REVIEW;
      if (this.props.isReviewMode) {
        const firstReviewable = assets.find((asset) => asset.isReviewable);
        continueUrl = assetsDisplayInfo[firstReviewable.asset.id].url;
      } else {
        continueUrl = assetsDisplayInfo[assets[0].asset.id].url;
      }
    }

    const timeEstimateSeconds = this.props.path.timeEstimateSeconds
      ? this.props.path.timeEstimateSeconds
      : PathUtils.getTimeEstimate(this.props.path);

    return (
      <Wrapper>
        <PathHeader
          name={this.props.path.name}
          bg={this.props.path.bg || '/images/default_card_banner_path.svg'}
          pathId={this.props.path.id}
          timeEstimateSeconds={timeEstimateSeconds}
          continueText={continueText}
          continueUrl={continueUrl}
          openLinkInNewTab={openLinkInNewTab}
          isReviewMode={this.props.isReviewMode}
          isMentorOnly={this.props.isMentorOnly}
        />
        <FlexRow>
          {this.props.path.description && (
            <PathAbout label={this.TRANSLATED_ABOUT_LABEL} description={this.props.path.description} />
          )}
          <PathContent
            path={this.props.path}
            assetsDisplayInfo={assetsDisplayInfo}
            fullWidth={!this.props.path.description}
            isReviewMode={this.props.isReviewMode}
            isMentorOnly={this.props.isMentorOnly}
          />
        </FlexRow>
      </Wrapper>
    );
  }
}

Path.propTypes = {
  isReviewMode: propTypes.bool,
  isEndUserPreview: propTypes.bool,
  universityId: propTypes.string,
  pathAssignment: propTypes.object,
  path: propTypes.shape({
    timeEstimateSeconds: propTypes.number,
    name: propTypes.string,
    bg: propTypes.string,
    description: propTypes.string,
  }),
};

const Wrapper = styled.div`
  background-color: white;
`;

const FlexRow = styled.div`
  display: flex;
  flex-flow: row-reverse wrap;
`;
