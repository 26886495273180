import _ from 'underscore';
import { Component } from 'react';
import styled from 'styled-components';
import TrainingSeriesApi from '~/agent/training-series';
import { URIUtils } from '../../common';
import Path from './path';

/** @deprecated - fy25_q2_path_assignment_view */
export default class PathAssignmentModal extends Component {
  componentDidMount() {
    $(this.modal).on('hidden.bs.modal', () => {
      this.props.onClose();
    });
  }

  componentDidUpdate() {
    if (this.props.show) {
      $(this.modal).modal('show');
    } else {
      $(this.modal).modal('hide');
    }
  }

  render() {
    return (
      <div className="modal fade" ref={(modal) => (this.modal = modal)}>
        <div className="path-assignment-modal modal-dialog">
          {this.props.pathAssignment ? (
            <Path
              sessionAttendeesByEventId={this.props.sessionAttendeesByEventId}
              pathAssignment={this.props.pathAssignment}
              path={this.props.pathAssignment.trainingSeries}
              universityId={this.props.universityId}
            />
          ) : null}
          <CloseIcon
            src="/images/x_icon_white.svg"
            role="button"
            onClick={this.props.onClose}
            tabIndex={0}
            onKeyDown={({ key }) => {
              if (key === 'Enter' || key === ' ') this.props.onClose();
            }}
          />
        </div>
      </div>
    );
  }
}

const CloseIcon = styled.img`
  width: 22px;
  height: 22px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

PathAssignmentModal.show = (assignment, universityId, sessionAttendeesByEventId, customOnClose) => {
  const props = {
    pathAssignment: assignment,
    universityId,
    sessionAttendeesByEventId,
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set('path_assignment_id', assignment.id);
  window.history.replaceState({}, null, `${window.location.pathname}?${urlSearchParams}`);

  WRModals.custom(PathAssignmentModal, props, null, () => {
    urlSearchParams.set('path_assignment_id', null);
    window.history.replaceState({}, null, `${window.location.pathname}?${urlSearchParams}`);
    customOnClose?.();
  });
  TrainingSeriesApi.markTrainingSeriesUserAssignmentViewed(assignment.id);
};

PathAssignmentModal.updatePathAssignmentData = (assignment, sessionAttendeesByEventId, universityId) => {
  WRModals.setCustomProps({
    pathAssignment: assignment,
    universityId,
    sessionAttendeesByEventId,
  });
};

PathAssignmentModal.init = (pathAssignments, universityId, sessionAttendeesByEventId, customOnClose) => {
  const pathAssignmentId = URIUtils.getParameterByName('path_assignment_id');
  const pathAssignment = _.find(pathAssignments, (a) => a.id === pathAssignmentId);
  if (pathAssignmentId && pathAssignment) {
    // Workaround for WRModals not being loaded yet
    setTimeout(
      () => PathAssignmentModal.show(pathAssignment, universityId, sessionAttendeesByEventId, customOnClose),
      50,
    );
  }
};
