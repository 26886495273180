const _ActionMessagesNotLoaded = function () {
  console.error('Action messages not loaded yet.');
};

export const ActionMessageTypes = {
  default: 'default',
  warning: 'warning',
  error: 'error',
};

/** @deprecated prefer `import { useToast } from '@workramp/ui';` */
const ActionMessages = {
  isLoaded: false,
  generateMessage: _ActionMessagesNotLoaded,
};
export default ActionMessages;
