import _ from 'underscore';
import { useEffect, useRef, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { WrTooltip } from '../../styled-components/common/wr-tooltip';
import useResize from '../custom-hooks/use-resize';

/** @deprecated prefer using `maxLines` prop of any text component withing @workramp/ui */
export default function TruncatedDiv({ text, className = '', numLines = 1, withRerenderHack = false, ...inputProps }) {
  text = text != null ? `${text} ` : '';
  const [showTooltip, setShowTooltip] = useState(false);
  const componentMounted = useRef(true);

  // In certain scenarios, responsiveHOC ('react-lines-ellipsis/lib/responsiveHOC') does not work as expected when
  // making the window larger. Because of this, we need to manually handle resizing with responsiveData and handleResize.
  const [responsiveData, setResponsiveData] = useState({ currentWinWidth: innerWidth, renderId: 1 });

  const handleResize = () => {
    const { currentWinWidth } = responsiveData;

    // In certain edge cases the truncation doesn't happen on the initial mounting of the end-user-card-view component.
    // We will allow an option to be passed into the component to force a re-render for those cases
    // TLDR: The hack will prevent overflow of text and overall bad UI experience for users
    // TODO: Figure out a way to fix this component so that it performs consistently throughout the site.
    const shouldReset = innerWidth !== currentWinWidth || (withRerenderHack && responsiveData.renderId <= 2);
    if (shouldReset) handleSetResponsiveData();
  };

  const handleSetResponsiveData = () => {
    const { renderId } = responsiveData;
    const newRenderId = renderId + 1;
    if (!componentMounted.current) return;
    setResponsiveData({ currentWinWidth: innerWidth, renderId: newRenderId });
  };

  useResize(_.debounce(handleResize, 150), [responsiveData, setResponsiveData]);

  const handleReflow = ({ clamped, text }) => {
    if (showTooltip === clamped) return;
    if (!componentMounted.current) return;
    setShowTooltip(clamped);
  };

  // Because renderId for a particular truncated div will not change if the text changes, we need to handle that here
  // with the useEffect hook. Without this, text that should not be truncated may actually be truncated if state changes
  // in the parent component.
  useEffect(() => {
    // is component still mounted?
    handleSetResponsiveData();
  }, [text]);

  return (
    <WrTooltip isOpen={showTooltip ? null : false} content={text}>
      <div
        {...inputProps}
        className={`truncated-div break-word${className ? ` ${className}` : ''}`}
        ref={componentMounted}
      >
        <LinesEllipsis
          key={responsiveData.renderId}
          text={text}
          maxLine={numLines}
          ellipsis="..."
          trimRight
          basedOn="letters"
          onReflow={handleReflow}
          style={{ whiteSpace: 'pre-wrap' }}
        />
      </div>
    </WrTooltip>
  );
}
