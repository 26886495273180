// Unit tests can be found in CMSNewAssetModal.test.tsx:188
// IF YOU UPDATE THIS, UPDATE BE `cms_controller.rb` method `valid_url_and_top_level_domain` TO MATCH
import { CMSTopLevelContentTypes } from '~/features/cms/constants';
import { APP_CONSTANTS } from '~/common/explicit-exports';

export const isUrlAndDomainValid = (url, validProtocols = ['https']) => {
  try {
    // Check if any protocol exists that isn't http and https
    // Note: We checked for https and http already in CMSNewAssetModal
    // so any other protocols will not be allowed
    if (!url.split(':')[1].includes('.')) {
      return false;
    }

    const newUrl = new URL(url);

    // Check if the protocol is valid
    if (!validProtocols.includes(newUrl.protocol.replace(':', ''))) {
      return false;
    }

    // Check if the hostname (includes domain and subdomain) is present
    if (!newUrl.hostname) {
      return false;
    }

    // Split the hostname on '.' and ensure there are at least two parts (hostname and Top Level Domain [TLD])
    // and none of the parts are empty strings (ensuring url doesn't end with a period)
    const parts = newUrl.hostname.split('.');
    if (parts.length < 2 || parts.some((part) => part.trim() === '')) {
      return false;
    }
  } catch (e) {
    // If there is an error parsing the URL, it's not valid
    return false;
  }

  // If all checks pass, the URL is valid
  return true;
};

/*
 * This function is used to go to a CMS content from an Algolia search result,
 * where we only have the top level content id and the content type.
 */
export const learnerGoToCmsContentViaSearchResult = (
  topLevelContentId: string,
  contentType: CMSTopLevelContentTypes.CMS_FILE | CMSTopLevelContentTypes.CMS_LINK,
) => {
  if (contentType === CMSTopLevelContentTypes.CMS_FILE) {
    // When using the embed route, we want to open the content in a new tab instead of redirecting the current tab
    const target = window.location.href.includes('/embed/') ? '_blank' : '_self';

    window.open(`/cms_files/view?tlc_id=${topLevelContentId}`, target);
  } else if (contentType === CMSTopLevelContentTypes.CMS_LINK) {
    window.open(`/cms_links/view?tlc_id=${topLevelContentId}`, '_blank');
  }
};

export const getCMSAssetLink = (assetType: string, id: string) => {
  if (assetType === APP_CONSTANTS.CMS_ASSET_TYPES.LINK) {
    return `/admin/cms/link/${id}`;
  }
  return `/admin/cms/file/${id}`;
};
