import { apiGet, apiPost, apiPatch, apiPut, apiDelete } from '../common';

const Fetch = {
  get: (url, data, successCb, errCb, nameSpace) => Promise.resolve(apiGet(url, data, successCb, errCb, nameSpace)),
  post: (url, data, errCb) => Promise.resolve(apiPost(url, data, null, errCb)), // TODO: refactor Fetch.post calls to include successCb
  postV2: (url, data, successCb, errCb) => Promise.resolve(apiPost(url, data, successCb, errCb)), // TODO: remove once refactor of above is complete
  patch: (url, data, successCb, errorCb, namespace) =>
    Promise.resolve(apiPatch(url, data, successCb, errorCb, namespace)),
  put: (url, data, successCb, errorCb, namespace) => Promise.resolve(apiPut(url, data, successCb, errorCb, namespace)),
  delete: (url, data, successCb, errorCb) => Promise.resolve(apiDelete(url, data, successCb, errorCb)),
};
export default Fetch;
