import { P } from '@workramp/ui';
import styled from 'styled-components';

export const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const Item = styled.li`
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.on[700]};
  overflow-wrap: anywhere;

  & + & {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }
`;

type TextProps = {
  $isInteractive?: boolean;
};

export const Text = styled(P)<TextProps>`
  position: relative;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  white-space: pre-line; // Needed to preserve newlines in tooltip message
  ${({ $isInteractive }) =>
    !!$isInteractive &&
    `
      cursor: pointer;
    `}
`;
