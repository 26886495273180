import styled from 'styled-components';
import { theme } from '../../styles';
import TimeUtils from '../../common/time-utils';

const TimeEstimate = styled.div`
  color: ${theme.color.textDescription};
  ${theme.font.semibold}
  display: flex;
  font-size: 0.8rem;
  margin-left: auto;
  img {
    margin-right: 4px;
  }
`;

export const TimeEstimateIcon = ({ color, size }) => {
  switch (color) {
    case 'grey':
      return <img src="/images/time_estimate_icon_grey.svg" width={size} height={size} />;
    default:
      return <img src="/images/time_estimate_icon.svg" width={size} height={size} aria-hidden="true" />;
  }
};

/**
 * Returns a shortened time estimate with a clock icon.
 *
 * If the provided timeEstimateSeconds parameter is undefined,
 * null, or 0, nothing will be rendered.
 *
 * @param {*} timeEstimateSeconds: Time Estimate (in seconds) to display
 */
export const TimeEstimateWithIcon = ({ timeEstimateSeconds, className, iconColor, iconSize, emptyEstimateCopy }) => {
  if (!timeEstimateSeconds && !emptyEstimateCopy) return null;

  return (
    <TimeEstimate className={className}>
      {timeEstimateSeconds ? (
        <>
          <TimeEstimateIcon color={iconColor} size={iconSize || '14px'} />
          {TimeUtils.displayDurationShort(timeEstimateSeconds)}
        </>
      ) : (
        emptyEstimateCopy
      )}
    </TimeEstimate>
  );
};

export const TimeEstimateWithIconAndPencilPadding = styled(TimeEstimateWithIcon)`
  padding-right: 25px;
`;
