import _ from 'underscore';
import ReactDOMServer from 'react-dom/server';
import { getDefaultFont } from './common';

window.MentionEnabledTextAreaUtils = {
  DEFAULT_TRIGGER: '@',

  getMentionRegex(trigger) {
    trigger = trigger || this.DEFAULT_TRIGGER;

    return new RegExp(`${trigger}([^${trigger}]+)$`);
  },

  matchMentionRegex(text, caretPosition, trigger) {
    trigger = trigger || this.DEFAULT_TRIGGER;

    text = text.substring(0, caretPosition);
    const mentionRegex = this.getMentionRegex(trigger);

    return mentionRegex.exec(text);
  },

  getHtmlFromParts(parts) {
    let html = '';

    _.each(parts, (part) => {
      if (typeof part === 'string' || part instanceof String) {
        html += part;
      } else {
        html += ReactDOMServer.renderToString(part);
      }
    });

    return html;
  },

  getMentionAndTextFromString(fullText, caretPosition, trigger) {
    trigger = trigger || this.DEFAULT_TRIGGER;
    const match = this.matchMentionRegex(fullText, caretPosition, trigger);

    if (match) {
      const mention = match[0];
      const preText = fullText.substring(0, caretPosition - mention.length);
      const postText = fullText.substring(caretPosition);
      return {
        mention,
        preText,
        postText,
      };
    }

    return fullText;
  },

  getMentionElement(type, id, text) {
    const width = MentionEnabledTextAreaUtils.width(text, getDefaultFont(), '12px', '.5px');
    return (
      <input
        data-type={type}
        data-id={id}
        style={{ width }}
        value={text}
        className="mention-enabled-text-field--mention"
        readOnly
      />
    );
  },

  getMentionDisplayElement(type, id, text) {
    return (
      <div data-type={type} data-id={id} className="mention-enabled-text-field--mention">
        {text}
      </div>
    );
  },

  width(txt, fontname, fontsize, letterSpacing) {
    const dummy = document.createElement('span');
    document.body.appendChild(dummy);

    dummy.style.fontSize = fontsize;
    dummy.style.fontFamily = fontname;
    dummy.style.letterSpacing = letterSpacing;
    dummy.innerText = txt;
    dummy.setAttribute('id', 'ruler');

    const $ruler = $('#ruler');
    const width = $ruler.width();
    $ruler.remove();
    return width;
  },

  parseCommentHtml(html) {
    const htmlElements = $.parseHTML(html);
    return _.map(htmlElements, (element, index) => {
      // element is text
      if (element.data) {
        return { text: element.data, index };
        //  element is mention
      }
      const $element = $(element);
      const referenceType = $element.attr('data-type');
      const referenceId = $element.attr('data-id');
      return { text: element.value, index, reference_id: referenceId, reference_type: referenceType };
    });
  },
};
