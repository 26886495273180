import PropTypes from 'prop-types';

const CommonPropTypes = {
  user: PropTypes.object,
  folder: PropTypes.object,
};

window.CommonPropTypes = CommonPropTypes;

export default CommonPropTypes;
